import React, { useState, useContext } from 'react';
import { deleteUser, signOut } from 'firebase/auth';
// import { DeleteAccountContext, IsDeletingContext, LoginContext } from '../../../providers';
import axios from 'axios';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { UserContext } from '../../../../components/UserProvider';
import { LoginContext } from '../../../..';
import { auth } from '../../../../config/firebase';
import { fetchSubscriptions } from '../../../../components/subscriptionService';
import { DeleteAccountContext, IsDeletingContext } from '../../providers';
import { useDialogue } from '../../modal/dialogue/useDialogue';
import { Link, useNavigate } from 'react-router-dom';
import { addNotification, removeLoadingNotification } from '../../../../components/notification';
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import fetchSecrets from '../../../../config/fetchSecrets';

export default function DeleteProcessAccount() {

    const { user, setStatusSubscription } = useContext(UserContext);
    const { setIsLoginComplete } = useContext(LoginContext);
    const { setDeletedAccount } = useContext(DeleteAccountContext);
    const { setIsDeletingAccount } = useContext(IsDeletingContext);
    const { openDialogue, closeDialogue } = useDialogue();
    let navigate = useNavigate();


    const [visible, setVisible] = useState(false);
    const [password, setpassword] = useState({ value: '', error: '' })
    const [showPassword, setShowPassword] = useState(false)

    const showDialog = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setpassword({ value: '', error: '' })
        setVisible(false);
    };

    const handleConfirm = (event) => {
        event.preventDefault(); // Isso deve estar aqui para sempre prevenir a atualização da página.
        setVisible(false);
        reauthenticateAndDeleteAccount()
    };

    const reauthenticateAndDeleteAccount = async () => {
        showDialog();
        const user = auth.currentUser;
        const email = user.email; // O e-mail do usuário atual, necessário para criar as credenciais
        const enteredPassword = password.value; // A senha inserida pelo usuário no diálogo, variável renomeada

        // Cria as credenciais para a reautenticação
        const credentials = EmailAuthProvider.credential(email, enteredPassword);

        try {
            await reauthenticateWithCredential(user, credentials);
            confirmDeleteAccount();
        } catch (error) {
            addNotification('A senha fornecida está incorreta ou ocorreu um erro.', 'error');
            setpassword({ value: '', error: '' });
        }
    };


    const SignOut = async () => {
        await signOut(auth);
    }

    const deleteAccount = () => {
        openDialogue("Confirmação de Exclusão de Conta", "Está prestes a excluir permanentemente sua conta e todos os dados associados no TwoAlert. Esta ação é irreversível e resultará na perda de todas as suas informações e acesso aos serviços. Deseja continuar?", [
            {
                text: "Cancelar",
                onClick: () => {
                    closeDialogue();
                }
            },
            {
                text: "Excluir Conta",
                onClick: () => {
                    closeDialogue();
                    searchAccount()
                }
            },
        ]);

    };

    const searchAccount = () => {
        fetchSubscriptions(user?.uid).then(subscription => {
            if (!subscription) {
                showDialog();
                return;
            }

            // Pega os dados da estrutura nova
            const status = subscription.subscriptionDetails?.status;
            const willRenew = subscription.subscriptionDetails?.willRenew;
            const currentPeriodEnd = subscription.subscriptionDetails?.currentPeriodEnd;
            const isActive = subscription.isActive;

            // Formatar a data
            const periodEndDate = currentPeriodEnd ? new Date(currentPeriodEnd).toLocaleDateString() : null;

            if (isActive) {
                if (status === 'CANCELED' && !willRenew) {
                    openDialogue(
                        "Assinatura Programada para Cancelamento",
                        `Sua assinatura está programada para ser cancelada automaticamente em ${periodEndDate}. Você mantém o acesso completo aos recursos do aplicativo até essa data. Se desejar continuar com a exclusão da sua conta imediatamente, selecione "Excluir Agora".`,
                        [
                            {
                                text: "Continuar Usando",
                                onClick: () => closeDialogue()
                            },
                            {
                                text: "Excluir Agora",
                                onClick: () => {
                                    closeDialogue();
                                    showDialog();
                                }
                            }
                        ]
                    );
                } else if (
                    [
                        'ACTIVE',
                        'GRACE_PERIOD',
                        'TRIAL'
                    ].includes(status)
                ) {
                    setStatusSubscription('active');
                    openDialogue(
                        "Assinatura Ativa",
                        "Você possui uma assinatura ativa. Por favor, cancele sua assinatura antes de excluir sua conta.",
                        [
                            {
                                text: "Ir para Assinaturas",
                                onClick: () => {
                                    closeDialogue();
                                    navigate("/account/subscription");
                                }
                            }
                        ]
                    );
                } else if (status === 'PENDING') {
                    openDialogue(
                        "Assinatura Pendente",
                        "Sua assinatura está aguardando confirmação de pagamento. Por favor, aguarde a confirmação ou cancele a assinatura antes de excluir sua conta.",
                        [
                            {
                                text: "Ir para Assinaturas",
                                onClick: () => {
                                    closeDialogue();
                                    navigate("/account/subscription");
                                }
                            }
                        ]
                    );
                } else {
                    showDialog();
                }
            } else {
                showDialog();
            }
        }).catch(error => {
            console.error("Erro ao buscar dados da assinatura:", error);
            openDialogue(
                "Erro",
                "Ocorreu um erro ao buscar os dados da sua assinatura. Tente novamente mais tarde.",
                [
                    {
                        text: "OK",
                        onClick: () => closeDialogue()
                    }
                ]
            );
        });
    };

    const confirmDeleteAccount = async () => {
        handleCancel()
        setIsLoginComplete(false)

        try {

            const secrets = await fetchSecrets();
            const SERVER = secrets.SERVER;
            const AUTH_TOKEN = secrets.AUTH_TOKEN;

            addNotification('Inicando processo de exclusão...', 'loading')
            // Autenticação bem-sucedida, prossegue com a exclusão da conta
            const uid = user.uid; // Assume que você tenha o UID do usuário disponível

            addNotification('Excluindo dados...', 'loading')
            const response = await axios.post(`${SERVER}/delete-account`, { uid }, {
                headers: {
                    'Authorization': `Bearer ${AUTH_TOKEN}`,
                }
            });


            if (response.status == 200) {
                setDeletedAccount(true)
                removeLoadingNotification();
                const currentUser = auth.currentUser;
                deleteUser(currentUser).then(() => {
                    navigate("/")
                    addNotification("Sua conta e todos os dados associados foram excluídos com sucesso.", 'success');
                    setIsLoginComplete(true)
                    // Implemente a lógica de pós-exclusão aqui, como redirecionar para a tela de entrada
                }).catch((error) => {
                    removeLoadingNotification();
                    setIsLoginComplete(false)
                    let actions = [{ text: "OK" }];
                    let errorMessage = "Seus dados pessoais já foram excluídos, mas precisamos de uma etapa adicional para remover completamente sua conta. ";

                    switch (error.code) {
                        case 'auth/requires-recent-login':
                            errorMessage += "Por motivos de segurança, é necessário confirmar sua identidade. Por favor, saia e entre novamente.";
                            // Altera as ações para incluir uma que permite ao usuário sair da conta
                            setIsDeletingAccount(true)
                            actions = [
                                { text: "Sair da Conta", onPress: () => SignOut() },
                                { text: "Cancelar", style: "cancel" }
                            ];
                            break;
                        default:
                            errorMessage += "Por favor, tente novamente mais tarde ou entre em contato com o suporte para assistência.";
                            break;
                    }

                    openDialogue("Atenção", errorMessage, actions);
                });
            } else {
                removeLoadingNotification();
                setIsLoginComplete(false)
                // Tratar casos em que a API retorna sucesso: false
                throw new Error(response.data.message || "Falha ao excluir a conta.");
            }
        } catch (error) {
            removeLoadingNotification();
            setIsLoginComplete(false)
            console.error("Erro ao excluir a conta:", error);
            addNotification(error.message || "Ocorreu um erro ao tentar excluir a conta.", 'error');
        } finally {
            removeLoadingNotification();
        }
    };


    return (
        <div className='allDeleteAccount'>

            {visible && (
                <div className='allVerification'>
                    <div className='closeArea' onClick={() => setVisible(false)}></div>
                    <form className="formVerification" onSubmit={handleConfirm}>
                        <div className="titleVerification">Insira a sua senha</div>
                        <div className='contentInputs'>
                            <div className='contentInput'>
                                <input type={showPassword ? 'text' : 'password'} class="input" required value={password.value} onChange={(e) => setpassword({ ...password, value: e.target.value })} maxLength={50} minLength={6} />
                                <label class="label">Senha atual</label>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} className='iconEye' />
                            </div>
                        </div>
                        <button className="action" style={{ marginTop: 0 }}>Prosseguir</button>
                    </form>
                </div>
            )}

            <div className='back'>
                <Link to="/account"><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Link>
            </div>

            <div className='deletingAccount'>
                <div className='containerDeleteInformations'>
                    <h3>Excluir a sua conta é uma operação permanente</h3>
                    <span>A exclusão de sua conta não pode ser desfeita, verifique novamente se você realmente deseja excluir esta conta</span>
                </div>
                <h3 className='textTitleName'>Detalhes da sua conta</h3>
                <div className='detailsAccount'>
                    <p><strong>E-mail: </strong>{user?.email}</p>
                    <p><strong>Data de registro: </strong>{user?.metadata?.creationTime}</p>
                </div>
                <button className="buttonDeleteAccount" onClick={() => deleteAccount()}>Deletar conta permanentemente</button>
            </div>
        </div>
    )
}