import React, { useEffect, useState, useContext } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { nameValidator } from '../../helpers/nameValidator'
import { descriptionValidator } from '../../helpers/descriptionValidator'
import { ageValidator } from '../../helpers/ageValidator'
import './style.css'
import CustomModal from '../customModal';
import { addNotification, removeLoadingNotification } from '../../components/notification';
import { paises } from '../../app/config/countries';
import { UserContext } from '../../components/UserProvider';
import { updateUser } from '../../analysis/update';
import fetchSecrets from '../../config/fetchSecrets';

// MUI Imports
import { 
    Box, 
    Typography, 
    TextField, 
    Button, 
    Paper, 
    Container,
    IconButton,
    Stack,
    styled
} from '@mui/material';
import { 
    KeyboardArrowDown as KeyboardArrowDownIcon,
    HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import { useDialogue } from '../../app/config/modal/dialogue/useDialogue';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: '#F2F2F7',
    minHeight: '100%',
    borderRadius: 0,
    padding: 0,
    position: 'relative',
    display: 'flex'
}));

const ContentContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    flex: 1
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    color: '#2148ff',
    fontWeight: 600,
    fontSize: '15px',
    marginBottom: theme.spacing(1)
}));

const SelectButton = styled(Button)(({ theme }) => ({
    width: '100%',
    backgroundColor: '#FFF',
    color: props => props.filled ? '#000' : '#8E8E93',
    border: '1px solid #E5E5EA',
    borderRadius: '12px',
    padding: '10px',
    justifyContent: 'space-between',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#FFF',
    }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        backgroundColor: '#FFF',
    }
}));

export function ChangeDataInfo({ data, ChangeData }) {

    const { registerId } = data;

    const { user } = useContext(UserContext)

    const { openDialogue, closeDialogue } = useDialogue();

    const [nome, setNome] = useState({ value: '', error: '' })
    const [idade, setIdade] = useState({ value: '', error: '' })
    const [desc, setDesc] = useState({ value: '', error: '' })
    const [descLength, setDescLength] = useState(0);

    const [originalData, setOriginalData] = useState(null)

    useEffect(() => {
        const fetchUserData = async () => {
            const docRef = doc(db, 'dataUsersRegister', registerId);

            try {
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setOriginalData({
                        nome: userData.nome,
                        dataNascimento: userData.dataNascimento,
                        descricao: userData.descricao,
                        estadoCivil: userData.estadoCivil,
                        genero: userData.genero,
                        nacionalidade: userData.nacionalidade,
                        cadastrante: userData.cadastrante,
                        edited: userData.edited,
                        cpf: userData.cpf
                    })

                    setNome({ value: userData.nome, error: '' });
                    setIdade({ value: userData.dataNascimento, error: '' });
                    setDesc({ value: userData.descricao, error: '' });
                    setDescLength(userData.descricao.length);
                    setEstadoCivil(userData.estadoCivil || '');
                    setSelectedSG(userData.genero || '');
                    setNacionalidade(userData.nacionalidade || '');
                    setCadas(userData.cadastrante || '');
                }
            } catch (error) {
                // Trate o erro conforme necessário
            }
        }

        fetchUserData();
    }, [registerId]);

    const updateData = async () => {
        const nomeError = nameValidator(nome.value);
        const idadeError = ageValidator(idade.value);
        const descError = descriptionValidator(desc.value);

        if (nomeError || idadeError || descError) {
            setNome({ ...nome, error: nomeError });
            setDesc({ ...desc, error: descError });
            setIdade({ ...idade, error: idadeError });
            addNotification('Ainda há um campo com erro!', 'error');
            setInfoAnalitcs('');
            return;
        }

        addNotification("Processando dados...", "loading");

        const payload = {};
        if (originalData.nome !== nome.value) payload.nome = nome.value;
        if (originalData.dataNascimento !== idade.value) payload.idade = idade.value;
        if (originalData.descricao !== desc.value) payload.desc = desc.value;
        if (originalData.estadoCivil !== estadoCivil) payload.estadoCivil = estadoCivil;
        if (originalData.genero !== selectedValueSG) payload.selectedValueSG = selectedValueSG;
        if (originalData.nacionalidade !== nacionalidade) payload.nacionalidade = nacionalidade;
        if (originalData.cadastrante !== cadas) payload.cadas = cadas;

        // Check if any data has been changed
        if (Object.keys(payload).length === 0) {
            addNotification('Nenhuma alteração detectada. Modifique algum dado para atualizar.', 'warning');
            removeLoadingNotification();
            return;
        }
        window.grecaptcha.ready(async () => {

            const secrets = await fetchSecrets();
            const SITE_reCAPTCHA = secrets.SITE_reCAPTCHA;

            const token = await window.grecaptcha.execute(`${SITE_reCAPTCHA}`, { action: 'submit' });

            if (token) {
                try {
                    const message = await updateUser(registerId, user.uid, payload, token);
                    removeLoadingNotification();
                    addNotification(message, 'success');
                } catch (error) {
                    removeLoadingNotification();
                    openDialogue("Erro ao Atualizar", error.message, [
                        {
                            text: "Ok",
                            onClick: () => {
                                closeDialogue();
                            }
                        },
                    ]);
                    // addNotification(error.message, 'error');
                }
            }
        })
    };

    const [estadoCivil, setEstadoCivil] = useState('')
    const [selectedValueSG, setSelectedSG] = useState("");

    const [nacionalidade, setNacionalidade] = useState('')
    const [cadas, setCadas] = useState('')

    const [pickerVisible, setPickerVisible] = useState(false);
    const [openEstadoCivil, setOpenEstadoCivil] = useState(false);
    const [openCadas, setOpenCadas] = useState(false);
    const [openNacionalidade, setOpenNacionalidade] = useState(false);

    const options = ["M e Cisgênero", "M e Transgênero", "M e Não Responder", "F e Cisgênero", "F e Transgênero", "F e Não Responder"];

    const OptionsEstadoCivil = ["Solteiro(a)", "Casado(a)", "Viúvo(a)", "Divorciado(a)", "Separado(a)", "Namorando"];

    const optionsParentesco = ["Filho", "Filha", "Marido", "Esposa", "Pai", "Mãe", "Irmão", "Irmã", "Tio", "Tia", "Avô", "Avó", "Primo", "Prima", "Sobrinho", "Sobrinha", "Padrastro", "Madrasta"];

    const isDisabled2 = estadoCivil === '' || selectedValueSG === '' || nacionalidade === '' || cadas === '';
    const isDisabled = nome.value === '' || idade.value === '' || desc.value === '';

    const [infoAnalitcs, setInfoAnalitcs] = useState('')

    const [countries, setCountries] = useState([]);

    useEffect(() => {
        setCountries(paises);
    }, [])

    const handleIdadeChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos

        // Adiciona a primeira barra após o dia (2 dígitos)
        if (value.length > 2 && value[2] !== '/') {
            value = value.slice(0, 2) + '/' + value.slice(2);
        }

        // Adiciona a segunda barra após o mês (2 dígitos após a primeira barra)
        if (value.length > 5 && value[5] !== '/') {
            value = value.slice(0, 5) + '/' + value.slice(5);
        }

        // Limita a entrada para no máximo 10 caracteres (dd/mm/aaaa)
        if (value.length > 10) {
            value = value.slice(0, 10);
        }

        setIdade({ value: value, error: '' });
    };

    const showGenderInfo = () => {
        const message = `Caso você não saiba:\n\n` +
            `Cisgênero: Pessoa que se apresenta ao mundo e se identifica com seu gênero de nascimento.\n\n` +
            `Transgênero: Pessoa que se apresenta ao mundo e se identifica com gênero diferente do seu gênero de nascimento. Engloba tanto as travestis quanto as pessoas transexuais.`;

        openDialogue("Informações Importantes", message, [
            {
                text: "OK",
                onClick: () => closeDialogue()
            }
        ]);
    };

    if (ChangeData === 1) {
        return (
            <StyledPaper>
                <Box maxWidth="sm" sx={{ flex: 1 }}>
                    <ContentContainer>
                        <Box mb={3}>
                            <HeaderText>Informações Básicas</HeaderText>
                            <Typography variant="h4" fontWeight={700} mb={1}>
                                Editar Perfil
                            </Typography>
                            <Typography color="#666" fontSize={17}>
                                Atualize as informações do usuário conforme necessário.
                            </Typography>
                        </Box>
    
                        <Stack spacing={2}>
                            <Box>
                                <Typography fontWeight={600} mb={1}>Nome completo</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={nome.value}
                                    onChange={(e) => setNome({ value: e.target.value, error: '' })}
                                    error={!!nome.error}
                                    helperText={nome.error}
                                    placeholder="Nome completo do usuário"
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Box>
    
                            <Box>
                                <Typography fontWeight={600} mb={1}>Data de Nascimento</Typography>
                                <StyledTextField
                                    fullWidth
                                    value={idade.value}
                                    onChange={handleIdadeChange}
                                    error={!!idade.error}
                                    helperText={idade.error}
                                    placeholder="Data de nascimento"
                                    inputProps={{ maxLength: 10, minLength: 10 }}
                                />
                            </Box>
    
                            <Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Typography fontWeight={600}>Sobre o usuário</Typography>
                                    <Typography color="#8E8E93" fontSize={15}>
                                        {descLength}/200
                                    </Typography>
                                </Box>
                                <StyledTextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={desc.value}
                                    onChange={(e) => {
                                        setDesc({ value: e.target.value, error: '' });
                                        setDescLength(e.target.value.length);
                                    }}
                                    error={!!desc.error}
                                    helperText={desc.error}
                                    placeholder="Ex: O usuário é gêmeo."
                                    inputProps={{ maxLength: 200 }}
                                />
                            </Box>
                        </Stack>
                    </ContentContainer>
    
                    <Box sx={{ 
                        position: 'absolute', 
                        bottom: 0, 
                        left: 0, 
                        right: 0, 
                        p: 2, 
                        bgcolor: '#FFF',
                        borderTop: '1px solid #E5E5EA'
                    }}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={updateData}
                            disabled={isDisabled || infoAnalitcs !== ""}
                            sx={{
                                bgcolor: isDisabled ? '#E5E5EA' : '#2148ff',
                                borderRadius: '12px',
                                py: 2,
                                textTransform: 'none',
                                fontSize: 17,
                                fontWeight: 600
                            }}
                        >
                            {infoAnalitcs || 'Salvar alterações'}
                        </Button>
                    </Box>
                </Box>
            </StyledPaper>
        );
    } else {
        return (
            <StyledPaper>
                <Box maxWidth="sm" sx={{ flex: 1 }}>
                    <ContentContainer>
                        <Box mb={3}>
                            <HeaderText>Informações Complementares</HeaderText>
                            <Typography variant="h4" fontWeight={700} mb={1}>
                                Editar Perfil
                            </Typography>
                            <Typography color="#666" fontSize={17}>
                                Atualize as informações complementares do usuário.
                            </Typography>
                        </Box>
    
                        <Stack spacing={2}>
                            <Box>
                                <Box display="flex" alignItems="center" mb={1}>
                                    <Typography fontWeight={600}>Sexo e Gênero</Typography>
                                    <IconButton 
                                        size="small" 
                                        onClick={() => showGenderInfo()}
                                        sx={{ ml: 1 }}
                                    >
                                        <HelpOutlineIcon sx={{ color: '#2148ff' }} />
                                    </IconButton>
                                </Box>
                                <SelectButton
                                    onClick={() => setPickerVisible(!pickerVisible)}
                                    filled={!!selectedValueSG}
                                    endIcon={<KeyboardArrowDownIcon sx={{ color: '#2148ff' }} />}
                                >
                                    {selectedValueSG || "Selecione uma opção"}
                                </SelectButton>
                            </Box>
    
                            <Box>
                                <Typography fontWeight={600} mb={1}>Estado Civil</Typography>
                                <SelectButton
                                    onClick={() => setOpenEstadoCivil(!openEstadoCivil)}
                                    filled={!!estadoCivil}
                                    endIcon={<KeyboardArrowDownIcon sx={{ color: '#2148ff' }} />}
                                >
                                    {estadoCivil || "Selecione o estado civil"}
                                </SelectButton>
                            </Box>
    
                            <Box>
                                <Typography fontWeight={600} mb={1}>País de origem</Typography>
                                <SelectButton
                                    onClick={() => setOpenNacionalidade(!openNacionalidade)}
                                    filled={!!nacionalidade}
                                    endIcon={<KeyboardArrowDownIcon sx={{ color: '#2148ff' }} />}
                                >
                                    {nacionalidade || "Selecione o país"}
                                </SelectButton>
                            </Box>
    
                            <Box>
                                <Typography fontWeight={600} mb={1}>Quem está editando</Typography>
                                <SelectButton
                                    onClick={() => setOpenCadas(!openCadas)}
                                    filled={!!cadas}
                                    endIcon={<KeyboardArrowDownIcon sx={{ color: '#2148ff' }} />}
                                >
                                    {cadas || "Selecione o parentesco"}
                                </SelectButton>
                            </Box>
                        </Stack>
                    </ContentContainer>
    
                    <CustomModal
                        visible={pickerVisible}
                        setVisible={setPickerVisible}
                        options={options}
                        onSelectOption={setSelectedSG}
                        textTitleModal='Sexo e Gênero'
                        selectedValue={selectedValueSG}
                    />
    
                    <CustomModal
                        visible={openEstadoCivil}
                        setVisible={setOpenEstadoCivil}
                        options={OptionsEstadoCivil}
                        onSelectOption={setEstadoCivil}
                        textTitleModal='Estado Civil'
                        selectedValue={estadoCivil}
                    />
    
                    <CustomModal
                        visible={openNacionalidade}
                        setVisible={setOpenNacionalidade}
                        options={paises}
                        onSelectOption={setNacionalidade}
                        textTitleModal="Escolha seu país"
                        selectedValue={nacionalidade}
                    />
    
                    <CustomModal
                        visible={openCadas}
                        setVisible={setOpenCadas}
                        options={optionsParentesco}
                        onSelectOption={setCadas}
                        textTitleModal="Escolha um Parentesco"
                        selectedValue={cadas}
                    />
    
                    <Box sx={{ 
                        position: 'absolute', 
                        bottom: 0, 
                        left: 0, 
                        right: 0, 
                        p: 2, 
                        bgcolor: '#FFF',
                        borderTop: '1px solid #E5E5EA'
                    }}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={updateData}
                            disabled={isDisabled2 || infoAnalitcs !== ""}
                            sx={{
                                bgcolor: isDisabled2 ? '#E5E5EA' : '#2148ff',
                                borderRadius: '12px',
                                py: 2,
                                textTransform: 'none',
                                fontSize: 17,
                                fontWeight: 600
                            }}
                        >
                            {infoAnalitcs || 'Salvar alterações'}
                        </Button>
                    </Box>
                </Box>
            </StyledPaper>
        );
    }
}