import './styles.css'
import { useState } from 'react'
import backgroundMap from '../../assets/download.jpg'
import Menu from '../../components/menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faEye, faLocationArrow, faBell, faEnvelope, faRightFromBracket, faMobilePhone } from '@fortawesome/free-solid-svg-icons';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import UserAvatar from '../../components/userTwoAlert';
import { db } from '../../config/firebase'
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import ChatHelper from '../../components/chatHelp';

import { useContext } from 'react';
import { UserContext } from '../../components/UserProvider';

import logo from '../../assets/Logo-TwoAlert.jpeg'
import { Link, useNavigate } from 'react-router-dom';
import ReportProblem from '../config/modal/reportProblem';
import { SignOut } from '../../functions/signOut';
import { LoginContext } from '../../index';
import { useDialogue } from '../config/modal/dialogue/useDialogue';
import { addNotification, removeLoadingNotification, updateLoadingMessage } from '../../components/notification';
import Loading from '../../assets/mn224E_W9XQ.gif'
import { sendVerifyEmail } from '../../functions/sendVerifyEmail';

export default function Home({ notifications, setNotifications }) {

    const { setIsLoginComplete } = useContext(LoginContext);

    let navigate = useNavigate();

    const { user, photoURL, displayName, email } = useContext(UserContext)

    const [menu, setMenu] = useState(false)
    const [showReportModal, setShowReportModal] = useState(false)
    const [loadingLogOut, setLoadingLogOut] = useState(false)

    const { openDialogue, closeDialogue } = useDialogue();

    const logOut = () => {
        openDialogue("Atenção!", "Você não precisa sair da sua conta caso esse seja o seu dispositivo de confiança. Deseja sair realmente?", [
            {
                text: "Não",
                onClick: () => {
                    closeDialogue();
                }
            },
            {
                text: "Sim",
                onClick: () => {
                    SignOut(setIsLoginComplete, setLoadingLogOut)
                    closeDialogue();
                }
            },
        ]);
    };

    const getNotificationDetails = (type, nav) => {
        const types = {
            'twoalert': { title: 'TwoAlert', icon: "twoalert", nav: nav || '' },
            'alert': { title: 'Alerta', icon: faBell, nav: '' },
            'message': { title: 'Mensagem', icon: faEnvelope, nav: nav || '' },
            'NewViewCode': { title: 'Código visualizado', icon: faEye, nav: nav || '/users' },
            'NewLogin': { title: 'Novo login em sua conta', icon: faRightFromBracket, nav: nav || '/account' },
            'Location': { title: 'Geolocalização', icon: faLocationArrow, nav: nav || '/location' },
            'ScreenshotTaken': { title: 'Alerta de Captura de tela', icon: faMobilePhone, nav: nav || '/users' },
        };

        return types[type] || { title: 'Notificação', icon: faBell, nav: nav || "" }; // Retorna um padrão se o tipo não for reconhecido
    };

    const [isNotificationsActive, setIsNotificationsActive] = useState(false);

    const toggleNotifications = () => {
        setIsNotificationsActive(!isNotificationsActive);
    };


    const showNotification = (title, description, nav, timestamp, sendEmail = false) => {
        const actions = [
            {
                text: "Ver",
                onClick: () => {
                    if (nav && (nav.startsWith('https://') || nav.startsWith('http://') || nav.startsWith('www.'))) {
                        const url = nav.startsWith('www.') ? `https://${nav}` : nav;
                        window.open(url, '_blank');
                    } else if (nav) {
                        // Se 'nav' não é uma URL, assume-se que seja uma rota interna
                        navigate(nav);
                    }
                    closeDialogue();
                }
            }
        ];

        if (timestamp !== "") {
            actions.unshift({
                text: "Excluir",
                onClick: () => {
                    removeNotification(timestamp);
                    closeDialogue();
                }
            });
        }

        if (sendEmail) {
            actions.unshift({
                text: "Enviar e-mail",
                onClick: () => {
                    sendVerifyEmail();
                    closeDialogue();
                }
            });
        }

        // Chama openDialogue com o título, descrição e as ações configuradas
        openDialogue(title, description, actions);
    };

    const removeNotification = (timestamp) => {
        addNotification("Excluindo notificação...", "loading");
        const userDocRef = doc(db, 'users', user.uid);

        // Remover a notificação com o timestamp especificado do array no banco de dados
        const removeNotificationFromDB = async (timestampToRemove) => {

            try {
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    let userNotifications = userData.notifications || [];

                    userNotifications = userNotifications.filter(notification => {
                        return notification.timestamp.seconds !== timestampToRemove.seconds ||
                            notification.timestamp.nanoseconds !== timestampToRemove.nanoseconds;
                    });

                    // Atualizar o documento do usuário com o novo array de notificações
                    await updateDoc(userDocRef, { notifications: userNotifications });
                    addNotification("Notificação removida com sucesso!", "success");
                    removeLoadingNotification();
                } else {
                    addNotification("Erro ao remover notificação", "error");
                    removeLoadingNotification();
                }
            } catch (error) {
                // console.error("Erro ao remover notificação:", error);
                addNotification("Erro ao remover notificação", "error");
            }
        };

        removeNotificationFromDB(timestamp);
    };

    function aboutNotification() {
        openDialogue("Registro de Atividades no TwoAlert", "Este é o registro das suas atividades no TwoAlert. Aqui você encontrará informações sobre quem visualizou seu código, localização, entre outros. As notificações são automaticamente removidas quando há acúmulo excessivo.", [
            {
                text: "Entendi",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    }

    return (
        <div className='allHome'>

            <ReportProblem showReportModal={showReportModal} setShowReportModal={setShowReportModal} />

            <Menu />
            <div className='home'>

                <ChatHelper />

                <div className='backgroundMap'>
                    <img src={backgroundMap} alt="background" draggable={false} />
                </div>
                <div className='userContent'>
                    <div className='headerUser'>
                        <div className='infoWepApp'>
                            <a href='https://support.twoalert.com.br/' style={{ textDecoration: 'none' }} target="_blank" rel="noopener">
                                <button><InfoIcon /> Suporte</button>
                            </a>
                        </div>
                        <div className={`notifications ${isNotificationsActive ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faBell} onClick={toggleNotifications} />
                            <div className='containerMobileNotifications'>
                                <div className='containerActivity'>
                                    {notifications.map((notification, index) => {
                                        const { title, icon, nav } = getNotificationDetails(notification?.type, notification.nav);

                                        return (
                                            <div key={index} className='notificationActivity' onClick={() => showNotification(title, notification?.content, nav, notification?.timestamp, notification.sendEmail)}>
                                                <div className='flexIconMessage'>
                                                    <div className='iconType'>
                                                        <div className='containerIcon'>
                                                            {notification?.error === true && (
                                                                <div className="iconError">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" title="Atividade Importante!" width="19" height="19" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                        <circle cx="12" cy="12" r="10" fill="rgb(250,123,23)" stroke="rgb(250,123,23)" />
                                                                        <line x1="12" y1="16" x2="12" y2="12" stroke="white" />
                                                                        <line x1="12" y1="8" x2="12" y2="8" stroke="white" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                            {icon === "twoalert"
                                                                ?
                                                                <img src={logo} />
                                                                :
                                                                <FontAwesomeIcon icon={icon} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='contentNotificationActiviy'>
                                                        <h3>{title}</h3>
                                                        <span>{notification?.content}</span>
                                                    </div>
                                                </div>
                                                <div className='iconActionNotification'>
                                                    <div className='containerIcon'>
                                                        <FontAwesomeIcon icon={faChevronRight} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='flexUserContent'>
                            <div className='flexUserAvatar' onClick={() => setMenu(!menu)}>
                                <FontAwesomeIcon icon={faChevronDown} />
                                <span>{displayName?.split(' ')[0]}</span>
                                <UserAvatar photoURL={photoURL} email={email} userName={displayName} size={40} />
                            </div>
                            <div className={menu ? 'menuUser active' : 'menuUser'}>
                                <Link to={`/account`}>Configurações</Link>
                                <Link onClick={() => setShowReportModal(true)}>Reportar um problema</Link>
                                <a href='https://www.twoalert.com.br' target="_blank" rel="noopener">Sobre</a>
                                <Link onClick={logOut}>Sair {loadingLogOut && (<img src={Loading} alt='loading animation' />)}</Link>
                            </div>
                        </div>
                    </div>
                    <div className='contentHello'>
                        <h3>Olá, <span>{displayName}</span></h3>
                    </div>
                    <div className='containerInfoTwoAlert'>
                        <h3>🔥<span>Todos os recursos disponíveis, agora na web!</span> </h3>
                        <div className='contentContainer'>
                            <h2>TwoAlert, a sua segurança virtual, agora na web com os mesmos recursos do aplicativo.</h2>
                            <p>(Uma simples assinatura, e você fará parte da nossa comunidade)</p>
                        </div>
                        <p id='contentAbout'>Confronte os dados de um desconhecido, envie e receba a localização em tempo real, saiba quem está te monitorando em tempo real.</p>
                        <button><a href='https://www.twoalert.com.br/' target="_blank" rel="noopener">Saiba mais <FontAwesomeIcon icon={faChevronRight} /></a></button>
                    </div>

                    <div className='containerBoxes'>
                        <div className='flexHeaderBoxes'>
                            <h2>Novidades</h2>
                            {/* <div className='iconFunction'>
                        
                            </div> */}
                        </div>
                        <div className='contentBoxes'>
                            <div className='boxNews'>
                                <div>
                                    <h3>Geolocalização</h3>
                                    <span>Compartilhe a sua localização em tempo real e de forma segura</span>
                                </div>
                            </div>
                            <div className='boxNews'>
                                <div>
                                    <h3>Verificação da Identidade</h3>
                                    <span>Confirme a identidade de alguém em tempo real</span>
                                </div>
                            </div>
                            <div className='boxNews'>
                                <div>
                                    <h3>Monitoramento da Localização</h3>
                                    <span>Monitore quem pode ver a sua localização em tempo real</span>
                                </div>
                            </div>
                            <div className='boxNews'>
                                <div>
                                    <h3>Segurança da Família</h3>
                                    <span>Mantenha a sua família ciente e informada</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='rightSideNotificationNews'>
                    <div className='notificationNews'>
                        <div className='headerNotificationNews'>
                            <h2>Atividade</h2>
                            <div class="buttonAboutSection" onClick={aboutNotification}>
                                <span>?</span>
                            </div>
                        </div>
                        <div className='containerActivity'>

                            {notifications.map((notification, index) => {
                                const { title, icon, nav } = getNotificationDetails(notification?.type, notification.nav);

                                return (
                                    <div key={index} className='notificationActivity' onClick={() => showNotification(title, notification?.content, nav, notification?.timestamp, notification.sendEmail)}>
                                        <div className='flexIconMessage'>
                                            <div className='iconType'>
                                                <div className='containerIcon'>
                                                    {notification?.error === true && (
                                                        <div className="iconError">
                                                            <svg xmlns="http://www.w3.org/2000/svg" title="Atividade Importante!" width="19" height="19" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                <circle cx="12" cy="12" r="10" fill="rgb(250,123,23)" stroke="rgb(250,123,23)" />
                                                                <line x1="12" y1="16" x2="12" y2="12" stroke="white" />
                                                                <line x1="12" y1="8" x2="12" y2="8" stroke="white" />
                                                            </svg>
                                                        </div>
                                                    )}
                                                    {icon === "twoalert"
                                                        ?
                                                        <img src={logo} />
                                                        :
                                                        <FontAwesomeIcon icon={icon} />
                                                    }
                                                </div>
                                            </div>
                                            <div className='contentNotificationActiviy'>
                                                <h3>{title}</h3>
                                                <span>{notification?.content}</span>
                                            </div>
                                        </div>
                                        <div className='iconActionNotification'>
                                            <div className='containerIcon'>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}