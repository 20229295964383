import React, { useState, createContext, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';
import { db } from './config/firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { signOut } from 'firebase/auth';

import Login from './login';

import Home from './app/home';
import Users from './app/users';
import Register from './app/register';
import ViewInfoCode from './app/viewInfoCode';
import Location from './app/location';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Loader from './components/Loader';
import Settings from './app/config/settings';

import { UserProvider } from './components/UserProvider';
import { DialogueProvider } from './app/config/modal/dialogue/DialogueContext';
import { VerificationProvider } from './app/config/modal/verification/VerificationProvider';

import { addNotification } from "./components/notification";
import DeleteProcessAccount from './app/config/settings/deleteProcessAccount';
import { DeleteAccountProvider, IsDeletingProvider } from './app/config/providers'; // GlobalConfigProvider
import RestrictedAccessScreen from './RestrictedAccessScreen';

const AuthenticatedUserContext = createContext({});

const stripePromise = loadStripe('pk_live_51MGrHjGYuiyBCe0CKrKdneSCb4mjGUsJnwW6HQK5XVEtH0KNd3mccecdsXUCfcH6nhTelzziJQYVYOhFwk4rq6sQ00V84yMXFr');

const AuthenticatedUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  return (
    <AuthenticatedUserContext.Provider value={{ user, setUser }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};


function TwoAlert() {
  // useFetchConfigurations()
  const { isLoginComplete, setIsLoginComplete } = useContext(LoginContext);
  const { user } = useContext(AuthenticatedUserContext);
  const [userData, setUserData] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [appLoaded, setAppLoaded] = useState(false);
  let navigate = useNavigate();

  const monitorUniqueIDInFirestore = async (userData) => {
    const localUniqueID = localStorage.getItem('uniqueID');

    if (!localUniqueID) {
      await signOut(auth);
      navigate('/');
      return;
    }

    if (userData && userData.sessionsDevice && userData.sessionsDevice.length > 0 && isLoginComplete) {
      const sessionExists = userData.sessionsDevice.some(
        (session) => session.uniqueID === localUniqueID
      );

      if (!sessionExists) {
        setIsLoginComplete(false)
        await signOut(auth);
        navigate('/');
      }
      else {
        setAppLoaded(true)
      }
    }
  };

  useEffect(() => {
    let unsubscribe;

    const fetchData = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);

        unsubscribe = onSnapshot(userRef, async (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUserData(userData);

            if (userData && userData.sessionsDevice) {
              await monitorUniqueIDInFirestore(userData).catch(console.error);
            }

            if (userData.sessionsDevice && userData.sessionsDevice.length === 0 && isLoginComplete === true) {
              setIsLoginComplete(false);
              await signOut(auth);
            }

            if (!userData.sessionsDevice && isLoginComplete === true) {
              setIsLoginComplete(false)
              await signOut(auth);
            }

          } else {
            setUserData({});
          }
        });
      }
    };

    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user, isLoginComplete]);

  function getEmailProviderLink(email) {
    const emailProvider = email.split('@')[1].toLowerCase();
    let baseLink = "https://www.";

    switch (true) {
      case emailProvider.includes('gmail'):
        return baseLink + "gmail.com";
      case emailProvider.includes('hotmail'):
      case emailProvider.includes('outlook'):
      case emailProvider.includes('live'):
        return baseLink + "outlook.com";
      case emailProvider.includes('yahoo'):
        return baseLink + "mail.yahoo.com";
      case emailProvider.includes('icloud'):
      case emailProvider.includes('me'):
        return baseLink + "icloud.com/mail";
      case emailProvider.includes('aol'):
        return baseLink + "mail.aol.com";
      case emailProvider.includes('zoho'):
        return baseLink + "mail.zoho.com";
      case emailProvider.includes('mail'):
        return baseLink + "mail.com";
      case emailProvider.includes('yandex'):
        return baseLink + "mail.yandex.com";
      case emailProvider.includes('protonmail'):
        return "https://mail.protonmail.com";
      case emailProvider.includes('gmx'):
        return baseLink + "gmx.com";
      case emailProvider.includes('fastmail'):
        return "https://www.fastmail.com/mail/";
      case emailProvider.includes('hushmail'):
        return "https://www.hushmail.com";
      case emailProvider.includes('tutanota'):
        return "https://mail.tutanota.com";
      case emailProvider.includes('runbox'):
        return "https://mail.runbox.com";
      case emailProvider.includes('mailbox'):
        return "https://mailbox.org";
      case emailProvider.includes('mailfence'):
        return "https://mailfence.com";
      case emailProvider.includes('inbox'):
        return baseLink + "inbox.com";
      case emailProvider.includes('lycos'):
        return baseLink + "mail.lycos.com";
      default:
        // Para outros provedores de email não especificados
        return "Não foi possível determinar o link direto para o seu provedor de email. Por favor, acesse o site do seu provedor de email diretamente.";
    }
  }


  useEffect(() => {
    const userDocRef = doc(db, 'users', user.uid);

    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        let userNotifications = userData.notifications || [];

        const recentNotifications = userNotifications.filter(notification => {
          const currentTimestamp = Math.floor(Date.now() / 1000); // Timestamp atual em segundos
          const notificationTimestamp = notification.timestamp.seconds;
          return currentTimestamp - notificationTimestamp <= 10; // Notificações dentro de 10 segundos
        });

        userNotifications.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

        if (recentNotifications.length > 0) {
          addNotification(recentNotifications[0].content, 'normal');
        }

        setNotifications(userNotifications); // Atualizar estado com as notificações atuais
        if (!user.emailVerified) {
          const newNotification = {
            content: "Parece que você ainda não verificou seu email! Por favor, verifique sua caixa de entrada e siga as instruções para completar a verificação.",
            timestamp: "",
            type: "twoalert",
            error: true,
            sendEmail: true,
            nav: getEmailProviderLink(user?.email)
          };

          setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
        }
        if (!user.emailVerified) {
          const newNotification = {
            content: "O acesso completo aos recursos do TwoAlert requer a verificação do seu endereço de e-mail. Por favor, certifique-se de que seu e-mail está verificado para utilizar todos os serviços disponíveis.",
            timestamp: "",
            type: "twoalert",
            error: true,
            sendEmail: true,
            nav: getEmailProviderLink(user?.email)
          };

          setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
        }
        if (userNotifications.length <= 0) {
          const newNotification = {
            content: "Deseja explorar todas as funcionalidades do TwoAlert? Acesse nosso guia de introdução agora mesmo e descubra como aproveitar ao máximo nossa plataforma.",
            timestamp: "",
            type: "notification",
            nav: "https://twoalert.com.br/"
          };

          setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
        }
        if (userNotifications.length <= 0 && user.emailVerified) {
          const aboutNotification = {
            content: "Quer saber mais sobre o TwoAlert? Visite nossa página Sobre para conhecer mais sobre nossa missão, valores e a equipe por trás da plataforma.",
            timestamp: "",
            type: "notification",
            nav: "https://twoalert.com.br/about"
          };

          const featuresNotification = {
            content: "Descubra os recursos avançados do TwoAlert! Acesse agora mesmo nossa página de Recursos e veja como nossa plataforma pode otimizar seu dia a dia.",
            timestamp: "",
            type: "notification",
            nav: "https://twoalert.com.br/features"
          };

          setNotifications(prevNotifications => [aboutNotification, featuresNotification, ...prevNotifications]);
        }
      } else {

      }
    }, (error) => {
      addNotification("Não foi possível recuperar suas notificações e atividades.", 'error');
    });

    return () => unsubscribe();

  }, [user, db, setNotifications]);

  if (!appLoaded) {
    return (
      <div style={{ display: 'flex', height: '100vh', width: '100vw', flex: 1, justifyContent: 'center', alignItems: 'center', background: '#233DFF' }}>
        <Loader />
      </div>
    )
  }



  return (
    <React.Fragment>
      <div id="notifications"></div>

      <Routes>
        <Route path='/' element={<Home notifications={notifications} setNotifications={setNotifications} />} />
        <Route path='/users' element={<Users />} />
        <Route path='/register' element={<Register />} />
        <Route path='/view' element={<ViewInfoCode />} />
        <Route path='/location' element={<Location />} />
        <Route path='/account' element={<Settings />} />
        <Route path='/account/profile' element={<Settings />} />
        <Route path='/account/password' element={<Settings />} />
        <Route path='/account/subscription' element={<Settings />} />
        <Route path='/account/idiom' element={<Settings />} />
        <Route path='/account/deleteAccount' element={<DeleteProcessAccount />} />
      </Routes>
    </React.Fragment>
  )
}

export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isLoginComplete, setIsLoginComplete] = useState(false);
  const { user } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        setIsLoginComplete(true);
      }, 8000);
    }
  }, [user])


  return (
    <LoginContext.Provider value={{ isLoginComplete, setIsLoginComplete }}>
      {children}
    </LoginContext.Provider>
  );
};

function AuthTwoAlert() {
  const protectedRoutes = [
    '/users',
    '/register',
    '/view',
    '/location',
    '/account',
    '/account/profile',
    '/account/password',
    '/account/subscription',
    '/account/idiom',
    '/account/deleteAccount',
  ];

  const location = useLocation();

  const isProtectedRoute = protectedRoutes.includes(location.pathname);

  return (
    <Routes>
      <Route path='/' element={<Login />} />
      {location.pathname !== '/' && isProtectedRoute && <Route path="*" element={<RestrictedAccessScreen />} />}
    </Routes>
  );
}

function RootNavigator() {
  const { user, setUser } = useContext(AuthenticatedUserContext);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {

    const unsubscribeAuth = onAuthStateChanged(
      auth,
      async authenticatedUser => {
        authenticatedUser ? setUser(authenticatedUser) : setUser(null);
        setIsLoading(false);
      }
    );

    return unsubscribeAuth;
  }, [user]);
  if (isLoading) {
    return (
      <div style={{ display: 'flex', height: '100vh', width: '100vw', flex: 1, justifyContent: 'center', alignItems: 'center', background: '#233DFF' }}>
        <Loader />
      </div>
    );
  }

  return (
    <BrowserRouter>
      {user ? <TwoAlert /> : <AuthTwoAlert />}
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Elements stripe={stripePromise}>
    <AuthenticatedUserProvider>
      <LoginProvider>
        <UserProvider>
          <DialogueProvider>
            <VerificationProvider>
              <DeleteAccountProvider>
                <IsDeletingProvider>
                  <RootNavigator />
                </IsDeletingProvider>
              </DeleteAccountProvider>
            </VerificationProvider>
          </DialogueProvider>
        </UserProvider>
      </LoginProvider>
    </AuthenticatedUserProvider>
  </Elements>
);