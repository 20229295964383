import fetchSecrets from "../../config/fetchSecrets";

export const checkImage = async (input) => {

    const secrets = await fetchSecrets();
    const SERVER_URL = secrets.SERVER_URL;
    const AUTH_TOKEN = secrets.AUTH_TOKEN;

    const formData = new FormData();

    function base64ToBlob(base64, mimeType) {
        const bytes = atob(base64.split(',')[1]);
        const arr = new Uint8Array(bytes.length);

        for (let i = 0; i < bytes.length; i++) {
            arr[i] = bytes.charCodeAt(i);
        }

        return new Blob([arr], { type: mimeType });
    }
    const blob = base64ToBlob(input, 'image/jpeg');
    formData.append('image', blob);

    const response = await fetch(`${SERVER_URL}/check_image`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${AUTH_TOKEN}`,
        },
        body: formData,
    });

    if (!response.ok) {
        // Lançando um erro com a resposta do servidor como mensagem
        throw new Error('Erro na resposta do servidor: ' + await response.text());
    }

    const result = await response.json();

    return { result: result.isAppropriate };
};
