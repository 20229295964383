import axios from 'axios';
import fetchSecrets from '../../config/fetchSecrets';

export const fetchSubscriptions = async (userId) => {
    const secrets = await fetchSecrets();
    const SERVER = secrets.SERVER;
    const AUTH_TOKEN = secrets.AUTH_TOKEN;

    try {
        const response = await axios.get(`${SERVER}/subscription-status/${userId}`, {        
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`,
            }
        });
        
        // Se não houver assinatura ativa, retorna null
        if (!response.data.success || !response.data.data) {
            return null;
        }

        // Retorna diretamente os dados da assinatura
        return response.data.data;
    } catch (error) {
        console.error("Erro ao recuperar status da assinatura:", error);
        return null;
    }
};