import './style.css'

const UserAvatar = ({ userName, email, photoURL, size = 50 }) => {
    let displayLetter;

    if (userName && userName.length > 0) {
        const nameParts = userName.split(' ');
        displayLetter = nameParts.map(part => part[0]).slice(0, 2).join('').toUpperCase();
    } else if (email && email.length > 0) {
        displayLetter = email[0].toUpperCase();
    } else {
        displayLetter = '?';
    }

    const style = {
        // animation: 'pulse 2s infinite',
        // boxShadow: '0 0 0 rgba(0, 123, 255, 0.7)',
        width: size,
        height: size,
        borderRadius: size / 2,
        backgroundColor: '#233DFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <div style={style}>
            {photoURL ?
                <img src={photoURL} style={{ width: '100%', height: '100%', borderRadius: size / 2, backgroundColor: '#F2F2F2', objectFit: 'cover' }} draggable={false} /> :
                <h3 className='fonth3' style={{ color: 'white', fontSize: size * 0.5, textTransform: 'uppercase', userSelect: 'none' }}>{displayLetter}</h3>
            }
        </div>
    );
};

export default UserAvatar;
