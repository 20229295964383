import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../../config/firebase';
import { doc, onSnapshot, collection, query as firestoreQuery, where, getDoc } from 'firebase/firestore';
import { fetchSubscriptions } from '../../components/subscriptionService';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [photo, setPhoto] = useState('');
    const [newName, setNewName] = useState('');
    const [statusSubscription, setStatusSubscription] = useState(null);
    const [users, setUsers] = useState([]);
    const [nameExist, setNameExist] = useState(false);
    const [hasUsedTrial, setHasUsedTrial] = useState(null);
    const [passwordHash, setPasswordHash] = useState(false);

    // Novo estado para armazenar o timestamp da última atualização
    const [lastSubscriptionUpdate, setLastSubscriptionUpdate] = useState(null);

    // Monitoramento do estado de autenticação
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                const { displayName, email, emailVerified, isAnonymous, metadata, photoURL, uid } = currentUser;
                setUser({ displayName, email, emailVerified, isAnonymous, metadata, photoURL, uid });
            } else {
                setUser(null);
            }
        });

        return unsubscribe;
    }, []);

    // Buscar dados do usuário no Firestore
    useEffect(() => {
        if (!user) return;

        const fetchUserData = async () => {
            try {
                const userRef = doc(db, 'users', user.uid);

                const unsubscribe = onSnapshot(userRef, (doc) => {
                    if (!doc.exists()) return;

                    const userData = doc.data();
                    setPhoto(userData?.photo || '');
                    setNewName(userData?.nome || '');
                    setNameExist(!!userData?.nome);
                    setPasswordHash(doc.exists() && doc.data().passwordHash);

                    // Verifica se há uma nova atualização do timestamp
                    const newUpdateTimestamp = userData?.subscriptionLastUpdate || null;

                    // Se o timestamp for diferente (incluindo quando aparece pela primeira vez)
                    if (newUpdateTimestamp !== lastSubscriptionUpdate) {
                        setLastSubscriptionUpdate(newUpdateTimestamp);
                    }
                });

                return unsubscribe;
            } catch (error) {
                console.error('Erro ao buscar dados do usuário:', error);
            }
        };

        fetchUserData();
    }, [user]);

    // Effect para verificação inicial e quando houver mudança no timestamp
    useEffect(() => {
        if (!user) return;

        const checkSubscriptionStatus = async () => {
            try {
                const subscriptionData = await fetchSubscriptions(user.uid);
                if (subscriptionData && subscriptionData?.isActive) {
                    setStatusSubscription('active');
                } else {
                    setStatusSubscription(null);
                }
            } catch (error) {
                console.error('Erro ao verificar status da assinatura:', error);
                setStatusSubscription(null);
            }
        };

        // Faz a verificação inicial quando o componente monta ou quando o user muda
        checkSubscriptionStatus();

    }, [user]); // Dependência apenas do user para verificação inicial

    // Effect específico para mudanças no timestamp
    useEffect(() => {
        if (!user || !lastSubscriptionUpdate) return;

        const checkSubscriptionStatus = async () => {
            try {
                const subscriptionData = await fetchSubscriptions(user.uid);
                if (subscriptionData && subscriptionData?.isActive) {
                    setStatusSubscription('active');
                } else {
                    setStatusSubscription(null);
                }
            } catch (error) {
                console.error('Erro ao verificar status da assinatura:', error);
                setStatusSubscription(null);
            }
        };

        // Executa a verificação quando o timestamp mudar
        checkSubscriptionStatus();

    }, [user, lastSubscriptionUpdate]);


    useEffect(() => {
        if (!user) return;

        const fetchTrialStatus = async () => {
            try {
                // Obtenha o e-mail do usuário
                const email = user.email;

                if (!email) {
                    console.warn('O e-mail do usuário não está disponível.');
                    return;
                }

                const trialUsageRef = doc(db, 'trial_usage', email);
                const trialDoc = await getDoc(trialUsageRef);

                if (trialDoc.exists()) {
                    const trialData = trialDoc.data();
                    setHasUsedTrial(trialData?.hasUsedTrial || false); // Define o estado como true ou false
                } else {

                    setHasUsedTrial(false); // Assume que o trial não foi usado se o documento não existir
                }
            } catch (error) {
                // console.error('Erro ao buscar o status de trial:', error);
            }
        };

        fetchTrialStatus();
    }, [user]);

    // Buscar usuários no Firestore
    useEffect(() => {
        if (!user?.uid) return;

        const fetchData = async () => {
            try {
                const userCollectionRef = collection(db, 'dataUsersRegister');
                const q = firestoreQuery(userCollectionRef, where('userId', '==', user.uid));

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const fetchedUsers = [];
                    querySnapshot.forEach((doc) => {
                        const { cpf, ...otherData } = doc.data();
                        fetchedUsers.push({
                            ...otherData,
                            id: doc.id,
                            code: otherData.code || 'Gerar código',
                        });
                    });
                    setUsers(fetchedUsers);
                });

                return unsubscribe;
            } catch (error) {
                console.error('Erro ao buscar usuários:', error);
            }
        };

        fetchData();
    }, [user?.uid]);

    const userName = user?.displayName;
    const email = user?.email;
    const photoURL = photo || user?.photoURL;
    const displayName = newName || userName || email?.split('@')[0];

    const value = {
        user,
        photoURL,
        displayName,
        email,
        passwordHash,
        setStatusSubscription,
        statusSubscription,
        users,
        nameExist,
        hasUsedTrial
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
