import axios from "axios";
import fetchSecrets from "../../config/fetchSecrets";

const blobFromUrl = async (url) => {
    const response = await fetch(url);
    return await response.blob();
};

export async function registerUser(
    nome,
    idade,
    desc,
    estadoCivil,
    selectedValueSG,
    cpf,
    nacionalidade,
    cadas,
    uid,
    imageUris,  // Agora esta variável deve conter as URIs das imagens
    pdfUri,
    token
) {
    const secrets = await fetchSecrets();
    const SERVER_URL = secrets.SERVER_URL;
    const AUTH_TOKEN = secrets.AUTH_TOKEN;

    const formData = new FormData();

    formData.append("nome", nome);
    formData.append("idade", idade);
    formData.append("desc", desc);
    formData.append("estadoCivil", estadoCivil);
    formData.append("selectedValueSG", selectedValueSG);
    formData.append("cpf", cpf);
    formData.append("nacionalidade", nacionalidade);
    formData.append("cadas", cadas);
    formData.append("uid", uid);

    // Convertendo e adicionando cada imagem como blob ao formData
    for (const [index, uri] of imageUris.entries()) {
        const blob = await blobFromUrl(uri);  // Usando a função blobFromUrl para carregar o blob da imagem
        const file = new File([blob], `image_${index + 1}.jpeg`, { type: "image/jpeg" });
        formData.append("images", file);
    }

    if (pdfUri) {
        const pdfBlob = await blobFromUrl(pdfUri);
        const pdfFile = new File([pdfBlob], "documento.pdf", { type: "image/jpeg" });
        formData.append("pdf", pdfFile);
    }

    try {
        const response = await axios.post(
            `${SERVER_URL}/register`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${AUTH_TOKEN}`,
                    'X-User-ID': uid,
                    'X-Recaptcha-Token': token
                },
            }
        );

        return response.data.message;
    } catch (error) {
        console.error(error)
        const errorMessage = error.code === 'ECONNABORTED'
            ? "Tempo de espera excedido. Tente novamente mais tarde."
            : error.response?.data?.error || "Erro ao registrar usuário";

        throw new Error(errorMessage);
    }
}