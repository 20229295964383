import React, { useState } from 'react';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const ImageAccordion = ({ images, title }) => {
    const [showImages, setShowImages] = useState(false);

    const toggleImages = () => {
        setShowImages(!showImages);
    };

    const handleImagePress = (image) => {
        // Implemente o código para exibir a imagem em um modal aqui
    };

    return (
        <div className="accordion">
            <div className="button" onClick={toggleImages}>
                <span className="titleAccordion">{title}</span>
                <FontAwesomeIcon icon={showImages ? faChevronUp : faChevronDown} />
            </div>

            <div className={`imageContainer ${showImages ? 'showImages' : ''}`}>
                {images.map((image, index) => (
                    <div key={index} onClick={() => handleImagePress(image)}>
                        <img
                            className="image"
                            src={image}
                            draggable={false}
                            alt={`Image ${index}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageAccordion;
