import React, { useEffect, useState } from 'react';
import { doc } from 'firebase/firestore';
import { onSnapshot } from "firebase/firestore";
import { db } from '../../config/firebase';
import { addNotification } from '../../components/notification';

import './style.css'

// MUI Imports
import {
    Box,
    Container,
    Typography,
    IconButton,
    Button,
    Modal,
    Paper,
    ImageList,
    ImageListItem,
    styled
} from '@mui/material';
import {
    Close as CloseIcon,
    Delete as DeleteIcon,
    FileUpload as FileUploadIcon,
    ChevronRight as ChevronRightIcon,
    Add as AddIcon,
    Check as CheckIcon
} from '@mui/icons-material';

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#F2F2F7',
    maxHeight: 'calc(100vh - 40px)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

const ImageGrid = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0.5),
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap'
}));

const StyledImage = styled('img')(({ selected }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 12,
    opacity: selected ? 0.7 : 1,
    transition: 'opacity 300ms ease'
}));

const ImageOverlay = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(33, 72, 255, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12
});

const CheckmarkCircle = styled(Box)({
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: '#2148ff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF'
});

const DocumentButton = styled(Button)(({ theme, active }) => ({
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#F2F2F7',
    borderRadius: 12,
    border: '1px solid #E5E5EA',
    justifyContent: 'space-between',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#F2F2F7',
    }
}));

export function ChangeData({ data, close, openShowPDF, setOpenShowPDF, images, setImages, imagesToDelete, setImagesToDelete, pdf, setPdf, pdfToDelete, setPdfToDelete }) {

    const { registerId, uid } = data;
    const [selectedImages, setSelectedImages] = useState([]);

    const [loading, setLoading] = useState(true);
    const [storagePdf, setStoragePdf] = useState(true);

    useEffect(() => {
        // Limpar estado das imagens antes de buscar novas imagens
        setImages([]);
        setPdf(null);
        setStoragePdf(null);

        const docRef = doc(db, 'dataUsersRegister', registerId);

        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setImages(userData.images || []);
                setPdf(userData.pdf || null);
                setStoragePdf(userData.pdf || null);
            }
        });

        return () => unsubscribe();
    }, [registerId]);


    const handleSelectImage = index => {
        if (selectedImages.includes(index)) {
            setSelectedImages(selectedImages.filter(i => i !== index));
        } else {
            setSelectedImages([...selectedImages, index]);
        }
    };

    const handleDeleteImages = async () => {
        const imagesToBeDeleted = selectedImages.map(index => images[index]);

        if (images.length - imagesToBeDeleted.length >= 1) {
            const remainingImages = images.filter((_, index) => !selectedImages.includes(index));
            setImages(remainingImages); // Atualiza as imagens remanescentes
            setImagesToDelete([...imagesToDelete, ...imagesToBeDeleted]); // Adiciona as imagens para deletar
            setSelectedImages([]); // Limpa as seleções
        } else {
            addNotification('Pelo menos uma imagem deve ser mantida. Não é possível excluir todas as imagens.', 'warning');
        }
    };

    useEffect(() => {
        if (!storagePdf) {
            setPdfToDelete(null)
        }
    }, [storagePdf])

    const deletePDF = async (pdf) => {
        if (!pdf) return;
        setPdfToDelete(pdf);
        setOpenShowPDF(false);
        setPdf(null);
    };

    const selectImage = async () => {
        if (images.length >= 5) {
            addNotification('Limite Atingido: Você não pode adicionar mais de 5 imagens.', 'warning');
            return;
        }

        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = true;
        input.onchange = (e) => {
            const files = Array.from(e.target.files);
            const maxImagesToAdd = 5 - images.length;

            if (files.length > maxImagesToAdd) {
                addNotification(`Selecione até ${maxImagesToAdd} imagens adicionais. Apenas ${maxImagesToAdd} novas imagens serão adicionadas.`, 'warning');
            }

            const filesToProcess = files.slice(0, maxImagesToAdd);

            const fileUrls = filesToProcess.map(file => URL.createObjectURL(file));
            setImages([...images, ...fileUrls]);
        };
        input.click();
    };

    const selectOneImage = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = false;
        input.onchange = async (e) => {
            const file = e.target.files[0];
            if (!file) return;

            const fileUrl = URL.createObjectURL(file);

            if (pdf && pdf.includes('storage.googleapis.com')) {
                setPdfToDelete(pdf);
            }

            setPdf(fileUrl);
        };
        input.click();
    };

    return (
        <StyledContainer>
            <Container maxWidth="sm" sx={{ flex: 1, overflow: 'auto', height: 'calc(100vh - 40px)' }}>
                <ImageGrid>
                    {images.map((imageUrl, index) => {
                        if (index % 3 === 0) {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        width: '96%',
                                        aspectRatio: '16/9',
                                        margin: '0.5%',
                                        position: 'relative',
                                        cursor: 'pointer',
                                        borderRadius: 3,
                                        overflow: 'hidden'
                                    }}
                                    onClick={() => handleSelectImage(index)}
                                >
                                    <StyledImage
                                        src={imageUrl}
                                        alt=""
                                        selected={selectedImages.includes(index)}
                                        draggable={false}
                                    />
                                    {selectedImages.includes(index) && (
                                        <ImageOverlay>
                                            <CheckmarkCircle>
                                                <CheckIcon />
                                            </CheckmarkCircle>
                                        </ImageOverlay>
                                    )}
                                </Box>
                            );
                        } else if (index % 3 === 1) {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '47.5%',
                                        margin: '0.5%'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            borderRadius: 3,
                                            overflow: 'hidden'
                                        }}
                                        onClick={() => handleSelectImage(index)}
                                    >
                                        <StyledImage
                                            src={imageUrl}
                                            alt=""
                                            selected={selectedImages.includes(index)}
                                            draggable={false}
                                        />
                                        {selectedImages.includes(index) && (
                                            <ImageOverlay>
                                                <CheckmarkCircle>
                                                    <CheckIcon />
                                                </CheckmarkCircle>
                                            </ImageOverlay>
                                        )}
                                    </Box>
                                    {index + 1 < images.length && (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                aspectRatio: '1/1',
                                                position: 'relative',
                                                cursor: 'pointer',
                                                borderRadius: 3,
                                                overflow: 'hidden',
                                                marginTop: '3%'
                                            }}
                                            onClick={() => handleSelectImage(index + 1)}
                                        >
                                            <StyledImage
                                                src={images[index + 1]}
                                                alt=""
                                                selected={selectedImages.includes(index + 1)}
                                                draggable={false}
                                            />
                                            {selectedImages.includes(index + 1) && (
                                                <ImageOverlay>
                                                    <CheckmarkCircle>
                                                        <CheckIcon />
                                                    </CheckmarkCircle>
                                                </ImageOverlay>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            );
                        }
                        return null;
                    })}
                </ImageGrid>
            </Container>

            <Paper
                elevation={0}
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: '#FFF',
                    borderTop: '1px solid #E5E5EA',
                    p: 2
                }}
            >
                <Box sx={{ mb: 2 }}>
                    <DocumentButton
                        onClick={pdf ? () => setOpenShowPDF(true) : selectOneImage}
                        active={!!pdf}
                    >
                        <Box>
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                textAlign="left"
                                color={pdf ? '#2148ff' : '#8E8E93'}
                                gutterBottom
                            >
                                {pdf ? 'Documento carregado' : 'Adicionar documento'}
                            </Typography>
                            <Typography
                                variant="body2"
                                textAlign="left"
                                color="#8E8E93"
                            >
                                {pdf ? 'Toque para visualizar ou alterar' : 'Carregue um arquivo complementar'}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                bgcolor: '#FFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {pdf ? (
                                <FileUploadIcon sx={{ color: '#2148ff' }} />
                            ) : (
                                <ChevronRightIcon sx={{ color: '#2148ff' }} />
                            )}
                        </Box>
                    </DocumentButton>
                </Box>

                <Box sx={{ display: 'flex', gap: 1.5 }}>
                    <Button
                        variant="contained"
                        onClick={selectImage}
                        sx={{
                            flex: 1,
                            bgcolor: '#F2F2F7',
                            color: '#2148ff',
                            borderRadius: 3,
                            p: 1.75,
                            '&:hover': {
                                bgcolor: '#E5E5EA'
                            }
                        }}
                        startIcon={<AddIcon />}
                    >
                        Adicionar fotos
                    </Button>

                    <Button
                        variant="contained"
                        onClick={selectedImages.length > 0 ? handleDeleteImages : close}
                        sx={{
                            flex: 1,
                            bgcolor: selectedImages.length > 0 ? '#FF3B30' : '#2148ff',
                            borderRadius: 3,
                            p: 1.75,
                            '&:hover': {
                                bgcolor: selectedImages.length > 0 ? '#FF2920' : '#1a3acc'
                            }
                        }}
                        startIcon={selectedImages.length > 0 ? <DeleteIcon /> : null}
                    >
                        {selectedImages.length > 0 ? `Excluir (${selectedImages.length})` : 'Concluir'}
                    </Button>
                </Box>
            </Paper>

            <Modal
                open={openShowPDF}
                onClose={() => setOpenShowPDF(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 9999
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        bgcolor: '#000',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 2
                        }}
                    >
                        <IconButton
                            onClick={() => setOpenShowPDF(false)}
                            sx={{
                                bgcolor: 'rgba(255,255,255,0.2)',
                                '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                            }}
                        >
                            <CloseIcon sx={{ color: '#FFF' }} />
                        </IconButton>
                        <IconButton
                            onClick={deletePDF}
                            sx={{
                                bgcolor: 'rgba(255,255,255,0.2)',
                                '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                            }}
                        >
                            <DeleteIcon sx={{ color: '#FFF' }} />
                        </IconButton>
                    </Box>

                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flex: 1,
                            p: 2
                        }}
                    >
                        <img
                            src={pdf}
                            alt=""
                            style={{
                                maxWidth: '100%',
                                maxHeight: '80vh',
                                borderRadius: 12
                            }}
                        />
                    </Box>

                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={selectOneImage}
                            sx={{
                                bgcolor: '#FFF',
                                color: '#000',
                                borderRadius: 3,
                                p: 2,
                                width: '100%',
                                '&:hover': {
                                    bgcolor: '#F2F2F7'
                                }
                            }}
                        >
                            Escolher outro documento
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </StyledContainer>
    );
}