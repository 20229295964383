import React, { useState } from 'react';
import { 
    Box, 
    Typography, 
    LinearProgress,
    IconButton,
    Card,
    Grid,
    Modal,
    styled,
} from '@mui/material';
import { 
    Close as CloseIcon,
    FileCopy as FileTextIcon,
    Person2 as Person2Icon,
    Favorite as FavoriteIcon,
    People as PeopleIcon,
    PublicOutlined as PublicIcon,
    RemoveRedEye as EyeIcon,
    HelpOutline as HelpIcon,
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useDialogue } from '../../app/config/modal/dialogue/useDialogue';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './style.css'

const ImageContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    height: '300px',
    overflow: 'hidden',
    backgroundColor: '#000'
});

const NavigationButton = styled(IconButton)({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    width: '40px',
    height: '40px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(4px)',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    '& .MuiSvgIcon-root': {
        color: '#FFF',
    }
});

const GradientOverlay = styled(Box)({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.8), transparent)',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 1
});

const StyledImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover'
});

const ContentContainer = styled(Box)({
    padding: '20px',
    backgroundColor: '#F2F2F2'
});

const MainCard = styled(Card)({
    backgroundColor: '#FFF',
    borderRadius: 16,
    padding: 20,
    marginBottom: 16,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)'
});

const InfoCard = styled(Card)({
    backgroundColor: '#FFF',
    borderRadius: 16,
    padding: 16,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    height: '100%'
});

const IconWrapper = styled(Box)({
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#F0F3FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12
});

export default function InfoCode({ data }) {
    const theme = useTheme();
    const { openDialogue, closeDialogue } = useDialogue();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showPdf, setShowPdf] = useState(false);

    const {
        images = [],
        nome,
        desc,
        idade,
        estadoCivil,
        selectedValueSG,
        nacionalidade,
        pdf,
        cadas,
        progress
    } = data || {};

    const showGenderInfo = () => {
        const message = `Caso você não saiba:\n\n` +
            `Cisgênero: Pessoa que se apresenta ao mundo e se identifica com seu gênero de nascimento.\n\n` +
            `Transgênero: Pessoa que se apresenta ao mundo e se identifica com gênero diferente do seu gênero de nascimento. Engloba tanto as travestis quanto as pessoas transexuais.`;

        openDialogue("Informações Importantes", message, [
            {
                text: "OK",
                onClick: () => closeDialogue()
            }
        ]);
    };

    const renderInfoCard = (icon, title, value, onHelp = null) => (
        <Grid item xs={12} sm={6}>
            <InfoCard>
                <IconWrapper>
                    {icon}
                </IconWrapper>
                <Typography color="textSecondary" variant="body2" gutterBottom>
                    {title}
                </Typography>
                <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle1" fontWeight="600" color="textPrimary" sx={{ flex: 1 }}>
                        {value}
                    </Typography>
                    {onHelp && (
                        <IconButton size="small" onClick={onHelp}>
                            <HelpIcon sx={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    )}
                </Box>
            </InfoCard>
        </Grid>
    );


    return (
        <Box sx={{ backgroundColor: '#F2F2F2', height: '100%' }}>
            <ImageContainer>
                <Swiper
                    modules={[Navigation, Pagination]}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                    pagination={{
                        el: '.swiper-pagination',
                        clickable: true,
                        type: 'bullets',
                        bulletActiveClass: 'swiper-pagination-bullet-active',
                        bulletClass: 'swiper-pagination-bullet',
                    }}
                    loop={true}
                    style={{ height: '100%' }}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <StyledImage src={image} alt={`${nome} - ${index + 1}`} />
                        </SwiperSlide>
                    ))}
                    
                    <NavigationButton 
                        className="swiper-button-prev" 
                        sx={{ left: 16 }}
                    >
                        <ChevronLeftIcon />
                    </NavigationButton>
                    
                    <NavigationButton 
                        className="swiper-button-next" 
                        sx={{ right: 16 }}
                    >
                        <ChevronRightIcon />
                    </NavigationButton>

                    <Box 
                        className="swiper-pagination" 
                        sx={{ 
                            position: 'absolute',
                            bottom: '60px!important',
                            zIndex: 2,
                            '& .swiper-pagination-bullet': {
                                width: '24px',
                                height: '2px',
                                borderRadius: '1px',
                                backgroundColor: '#FFF',
                                opacity: 0.4,
                                '&.swiper-pagination-bullet-active': {
                                    opacity: 1
                                }
                            }
                        }} 
                    />
                </Swiper>

                <GradientOverlay>
                    <Typography variant="h6" color="white" fontWeight="600" gutterBottom>
                        {nome}
                    </Typography>
                    <Typography variant="body2" color="white" sx={{ opacity: 0.9 }}>
                        Usuário(a) cadastrado(a) pelo(a) {cadas}
                    </Typography>
                </GradientOverlay>
            </ImageContainer>

            <LinearProgress 
                variant="determinate" 
                value={progress || 0} 
                sx={{ 
                    height: 5,
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#233DFF'
                    }
                }} 
            />

            <ContentContainer>
                <MainCard>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <FileTextIcon sx={{ color: '#233DFF', mr: 1.5 }} />
                        <Typography variant="h6" color="textPrimary">
                            Descrição
                        </Typography>
                    </Box>
                    <Typography variant="body1" color="textSecondary">
                        {desc}
                    </Typography>
                </MainCard>

                <Grid container spacing={2} sx={{ mb: 3 }}>
                    {renderInfoCard(
                        <Person2Icon sx={{ color: '#233DFF' }} />,
                        "Idade",
                        idade
                    )}
                    {renderInfoCard(
                        <FavoriteIcon sx={{ color: '#233DFF' }} />,
                        "Estado Civil",
                        estadoCivil
                    )}
                    {renderInfoCard(
                        <PeopleIcon sx={{ color: '#233DFF' }} />,
                        "Sexo e Gênero",
                        selectedValueSG,
                        showGenderInfo
                    )}
                    {renderInfoCard(
                        <PublicIcon sx={{ color: '#233DFF' }} />,
                        "País de origem",
                        nacionalidade
                    )}
                </Grid>

                {pdf && (
                    <Card 
                        sx={{ 
                            p: 2, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            borderRadius: 2
                        }}
                        onClick={() => setShowPdf(true)}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconWrapper>
                                <EyeIcon sx={{ color: '#233DFF' }} />
                            </IconWrapper>
                            <Box sx={{ ml: 1.5 }}>
                                <Typography variant="subtitle1" color="textPrimary" fontWeight="600">
                                    Arquivo do usuário
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Usuário disponibilizou um arquivo
                                </Typography>
                            </Box>
                        </Box>
                        <ChevronRightIcon />
                    </Card>
                )}
            </ContentContainer>

            <Modal
                open={showPdf}
                onClose={() => setShowPdf(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'rgba(0, 0, 0, 0.9)',
                    zIndex: 9999
                }}
            >
                <Box sx={{ width: '90%', height: '90%', position: 'relative' }}>
                    <IconButton
                        onClick={() => setShowPdf(false)}
                        sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img
                        src={pdf}
                        alt="PDF Preview"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </Box>
            </Modal>
        </Box>
    );
}