import './style.css'
import React, { useEffect, useState } from 'react'
import logo from '../../../assets/Logo-TwoAlert.jpeg'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import UserAvatar from '../../../components/userTwoAlert'
import { useContext } from 'react';
import { UserContext } from '../../../components/UserProvider';

import Account from './account'
import Profile from './profile'
import Password from './password'
import Idiom from './idiom'

import UpdateAccount from '../modal/updateAccount'
import ReportProblem from '../modal/reportProblem'
import Signature from './signature'

export default function Settings() {

    let location = useLocation();

    const { photoURL, displayName, email } = useContext(UserContext)

    const [width, setWidth] = useState(null)

    const renderScreen = () => {
        switch (location.pathname) {
            case '/account':
                return <Account />;
            case '/account/profile':
                return <Profile setWidth={setWidth} />;
            case '/account/password':
                return <Password />;
            case '/account/idiom':
                return <Idiom />;
            case '/account/subscription':
                return <Signature />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (width == 100) {
            setTimeout(() => {
                setWidth(null)
            }, 200);
        }
    }, [width])

    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showReportModal, setShowReportModal] = useState(false)


    return (
        <div className='allSettings'>

            <ReportProblem showReportModal={showReportModal} setShowReportModal={setShowReportModal} />
            <UpdateAccount showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} />

            {width && (
                <div className='containerBar'>
                    <div className='bar' style={{ width: `${width}%` }}></div>
                </div>
            )}
            <div className='settingsCenter'>
                <header>
                    <div className='contentBase'>
                        <div className='logoContent'>
                            <img src={logo} alt='Logo TwoAlert' />
                            <h3>TwoAlert</h3>
                        </div>
                        <div className='navMenu'>
                            <nav>
                                <ul>
                                    <Link to={`/`}>Início</Link>
                                    <Link to={`/users`}>Usuários</Link>
                                    <a href='https://www.twoalert.com.br/contact' target="_blank" rel="noopener">Contato</a>
                                    <Link onClick={() => setShowReportModal(true)}>Reportar</Link>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className='contentBase'>
                        <div className='buttonOption' onClick={() => setShowUpdateModal(true)}>
                            <FontAwesomeIcon icon={faBolt} />
                            <span>Atualizar</span>
                        </div>
                        <UserAvatar photoURL={photoURL} email={email} userName={displayName} />
                    </div>
                </header>
                <div className='mainContentSettings'>
                    <div className='mainContent'>
                        <div className='contentTitleSettings paddingSetting'>
                            <h1>Configurações</h1>
                            <span>Gerencie suas preferências aqui!</span>
                        </div>

                        <div className='containerOptionSettings paddingSetting'>
                            <div className='contentOptionSettings'>
                                <Link to='/account' className={location.pathname === '/account' ? 'active' : ''}>Minha conta</Link>
                                <Link to='/account/profile' className={location.pathname === '/account/profile' ? 'active' : ''}>Perfil</Link>
                                <Link to='/account/password' className={location.pathname === '/account/password' ? 'active' : ''}>Senha</Link>
                                <Link to='/account/subscription' className={location.pathname === '/account/subscription' ? 'active' : ''}>Assinatura</Link>
                                <Link to='/account/idiom' className={location.pathname === '/account/idiom' ? 'active' : ''}>Idioma</Link>
                            </div>
                        </div>

                        <div className='containerContentSettings'>
                            <div className='paddingSetting'>
                                {renderScreen()}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}