import axios from 'axios';
import { fetchSubscriptions } from '../../../../../components/subscriptionService';

export const handleManageSubscription = (subscriberOS, userUid, config, openDialogue, closeDialogue) => {
    if (subscriberOS === 'iOS') {
        // Assinatura iOS
        openDialogue(
            'Gerenciar Assinatura',
            'Esta assinatura foi realizada através da App Store. Para gerenciar sua assinatura, acesse as configurações do seu Apple ID em appleid.apple.com.',
            [
                {
                    text: 'Ir para Apple ID',
                    onClick: () => window.open('https://appleid.apple.com', '_blank')
                },
                {
                    text: 'Cancelar',
                    onClick: () => closeDialogue()
                }
            ]
        );
    } else if (subscriberOS === 'Android') {
        // Assinatura Android
        openDialogue(
            'Gerenciar Assinatura',
            'Esta assinatura foi realizada através da Google Play Store. Para gerenciar sua assinatura, acesse a Google Play Store ou visite play.google.com/store/account/subscriptions.',
            [
                {
                    text: 'Ir para Google Play',
                    onClick: () => window.open('https://play.google.com/store/account/subscriptions', '_blank')
                },
                {
                    text: 'Cancelar',
                    onClick: () => closeDialogue()
                }
            ]
        );
    } else {
        // Assinatura Web
        redirectToBillingPortal(userUid, config, openDialogue, closeDialogue);
    }
};

export const handleCancelSubscription = (subscriberOS, subscriptionId, setLoading, user, setActiveSubscription, config, openDialogue, closeDialogue) => {
    if (subscriberOS === 'iOS') {
        openDialogue(
            'Cancelar Assinatura',
            'Para cancelar sua assinatura, acesse as configurações do seu Apple ID em appleid.apple.com.',
            [
                {
                    text: 'Ir para Apple ID',
                    onClick: () => window.open('https://appleid.apple.com', '_blank')
                },
                {
                    text: 'Cancelar',
                    onClick: () => closeDialogue()
                }
            ]
        );
    } else if (subscriberOS === 'Android') {
        openDialogue(
            'Cancelar Assinatura',
            'Para cancelar sua assinatura, acesse a Google Play Store ou visite play.google.com/store/account/subscriptions.',
            [
                {
                    text: 'Ir para Google Play',
                    onClick: () => window.open('https://play.google.com/store/account/subscriptions', '_blank')
                },
                {
                    text: 'Cancelar',
                    onClick: () => closeDialogue()
                }
            ]
        );
    } else {
        // Para assinaturas web
        handleCancelSubscriptionWeb(subscriptionId, setLoading, user, setActiveSubscription, config, openDialogue, closeDialogue);
    }
};

const handleCancelSubscriptionWeb = async (subscriptionId, setLoading, user, setActiveSubscription, { SERVER, AUTH_TOKEN }, openDialogue, closeDialogue) => {
    if (!subscriptionId) {
        openDialogue('Erro', 'Nenhuma assinatura selecionada para cancelar.', [
            { text: 'OK', onClick: () => closeDialogue() }
        ]);
        return;
    }

    openDialogue(
        'Confirmar Cancelamento',
        'Você tem certeza que deseja cancelar sua assinatura?',
        [
            {
                text: 'Não',
                onClick: () => closeDialogue()
            },
            {
                text: 'Sim',
                onClick: async () => {
                    closeDialogue();
                    setLoading(true);

                    try {
                        const response = await axios.post(
                            `${SERVER}/cancel-subscription`,
                            { subscriptionId },
                            {
                                headers: {
                                    'Authorization': `Bearer ${AUTH_TOKEN}`,
                                }
                            }
                        );

                        if (response.data.success) {
                            openDialogue('Sucesso', 'Assinatura cancelada com sucesso!', [
                                { text: 'OK', onClick: async () => {
                                    closeDialogue();
                                    const subscriptionData = await fetchSubscriptions(user.uid);
                                    if (subscriptionData?.isActive) {
                                        setActiveSubscription(subscriptionData);
                                    }
                                }}
                            ]);
                        } else {
                            openDialogue('Erro', response.data.message || 'Não foi possível cancelar a assinatura.', [
                                { text: 'OK', onClick: () => closeDialogue() }
                            ]);
                        }
                    } catch (error) {
                        console.error(error);
                        openDialogue('Erro', 'Ocorreu um erro ao cancelar a assinatura.', [
                            { text: 'OK', onClick: () => closeDialogue() }
                        ]);
                    } finally {
                        setLoading(false);
                    }
                }
            }
        ]
    );
};

const redirectToBillingPortal = async (userUid, { SERVER, AUTH_TOKEN }, openDialogue, closeDialogue) => {

    try {
        const response = await fetch(`${SERVER}/api/user-billing-portal/${userUid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`,
            },
            body: JSON.stringify({ return_url: "https://app.twoalert.com.br/" }),
        });

        const data = await response.json();

        if (response.ok) {
            openDialogue(
                'Aviso de Redirecionamento',
                'Você será redirecionado para um site seguro onde poderá gerenciar suas informações de pagamento e assinatura no portal do TwoAlert. Deseja continuar?',
                [
                    {
                        text: 'Cancelar',
                        onClick: () => closeDialogue()
                    },
                    {
                        text: 'Ir',
                        onClick: () => {
                            window.open(data.url, '_blank');
                            closeDialogue();
                        }
                    }
                ]
            );
        } else {
            openDialogue('Algo deu errado!', 'Ocorreu um erro, por favor tente novamente.', [
                { text: 'OK', onClick: () => closeDialogue() }
            ]);
        }
    } catch (error) {
        console.error('error: ', error);
        openDialogue('Erro', 'Houve um problema ao redirecionar para o portal.', [
            { text: 'OK', onClick: () => closeDialogue() }
        ]);
    }
};