import { db } from "../../config/firebase";
import { auth } from "../../config/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; // Import addDoc and collection

export const getUid = () => {
    return auth.currentUser?.uid;
}

export const sendUserReport = async (title, description, type, reference) => {
    try {
        const uid = getUid();
        if (!uid) throw new Error("Usuário não está logado.");

        await addDoc(collection(db, "userReports"), {
            uid,
            title,
            description,
            type,
            reference,
            timestamp: serverTimestamp() 
        });
    } catch (error) {
        throw new Error("Erro ao enviar relatório.");
    }
};