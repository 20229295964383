import axios from 'axios';
import { auth } from '../firebase';

const fetchSecrets = async () => {
    const getFirebaseToken = async () => {
        return auth.currentUser?.getIdToken();
    };

    try {
        const token = await getFirebaseToken();
        const response = await axios.get('https://server-secrets-key-dot-twoalert-68c80.uc.r.appspot.com/get-secret-config', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar segredos:', error);
        throw new Error('Erro ao buscar segredos');
    }
};

export default fetchSecrets;
