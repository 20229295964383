import './style.css'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import VeracityPercentageGraph from './VeracityPercentageGraph';
import EditedDataGraph from './EditedDataGraph';
import PercentageCircle from './PercentageCircle';
import { useDialogue } from '../../app/config/modal/dialogue/useDialogue';
import DataImage from './dataImage';

const TypingText = ({ text, speed }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timer = setTimeout(() => {
                setIndex(index + 1);
            }, speed);
            return () => clearTimeout(timer);
        }
    }, [index, text, speed]); // Agora depende de index

    return <h3 className='titleComponent'>{text.substring(0, index)}</h3>;
};

export function DataAnalysed({ animateBall, data, veracityPercentage, close }) {

    const { openDialogue, closeDialogue } = useDialogue();

    const totalSeconds = 120; // 2 minutos total
    const [secondsLeft, setSecondsLeft] = useState(totalSeconds);
    const radius = 15;
    const strokeWidth = 4;
    const circumference = 2 * Math.PI * radius;

    // Atualiza o tempo restante formatado
    const getFormattedTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        let timer;
        if (secondsLeft > 0) {
            timer = setTimeout(() => {
                setSecondsLeft(secondsLeft - 1);
            }, 1000);
        } else {
            close()
        }
        return () => clearTimeout(timer);
    }, [secondsLeft]);

    // Calcula o offset para a animação do círculo
    const strokeDashoffset =
        circumference - (secondsLeft / totalSeconds) * circumference;

    const editedData = data?.edited;
    const viewsData = data?.viewsDetails;
    const verificationData = data?.verificationDataInfo ?? {};

    const viewsNotMine = viewsData?.filter(view => view?.viewerUid !== data?.userId);

    const {
        cpfExiste = false,
        dataNascimentoCorreta = false,
        nomeCompleto = false,
        nomeCorreto = false,
        partesCorretas = 0,
        partesIncorretas = 0,
        similaridadeNomeCompleto = 0,
        similaridadePartesEnviadas = 0,
        dataInvalidaParaDados,
        isTeenager,
        isChild
    } = verificationData;

    const [currentMessage, setCurrentMessage] = useState('');
    const messages = [
        "Assegurando a autenticidade das informações para a sua tranquilidade.",
        "Promovendo transparência e confiança nas interações online.",
        "Cada detalhe verificado aumenta a segurança do seu encontro virtual.",
        "Compromisso com a precisão: verificamos para que você não precise se preocupar.",
        "Transformando dados em segurança: a veracidade ao alcance de suas mãos.",
        "Proteção em cada passo: validando informações para as interações mais seguras.",
        "Construindo um ambiente digital onde a confiança é a base de cada conexão.",
        "Segurança digital é a nossa prioridade: informações verificadas, interações seguras.",
        "Dando poder à verdade em um mundo virtual: suas interações mais seguras do que nunca.",
        "Na vanguarda da segurança online: onde a verificação encontra a paz de espírito."
    ];

    const [style, setStyle] = useState({});
    let messageIndex = 0;

    const changeMessage = () => {
        // Animação para desaparecer
        setStyle({ transform: 'translateY(-10px)', opacity: 0 });

        setTimeout(() => {
            // Mudança da mensagem quando invisível
            messageIndex = (messageIndex + 1) % messages.length;
            setCurrentMessage(messages[messageIndex]);

            // Animação para reaparecer
            setStyle({ transform: 'translateY(10px)', opacity: 0 });

            setTimeout(() => {
                setStyle({ transform: 'translateY(0)', opacity: 1 });
            }, 500);
        }, 500);
    };

    useEffect(() => {
        setCurrentMessage(messages[0]);
        const interval = setInterval(changeMessage, 6000);
        return () => clearInterval(interval);
    }, []);

    const [selectedImage, setSelectedImage] = useState(null);
    const onImagePress = (data) => {
        setSelectedImage(data)
    };

    // const [pageDetails, setPageDetails] = useState([]);

    // useEffect(() => {
    //     if (selectedImage?.webDetectionResults?.pagesWithMatchingImages.length > 1) {
    //         const fetchPageDetails = async () => {
    //             const urls = selectedImage.webDetectionResults.pagesWithMatchingImages.map(p => p.url);

    //             const headers = {
    //                 'Authorization': `Bearer ${AUTH_TOKEN}`  // Adicionando o cabeçalho de autorização
    //             };

    //             try {
    //                 const response = await axios.post(`${SERVER_URL}/scrape`, { urls }, { headers: headers });
    //                 setPageDetails(response.data);
    //             } catch (error) {
    //                 console.error('Erro ao buscar detalhes da página:', error);
    //             }
    //         };
    //         fetchPageDetails();
    //     }
    // }, [selectedImage]);


    function twoalert() {
        openDialogue("Nota TwoAlert:", "Apesar de nossas pesquisas, dados e análises serem precisos e cuidadosamente elaborados, visando oferecer segurança adicional a todos os nossos clientes, recomendamos que você sempre verifique todas as informações com atenção e mantenha-se alerta contra pessoas enganosas, especialmente na web e em outras plataformas. Lembre-se: o TwoAlert é uma ferramenta de apoio, mas não substitui a atuação das autoridades locais. Mantenha-se seguro (a) e informado (a)! 👮‍♂️🔍", [
            {
                text: "OK",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    }

    const handlePressInfo = () => {
        openDialogue("Entendendo os Indicadores de Dados", "Os indicadores verdes e vermelhos mostram a verificação dos dados fornecidos. Um ícone verde (✓) indica que a informação corresponde aos registros oficiais ou esperados, enquanto um ícone vermelho (x) indica discrepâncias ou informações que não correspondem aos registros esperados. Esses indicadores são uma forma visual de representar a veracidade e precisão das informações fornecidas.", [
            {
                text: "OK",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    };

    const handlePressViewInfo = () => {
        openDialogue("Entendendo os Dados de Visualização", "Esta seção mostra a confiabilidade das informações do usuário com base nas visualizações de outras pessoas. Uma veracidade de 50% ou mais sugere que as informações são consistentemente vistas como confiáveis por outros usuários. Pontuações abaixo de 50% podem indicar discrepâncias ou que os dados não são consistentemente reconhecidos como precisos. Ondulações ou variações grandes no gráfico podem sugerir que o usuário está sendo percebido de maneiras diferentes por diferentes pessoas, o que pode ser um indicativo de múltiplas identidades ou informações enganosas.", [
            {
                text: "Entendi",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    };

    const handlePressEditInfo = () => {
        openDialogue("Entendendo os Dados de Edição", "Esta seção detalha a frequência e a magnitude das alterações feitas nas informações do usuário. O gráfico mostra as últimas seis edições e a porcentagem de mudança para cada uma. Uma edição de 22% ou mais é considerada alta, indicando alterações significativas nas informações. Isso pode refletir uma atualização importante nos dados do usuário ou inconsistências frequentes. Acompanhe essas mudanças para entender melhor a estabilidade e a confiabilidade das informações ao longo do tempo.", [
            {
                text: "Entendi",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    };

    const handlePressCelebritiesInfo = () => {
        openDialogue("Astros Identificados", "Esta seção exibe as celebridades detectadas nas imagens do usuário, juntamente com a porcentagem de confiança de que a identificação está correta. Cada imagem analisada pelo aplicativo é comparada a um banco de dados de figuras públicas conhecidas. A correspondência é expressa em porcentagem, refletindo a precisão com que o sistema acredita na identificação. Enquanto a tecnologia é avançada, recomenda-se uma verificação adicional para garantir a precisão, especialmente em casos de alta semelhança com figuras públicas.", [
            {
                text: "Entendi",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    };

    const handlePressImageInfo = () => {
        openDialogue("Dados das Imagens", "Esta seção exibe uma análise detalhada de cada imagem enviada, proporcionando insights adicionais para a verificação dos dados do usuário. As análises incluem a detecção de rostos, a possível correspondência com celebridades, e outros marcadores relevantes que ajudam a entender o contexto e a autenticidade de cada imagem. Clique em qualquer imagem para explorar informações detalhadas derivadas das análises. Essas informações são cruciais para assegurar a precisão dos dados no TwoAlert, ajudando a construir um ambiente mais seguro e confiável para todos os usuários.", [
            {
                text: "Entendi",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    };

    const handlePressWebDataInfo = () => {
        openDialogue("Dados Encontrados na Web", "Esta seção exibe links para imagens encontradas na internet que correspondem ou são visualmente semelhantes às imagens enviadas pelo usuário. Isso inclui: \n\n1. Imagens Correspondentes: Imagens que são idênticas ou muito próximas às enviadas, indicando que podem não ser únicas ou pessoais.\n\n2. Imagens Correspondentes Parciais: Imagens que têm certa semelhança com as enviadas, podendo indicar uso comum na web.\n\n3. Imagens Visualmente Semelhantes: Imagens que não são exatamente iguais, mas compartilham características visuais com as enviadas.\n\nEssa análise ajuda a identificar se as imagens do usuário são exclusivas ou se já apareceram online, o que pode ser um indicativo de tentativa de se passar por outra pessoa usando imagens da internet. Clique em cada categoria para explorar as imagens relacionadas.", [
            {
                text: "Entendi",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    };

    const handlePressKeywordsInfo = () => {
        openDialogue("Palavras-chave das Imagens", "Esta seção destaca palavras-chave extraídas das imagens enviadas, com base em sua relevância e pontuação de destaque. As palavras-chave são determinadas pela análise de conteúdo visual e podem incluir nomes, lugares, conceitos ou outros elementos significativos identificados nas imagens.\n\nAs palavras-chave com pontuação acima de 0.5 são consideradas mais relevantes e são exibidas aqui para fornecer um entendimento rápido do conteúdo das imagens. Elas ajudam a entender o contexto e a natureza das imagens, contribuindo para análises mais precisas e informadas.", [
            {
                text: "Entendi",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    };

    const handlePressRelatedPagesInfo = () => {
        openDialogue("Páginas Relacionadas às Imagens", "Esta seção mostra as páginas da web onde imagens semelhantes ou idênticas foram encontradas. Isso inclui correspondências completas, parciais e imagens visualmente semelhantes. As URLs fornecem contexto sobre onde as imagens podem ter sido publicadas ou referenciadas.\n\nEsse recurso é útil para identificar a origem das imagens e para entender em quais contextos elas estão sendo usadas na web. É uma ferramenta valiosa para verificar a autenticidade e a originalidade das imagens e pode ser particularmente útil para identificar uso de imagens de celebridades, conteúdos replicados, ou até mesmo para encontrar menções relevantes sobre o usuário ou eventos associados. As informações são filtradas para excluir links de redes sociais, preservando a privacidade do usuário.", [
            {
                text: "Entendi",
                onClick: () => {
                    closeDialogue();
                }
            },
        ]);
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [currentUrl, setCurrentUrl] = useState('');

    const handleOpenLink = (url) => {
        setCurrentUrl(url);
        setModalVisible(true);
    };

    return (
        <>
            {selectedImage !== null && (
                <DataImage selectedImage={selectedImage} setSelectedImage={setSelectedImage} handlePressWebDataInfo={handlePressWebDataInfo} />
            )}

            <div className='allInfoVeracityCode'>
                <header>
                    <div className='closeButtonVeracityCode' onClick={() => close()}>
                        <FontAwesomeIcon icon={faX} />
                    </div>
                    <div className='titleAnalitcs'>
                        <TypingText text={`Análise de dados`} speed={50} />
                    </div>
                    <div className='countDownCloseData'>
                        <svg width="40" height="40" viewBox="0 0 40 40">
                            <circle
                                cx="20"
                                cy="20"
                                r={radius}
                                fill="none"
                                stroke="#EEE"
                                strokeWidth={strokeWidth}
                            />
                            <circle
                                cx="20"
                                cy="20"
                                r={radius}
                                fill="none"
                                stroke="#233DDF"
                                strokeWidth={strokeWidth}
                                strokeDasharray={circumference}
                                strokeDashoffset={strokeDashoffset}
                                strokeLinecap="round"
                                transform="rotate(-90 20 20)"
                            />
                        </svg>
                        <span style={{
                            position: 'absolute',
                            fontSize: 9,
                            transform: 'translate(-50%, -50%)',
                            top: '50%',
                            left: '50%',
                        }}>
                            {getFormattedTime(secondsLeft)}
                        </span>
                    </div>
                </header>
                <div className='veracityCode'>
                    <div className='analytcs'>

                        <section>
                            <div className="phrasesTwoAlert" style={style}>
                                <span>{currentMessage}</span>
                            </div>
                        </section>

                        <section>
                            <div className='headerSection'>
                                <h3>Dados de existência</h3>
                                <div className='buttonAboutSection' onClick={handlePressInfo}>
                                    <span>?</span>
                                </div>
                            </div>

                            <div className='containerInformation'>

                                <div className="contentAboutUser">
                                    <span>Usuário existe</span>
                                    <div className={`verificationContainer ${cpfExiste ? 'containerSuccess' : 'containerError'}`}>
                                        <FontAwesomeIcon icon={cpfExiste ? faCheck : faX} color="#FFFFFF" />
                                    </div>
                                </div>

                                {!dataInvalidaParaDados
                                    ?
                                    <>
                                        <div className="contentAboutUser">
                                            <span>Idade do usuário</span>
                                            <div className={`verificationContainer ${dataNascimentoCorreta ? 'containerSuccess' : 'containerError'}`}>
                                                <FontAwesomeIcon icon={dataNascimentoCorreta ? faCheck : faX} color="#FFFFFF" />
                                            </div>
                                        </div>

                                        {isTeenager && data?.idade >= 18 && (
                                            <div className='contentAboutUser'>
                                                <span>
                                                    Importante: Este usuário é classificado como adolescente, com idade entre 12 a 17 anos, não correspondendo à idade adulta de {data?.idade} anos conforme indicado. Esta informação é essencial para garantir a precisão dos dados cadastrais.
                                                </span>
                                            </div>
                                        )}

                                        {isChild && data?.idade >= 12 && (
                                            <div className='contentAboutUser'>
                                                <span>
                                                    Atenção: As informações disponíveis indicam que, na realidade, este usuário é uma criança, com idade abaixo de 12 anos, e não possui a idade de {data?.idade} anos como previamente indicado. Este detalhe é crucial e pedimos que seja levado em consideração ao revisar os dados.
                                                </span>
                                            </div>
                                        )}

                                        <div className="contentAboutUser">
                                            <span>Nome do usuário</span>
                                            <div className={`verificationContainer ${nomeCorreto ? 'containerSuccess' : 'containerError'}`}>
                                                <FontAwesomeIcon icon={nomeCorreto ? faCheck : faX} color="#FFFFFF" />
                                            </div>
                                        </div>

                                        {similaridadePartesEnviadas >= 0.76 && similaridadePartesEnviadas < 1.0 && (
                                            <div className='contentAboutUser'>
                                                <span>
                                                    Identificamos pequenas inconsistências nos caracteres do nome fornecido. É possível que essas variações sejam resultado de um erro de digitação no momento do cadastro. Estas diferenças sutis não comprometem tanto a identificação principal.
                                                </span>
                                            </div>
                                        )}

                                        <div className="contentAboutUser">
                                            <span>Nome completo</span>
                                            <div className={`verificationContainer ${nomeCompleto ? 'containerSuccess' : 'containerError'}`}>
                                                <FontAwesomeIcon icon={nomeCompleto ? faCheck : faX} color="#FFFFFF" />
                                            </div>
                                        </div>

                                        {partesIncorretas > 0 && (
                                            <>
                                                <div className="contentAboutUser">
                                                    <span>Nome com partes incorretas: </span>
                                                    <span ><strong>{partesIncorretas}</strong></span>
                                                </div>

                                                <div className="contentAboutUser">
                                                    <span>Nome com partes corretas</span>
                                                    <span><strong>{partesCorretas}</strong></span>
                                                </div>
                                            </>
                                        )}
                                    </>
                                    :
                                    <div className='contentAboutUser'>
                                        <span>
                                            Observamos que alguns dos dados fornecidos podem não refletir as informações autênticas, o que impede a validação completa dos detalhes necessários.
                                        </span>
                                    </div>
                                }
                            </div>

                        </section>

                        {data?.analysisResponse?.PalavrasChaves.length > 0 && (
                            <section>
                                <div className='headerSection'>
                                    <h3>Palavras chave</h3>
                                </div>

                                <div className='rowKeywords'>
                                    {data?.analysisResponse?.PalavrasChaves.map((keyword, index) => (
                                        <div key={index} className='keyword'>
                                            <span>{keyword}</span>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}

                        {data?.analysisResponse?.Caracteristicas && (
                            <section>
                                <div className='headerSection'>
                                    <h3>Informações úteis</h3>
                                </div>

                                <div className='rowKeywords'>
                                    <div className='keyword'>
                                        <span>{data?.analysisResponse?.Caracteristicas}</span>
                                    </div>
                                </div>
                            </section>
                        )}

                        {viewsNotMine.length >= 2 && (
                            <section>
                                <div className='headerSection'>
                                    <h3>Dados de visualização</h3>
                                    <div className='buttonAboutSection' onClick={handlePressViewInfo}>
                                        <span>?</span>
                                    </div>
                                </div>
                                <VeracityPercentageGraph viewsDetails={viewsNotMine} />
                                <div className='confidence'>
                                    <div className='buttonAboutSection'>
                                        <span>i</span>
                                    </div>
                                    <span>Score maior ou igual a <strong style={{ color: '#233DDF', fontWeight: '500' }}>50</strong> pode ser confiável</span>
                                </div>
                            </section>
                        )}

                        {Array.isArray(data?.analysisResults?.celebrityInfoInAllImages) && data?.analysisResults?.celebrityInfoInAllImages.length > 0 && (
                            <section>
                                <div className='headerSection'>
                                    <h3>Astros identificados</h3>
                                    <div className='buttonAboutSection' onClick={handlePressCelebritiesInfo}>
                                        <span>?</span>
                                    </div>
                                </div>

                                <div className='containerActors'>
                                    {data.analysisResults.celebrityInfoInAllImages.map((celebrity, index) => (
                                        <div key={index} className='containerContentActors'>
                                            <img src={celebrity.imageUrl} />
                                            <div className='contentInfoActor'>
                                                <h3>{celebrity.Name}</h3>
                                                <span>{`Com um total de ${celebrity.MatchConfidence.toFixed(2)}% de confiança`}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </section>
                        )}

                        {editedData?.length >= 2 && (
                            <section>
                                <div className='headerSection'>
                                    <h3>Dados de edição</h3>
                                    <div className='buttonAboutSection' onClick={handlePressEditInfo}>
                                        <span>?</span>
                                    </div>
                                </div>
                                <EditedDataGraph editedData={editedData} />
                                <div className='confidence'>
                                    <div className='buttonAboutSection'>
                                        <span>i</span>
                                    </div>
                                    <span>Considere <strong style={{ color: '#233DDF', fontWeight: '500' }}>22%</strong> como uma alta alteração de dados</span>
                                </div>
                            </section>
                        )}

                        <section>
                            <div className='headerSection'>
                                <h3>Dados das imagens</h3>
                                <div className='buttonAboutSection' onClick={handlePressImageInfo}>
                                    <span>?</span>
                                </div>
                            </div>

                            <div className='rowImagensContainer'>
                                {data?.analysisResults?.faceDetails.map((data, index) => (
                                    <div key={index} className='itemImageDetails' onClick={() => onImagePress(data)}>
                                        <img style={{ width: 60, height: 60, borderRadius: 10, objectFit: 'cover' }} draggable={false} src={data.imageUrl} />
                                        <div className='blurButtonCheckDetails'>
                                            <div className='buttonAboutSection'>
                                                <span>i</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </section>

                        <section>
                            <div className='headerSection'>
                                <h3>Resultado final</h3>
                                <div className='buttonAboutSection' onClick={twoalert}>
                                    <span>?</span>
                                </div>
                            </div>

                            {!data?.analysisResponse?.TextoTemSentido && (
                                <div className="contentAboutUser" style={{ marginBottom: 10 }}>
                                    <p>Usuário digitou um texto que parece não ter sentido, verifique o texto detalhadamente para confirmar</p>
                                </div>
                            )}

                            {data?.analysisResponse?.TextoEmPrimeiraPessoa && (
                                <div className="contentAboutUser" style={{ marginBottom: 10 }}>
                                    <p>O estilo narrativo do texto sugere que foi redigido em primeira pessoa, o que pode indicar um autocadastro ou autodescrição por parte do usuário. Essa observação contrasta com as informações disponíveis, que indicam {data?.cadastrante} como responsável pelo cadastro, conforme os dados fornecidos.</p>
                                </div>
                            )}

                            <div className='twoalertConfidence'>
                                <p>Score de confiabilidade dos dados</p>
                                <PercentageCircle percentage={Math.round(veracityPercentage)} size={80} isPercentage={true} />
                            </div>

                            <div className='confidence'>
                                <div className='buttonAboutSection'>
                                    <span>i</span>
                                </div>
                                <span>Maior ou igual a <strong style={{ color: '#233DDF', fontWeight: '500' }}>50</strong> pode ser confiável</span>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </>
    )
}