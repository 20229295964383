import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    Button,
    Stack,
    IconButton,
    Tooltip,
    Paper
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
    faUser,
    faAddressCard,
    faEye,
    faMap
} from '@fortawesome/free-regular-svg-icons';
import { faCompass } from '@fortawesome/free-regular-svg-icons';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import logo from '../../assets/Logo-TwoAlert.jpeg'

const MenuWrapper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'isOpen'
})(({ theme, isOpen }) => ({
    position: 'relative',
    width: isOpen ? 280 : 88,
    height: '100vh',
    backgroundColor: '#f2f2f2',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    transition: 'width 0.3s ease-in-out',
    overflow: 'visible'
}));

const MenuContent = styled(Box)({
    width: '100%',
    height: '100%',
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px'
});

const Header = styled(Box)(({ isOpen }) => ({
    display: 'flex',
    justifyContent: isOpen ? 'space-between' : 'center',
    alignItems: 'center',
    padding: '8px 0',
    position: 'relative'
}));

const Logo = styled('img')({
    width: 35,
    height: 35,
    borderRadius: 8,
    transition: 'transform 0.2s ease',
    '&:hover': {
        transform: 'scale(1.05)'
    }
});

const StyledLink = styled(Link, {
    shouldForwardProp: (prop) => !['active', 'isOpen'].includes(prop)
})(({ active, isOpen }) => ({
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: isOpen ? 'flex-start' : 'center',
    padding: '14px 8px',
    marginBottom: '8px',
    borderRadius: 12,
    transition: 'all 0.2s ease-in-out',
    position: 'relative',
    backgroundColor: active === 'true' ? '#233DFF' : 'transparent',
    width: '100%',
    '&:hover': {
        backgroundColor: active === 'true' ? '#233DFF' : '#f5f5f5'
    },
    '&::before': active === 'true' ? {
        content: '""',
        position: 'absolute',
        left: -16,
        top: '50%',
        transform: 'translateY(-50%)',
        width: 3,
        height: '40%',
        backgroundColor: '#233DFF',
        borderRadius: '0 4px 4px 0'
    } : {}
}));

const MenuItemText = styled(Typography)(({ active }) => ({
    marginLeft: 16,
    fontSize: 15,
    fontFamily: '"SF-Pro-Text-Regular", sans-serif',
    fontWeight: 500,
    color: active === 'true' ? '#ffffff' : '#666666',
    transition: 'all 0.2s ease',
    whiteSpace: 'nowrap'
}));

const StyledListItemIcon = styled(ListItemIcon)(({ active }) => ({
    minWidth: 'auto',
    justifyContent: 'center',
    '& svg': {
        fontSize: 22,
        color: active === 'true' ? '#ffffff' : '#666666',
        transition: 'all 0.2s ease'
    }
}));

const NavSection = styled(List)({
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
});

const ToggleButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: -30,
    // top: '50%',
    top: '100%',
    transform: 'translateY(-50%)',
    width: 28,
    height: 28,
    // backgroundColor: '#ffffff',
    backgroundColor: '#f2f2f2',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    visibility: 'visible',
    zIndex: 10,
    zIndex: 9999,
    '&:hover': {
        // backgroundColor: '#f8f9ff'
        backgroundColor: '#f2f2f2'
    },
    '& svg': {
        fontSize: 14
    }
}));

const DownloadButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isOpen'
})(({ isOpen }) => ({
    minWidth: isOpen ? 'auto' : 42,
    width: isOpen ? '100%' : 42,
    height: 42,
    padding: isOpen ? '8px 16px' : 0,
    borderRadius: 12,
    color: '#1D1D1F',
    border: '1px solid #1D1D1F',
    transition: 'all 0.2s ease',
    justifyContent: isOpen ? 'flex-start' : 'center',
    gap: isOpen ? '12px' : 0,
    '&:hover': {
        backgroundColor: 'rgba(29, 29, 31, 0.04)',
        borderColor: '#1D1D1F',
    },
    '& svg': {
        fontSize: 20,
        opacity: 0.8, // deixa mais suave
        strokeWidth: 1 // tenta deixar mais fino
    },
    '& span': {
        display: isOpen ? 'block' : 'none',
        fontSize: 14,
        fontFamily: '"SF-Pro-Text-Regular", sans-serif',
        textTransform: 'capitalize'
    }
}));

export default function Menu() {
 
    const location = useLocation();
    // Inicializa o estado com o valor do localStorage ou false como padrão
    const [isOpen, setIsOpen] = useState(() => {
        const savedState = localStorage.getItem('menuIsOpen');
        return savedState ? JSON.parse(savedState) : false;
    });

    // Atualiza o localStorage sempre que o estado do menu mudar
    useEffect(() => {
        localStorage.setItem('menuIsOpen', JSON.stringify(isOpen));
    }, [isOpen]);

    const menuItems = [
        { path: '/', icon: faCompass, text: 'Início' },
        { path: '/users', icon: faUser, text: 'Usuários' },
        { path: '/register', icon: faAddressCard, text: 'Cadastrar' },
        { path: '/view', icon: faEye, text: 'Consulta' },
        { path: '/location', icon: faMap, text: 'Geolocalização' }
    ];

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <MenuWrapper isOpen={isOpen}>
            <MenuContent>
                <Header isOpen={isOpen}>
                    {isOpen && (
                        <Typography
                            variant="h6"
                            sx={{
                                fontFamily: '"SF-Pro-Display-Bold", sans-serif',
                                color: '#1D1D1F',
                                fontSize: 24
                            }}
                        >
                            TwoAlert
                        </Typography>
                    )}
                    <Link to="/">
                        <Logo src={logo} alt="logo" />
                    </Link>
                    <ToggleButton
                        onClick={toggleMenu}
                        size="small"
                        title={isOpen ? 'Fechar menu' : 'Abrir menu'}
                    >
                        <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
                    </ToggleButton>
                </Header>

                <NavSection>
                    {menuItems.map((item) => (
                        <ListItem key={item.path} disablePadding>
                            <Tooltip title={!isOpen ? item.text : ''} placement="right">
                                <StyledLink
                                    to={item.path}
                                    active={(location.pathname === item.path).toString()}
                                    isOpen={isOpen}
                                >
                                    <StyledListItemIcon active={(location.pathname === item.path).toString()}>
                                        <FontAwesomeIcon icon={item.icon} />
                                    </StyledListItemIcon>
                                    {isOpen && (
                                        <MenuItemText active={(location.pathname === item.path).toString()}>
                                            {item.text}
                                        </MenuItemText>
                                    )}
                                </StyledLink>
                            </Tooltip>
                        </ListItem>
                    ))}
                </NavSection>

                <Stack spacing={2} sx={{ mt: 'auto', alignItems: 'center' }}>
                    {isOpen && (
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontFamily: '"SF-Pro-Display", sans-serif',
                                color: '#1D1D1F',
                                fontWeight: 600,
                                width: '100%'
                            }}
                        >
                            Baixe o app
                        </Typography>
                    )}
                    <DownloadButton
                        component="a"
                        href="https://apps.apple.com/br/app/twoalert/id1661677988"
                        target="_blank"
                        rel="noopener"
                        title="App Store"
                        isOpen={isOpen}
                    >
                        <FontAwesomeIcon icon={faApple} />
                        <span>App Store</span>
                    </DownloadButton>
                    <DownloadButton
                        component="a"
                        href="https://play.google.com/store/apps/details?id=io.twoalert.com"
                        target="_blank"
                        rel="noopener"
                        title="Google Play"
                        isOpen={isOpen}
                    >
                        <FontAwesomeIcon icon={faGooglePlay} />
                        <span>Google Play</span>
                    </DownloadButton>
                </Stack>
            </MenuContent>
        </MenuWrapper>
    );
}