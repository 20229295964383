import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle, faCheckCircle, faInfoCircle, faExclamationTriangle, faBell } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

// Adiciona os ícones ao library para uso
library.add(faTimesCircle, faCheckCircle, faInfoCircle, faExclamationTriangle, faBell);

// Mantém referência à notificação de carregamento para reutilização
let loadingNotification = null;

export const addNotification = (message, type) => {
    const container = document.getElementById("notifications");
    if (!container) {
        console.error("Notification container not found!");
        return;
    }

    let notification;

    if (type === 'loading') {
        if (!loadingNotification) {
            loadingNotification = document.createElement("div");
            loadingNotification.classList.add('notification', 'loading');
            loadingNotification.innerHTML = `${getIcon(type)}<span>${message}</span>`;
            container.appendChild(loadingNotification);
        } else {
            // Se a notificação de carregamento já existe, apenas atualiza a mensagem
            updateLoadingMessage(message);
        }
        loadingNotification.classList.add('show');
    } else {
        notification = document.createElement("div");
        notification.classList.add('notification', type);
        const iconHtml = getIcon(type);
        const closeButton = '<button class="close-button">×</button>';
        notification.innerHTML = `<span class="icon">${iconHtml}</span>${message}<div class="progress-bar"></div>${closeButton}`;
        container.appendChild(notification);

        setTimeout(() => {
            notification.classList.add('show');
        }, 100);

        const closeBtn = notification.querySelector('.close-button');
        closeBtn.addEventListener('click', () => {
            notification.classList.remove('show');
            setTimeout(() => container.removeChild(notification), 300);
        });

        setTimeout(() => {
            if (document.body.contains(notification)) {
                notification.classList.remove('show');
                setTimeout(() => container.removeChild(notification), 300);
            }
        }, 8000);
    }
};

export const updateLoadingMessage = (newMessage) => {
    if (loadingNotification) {
        const span = loadingNotification.querySelector('span');
        if (span) {
            span.innerHTML = newMessage;
        }
    }
};

export const removeLoadingNotification = () => {
    if (loadingNotification && document.body.contains(loadingNotification)) {
        loadingNotification.classList.remove('show');
        setTimeout(() => {
            if (loadingNotification) {
                const container = document.getElementById("notifications");
                if (container) {
                    container.removeChild(loadingNotification);
                }
                loadingNotification = null; // Reset para permitir nova criação
            }
        }, 300);
    }
};

const getIcon = (type) => {
    const icons = {
        error: icon({ prefix: 'fas', iconName: 'times-circle' }).html,
        success: icon({ prefix: 'fas', iconName: 'check-circle' }).html,
        info: icon({ prefix: 'fas', iconName: 'info-circle' }).html,
        warning: icon({ prefix: 'fas', iconName: 'exclamation-triangle' }).html,
        normal: icon({ prefix: 'fas', iconName: 'bell' }).html,
        loading: '<div class="loadingNotification"></div>' // Ícone de carregamento personalizado
    };

    return icons[type]?.html || icons[type]; // Retorna o HTML do ícone ou o próprio HTML para 'loading'
};
