import React, { useEffect, useState, useRef, useContext } from "react"
import Menu from "../../components/menu"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faImage, faFileAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import CustomModal from "../../componentsTwoAlert/customModal"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import { UserContext } from "../../components/UserProvider"
import { VerificationContext } from "../config/modal/verification/VerificationProvider"
import Verification from "../config/modal/verification"
import UpdateAccount from "../config/modal/updateAccount"
import { nameValidator } from '../../helpers/nameValidator'
import { descriptionValidator } from '../../helpers/descriptionValidator'
import { ageValidator } from '../../helpers/ageValidator'
import { addNotification, removeLoadingNotification } from '../../components/notification'
import { useNavigate } from "react-router-dom"
import { paises } from "../config/countries"
import { registerUser } from "../../analysis/register"
import fetchSecrets from "../../config/fetchSecrets"
import {
    Button,
    Typography,
    Box,
    ThemeProvider,
    createTheme,
    alpha,
    TextField,
    IconButton,
    Modal
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDialogue } from "../config/modal/dialogue/useDialogue"
import { Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material';
import './style.css'

const theme = createTheme({
    palette: {
        primary: {
            main: '#2148ff',
            light: '#4B9BFF',
            dark: '#0047B3',
        },
        background: {
            default: '#F2F2F7',
            paper: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    },
});

const AppContainer = styled('div')({
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#F2F2F7',
});

const MainContent = styled('div')({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    // backgroundColor: '#FFFFFF'
});

const ScrollableContent = styled('div')({
    flex: 1,
    overflow: 'auto',
});

const FormCard = styled('div')({
    padding: '32px',
    maxWidth: '1000px',
    minHeight: 'calc(100vh - 85px)',
    margin: '0 auto',
    // backgroundColor: '#FFFFFF'
});

const InputRow = styled('div')({
    display: 'flex',
    gap: '24px',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
    },
});

const InputGroup = styled('div')(({ half }) => ({
    flex: half ? '0 0 calc(50% - 12px)' : 1,
    marginBottom: '15px',
}));

const InputLabel = styled(Typography)({
    fontSize: '15px',
    fontWeight: '600',
    color: '#1D1D1F',
    marginBottom: '8px',
});

const StyledInput = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        '& fieldset': {
            borderColor: '#E5E5EA',
        },
        '&:hover fieldset': {
            borderColor: '#2148ff',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#2148ff',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '15px',
        fontSize: '16px',
        color: '#000000',
    },
});

const SelectButton = styled('button')(({ hasValue }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    padding: '15px',
    border: '1px solid #E5E5EA',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
        borderColor: '#2148ff',
        backgroundColor: alpha('#2148ff', 0.04),
    },
    '& span': {
        fontSize: '16px',
        color: hasValue ? '#1D1D1F' : '#8E8E93',
    },
}));

const ImagePreviewGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(95px, 1fr))',
    gap: '16px',
    marginTop: '16px',
});

const ImagePreview = styled('div')({
    aspectRatio: '1',
    borderRadius: '12px',
    position: 'relative',
    backgroundColor: '#F5F5F7',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '12px',
    },
});

const StickyFooter = styled('div')({
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
    borderTop: '1px solid #E5E5EA',
    padding: '16px 24px',
    zIndex: 10,
});

const ActionButton = styled(Button)({
    borderRadius: '12px',
    padding: '12px 24px',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
    display: 'block',
    boxShadow: 'none',
    '&:hover': {
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
});

export default function Register() {

    const [blockPrint, setBlockPrint] = useState(false)
    const { initiateVerification } = useContext(VerificationContext);

    const { openDialogue, closeDialogue } = useDialogue();

    let navigate = useNavigate();

    window.addEventListener('keydown', function (e) {
        if (e.key === 'PrintScreen' || e.keyCode === 44 || e.keyCode === 16) {
            e.preventDefault();
            setBlockPrint(true)
        }
    });

    const { user, users, passwordHash, statusSubscription } = useContext(UserContext)

    const swiperRef = useRef(null);

    const [slideValue, setSlideValue] = useState(0)

    const [cpf, setCpf] = useState('');
    const [images, setImages] = useState([]);
    const [cadas, setCadas] = useState('');
    const [nome, setNome] = useState('');
    const [desc, setDesc] = useState('');
    const [idade, setIdade] = useState('');
    const [estadoCivil, setEstadoCivil] = useState('');
    const [selectedValueSG, setSelectedSG] = useState("");
    const [nacionalidade, setNacionalidade] = useState('')
    const [pdf, setPdf] = useState(null);

    const [pickerVisible, setPickerVisible] = useState(false);
    const [openEstadoCivil, setOpenEstadoCivil] = useState(false);
    const [openNacionalidade, setOpenNacionalidade] = useState(false);
    const [openCadas, setOpenCadas] = useState(false);

    const options = ["M e Cisgênero", "M e Transgênero", "M e Não Responder", "F e Cisgênero", "F e Transgênero", "F e Não Responder"];

    const OptionsEstadoCivil = ["Solteiro(a)", "Casado(a)", "Viúvo(a)", "Divorciado(a)", "Separado(a)", "Namorando"];

    const optionsParentesco = ["Filho", "Filha", "Marido", "Esposa", "Pai", "Mãe", "Irmão", "Irmã", "Tio", "Tia", "Avô", "Avó", "Primo", "Prima", "Sobrinho", "Sobrinha", "Padrastro", "Madrasta"];

    const isDisabled = estadoCivil === '' || selectedValueSG === '' || nome === '' || desc === '' || idade === '' || selectedValueSG === '';

    const isDisabled2 = cpf === '' || images.length === 0 || desc === '' || cadas === '' || nacionalidade === '';

    const [countries, setCountries] = useState([]);

    useEffect(() => {
        setCountries(paises);
    }, [])

    const inputRef = useRef();

    const selectImage = () => {
        if (images.length < 5) {
            inputRef.current.click();
        } else {
            alert('Você só pode enviar até 5 imagens.');
        }
    };

    const handleImageChange = (e) => {
        const selectedFiles = Array.from(e.target.files); // Converte a FileList em um Array
        const newImageUrls = selectedFiles.map(file => URL.createObjectURL(file));

        if (images.length + newImageUrls.length > 5) {
            alert('Você só pode enviar até 5 imagens.');
            return;
        }

        setImages(prevImages => [...prevImages, ...newImageUrls]);
    };

    const inputFileRef = useRef(null);

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            setPdf(fileUrl);
        }
    };

    const handleClick = () => {
        if (pdf) {
            setOpenShowPDF(true);
        } else {
            inputFileRef.current.click();
        }
    };

    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        if (!passwordHash) {
            openDialogue("PIN e obrigatório", "É preciso configurar o PIN para realizar ações no TwoAlert Web", [
                {
                    text: "Configurar",
                    onClick: () => {
                        closeDialogue();
                        navigate('/account')
                    }
                },
                {
                    text: "Entendi",
                    onClick: () => {
                        closeDialogue();
                    }
                },
            ]);
        }
    }, [])

    const sendData = async () => {

        if (statusSubscription !== "active" && statusSubscription !== "past_due") {
            addNotification("Uma assinatura ativa é necessária para prosseguir com esta operação.", "warning");
        }

        if (!user.emailVerified) {
            addNotification("Por favor, verifique seu e-mail para ativar todas as funcionalidades do TwoAlert.", 'warning');
            return;
        }

        if (!passwordHash) {
            addNotification("É preciso configurar o PIN para realizar ações no TwoAlert Web", 'warning');
            return;
        }

        if (!nome || !idade || !desc || !estadoCivil || !selectedValueSG ||
            !cpf || !nacionalidade || !cadas || images.length === 0 || !user.uid) {
            addNotification("Está faltando dados", 'warning');
            return;
        }

        await initiateVerification();

        setIsSending(true);
        addNotification("Processando dados...", "loading");

        const secrets = await fetchSecrets();
        const SITE_reCAPTCHA = secrets.SITE_reCAPTCHA;

        window.grecaptcha.ready(async () => {
            const token = await window.grecaptcha.execute(`${SITE_reCAPTCHA}`, { action: 'submit' });

            if (token) {
                try {
                    setIsSending(true);


                    await registerUser(
                        nome,
                        idade,
                        desc,
                        estadoCivil,
                        selectedValueSG,
                        cpf,
                        nacionalidade,
                        cadas,
                        user.uid,
                        images,
                        pdf,
                        token
                    );

                    removeLoadingNotification();
                    setIsSending(false);
                    addNotification(`O usuário ${nome} foi cadastrado com sucesso.`, 'success');
                    swiperRef.current.swiper.slidePrev()
                    setCpf('');
                    setImages([]);
                    setCadas('');
                    setNome('');
                    setDesc('');
                    setIdade('');
                    setEstadoCivil('');
                    setSelectedSG("");
                    setNacionalidade('')
                    setPdf(null);

                } catch (err) {
                    const errorMessage = err.message || `Não foi possível realizar o cadastro do usuário ${nome.value} no momento. Por favor, tente novamente mais tarde.`;
                    setIsSending(false);
                    removeLoadingNotification();
                    openDialogue("Erro ao Cadastrar", errorMessage, [
                        {
                            text: "Ok",
                            onClick: () => {
                                closeDialogue();
                            }
                        },
                    ]);
                    // addNotification(errorMessage, "error");
                }
            }
            else {
                setIsSending(false);
                removeLoadingNotification();
                addNotification('Desculpe algo deu errado, tente novamente.', "error");
            }
        })

    };

    const nomeError = nameValidator(nome)
    const idadeError = ageValidator(idade)
    const descError = descriptionValidator(desc)

    function onPress() {
        let hasError = false;

        if (nomeError) {
            addNotification(nomeError, 'warning');
            hasError = true;
        }

        if (descError) {
            addNotification(descError, 'warning');
            hasError = true;
        }

        if (idadeError) {
            addNotification(idadeError, 'warning');
            hasError = true;
        }

        if (!hasError) {
            swiperRef.current.swiper.slideNext();
            setSlideValue(1)
        }
    }

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (value.match(/^\d*$/)) {
            setCpf(value);
        }
    };

    const handleIdadeChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos

        // Adiciona a primeira barra após o dia (2 dígitos)
        if (value.length > 2 && value[2] !== '/') {
            value = value.slice(0, 2) + '/' + value.slice(2);
        }

        // Adiciona a segunda barra após o mês (2 dígitos após a primeira barra)
        if (value.length > 5 && value[5] !== '/') {
            value = value.slice(0, 5) + '/' + value.slice(5);
        }

        // Limita a entrada para no máximo 10 caracteres (dd/mm/aaaa)
        if (value.length > 10) {
            value = value.slice(0, 10);
        }

        setIdade(value);
    };

    const prev = () => {
        swiperRef.current.swiper.slidePrev()
        setSlideValue(0)
    }

    const [showUpdateModal, setShowUpdateModal] = useState(false)

    const [openShowPDF, setOpenShowPDF] = useState(false);

    const handleImageDelete = (indexToDelete) => {
        setImages(prevImages => prevImages.filter((_, index) => index !== indexToDelete));
    };

    const deletePDF = () => {
        setPdf(null);
        setOpenShowPDF(false);
    };

    const selectOneImage = () => {
        inputFileRef.current.click();
        setOpenShowPDF(false);
    };


    return (
        <ThemeProvider theme={theme}>
            <AppContainer>
                <Menu />
                <MainContent>
                    <Verification />
                    <UpdateAccount showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} />

                    {blockPrint && (
                        <Box
                            position="fixed"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            bgcolor="rgba(0,0,0,0.9)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            zIndex={9999}
                        >
                            <Box textAlign="center" color="white" p={4}>
                                <Typography variant="h4" mb={3}>PrintScreen não permitido!</Typography>
                                <ActionButton onClick={() => setBlockPrint(false)}>
                                    Fechar
                                </ActionButton>
                            </Box>
                        </Box>
                    )}

                    <div id="notifications" />

                    {!users.length < 5 ? (
                        <>
                            <ScrollableContent>
                                <Swiper
                                    ref={swiperRef}
                                    pagination={{
                                        type: 'bullets',
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    simulateTouch={false}
                                >
                                    <SwiperSlide>
                                        <FormCard>
                                            <Typography variant="h4" gutterBottom fontWeight="600">
                                                Informações Pessoais
                                            </Typography>
                                            <Typography color="text.secondary" mb={4}>
                                                Insira as informações básicas do usuário que você está cadastrando.
                                            </Typography>

                                            <InputRow>
                                                <InputGroup half>
                                                    <InputLabel>Nome do usuário</InputLabel>
                                                    <StyledInput
                                                        fullWidth
                                                        placeholder="Nome completo"
                                                        value={nome}
                                                        onChange={(e) => setNome(e.target.value)}
                                                        disabled={!passwordHash}
                                                    />
                                                </InputGroup>

                                                <InputGroup half>
                                                    <InputLabel>Data de nascimento</InputLabel>
                                                    <StyledInput
                                                        fullWidth
                                                        placeholder="DD/MM/AAAA"
                                                        value={idade}
                                                        onChange={handleIdadeChange}
                                                        disabled={!passwordHash}
                                                    />
                                                </InputGroup>
                                            </InputRow>

                                            <InputGroup>
                                                <InputLabel>Sobre o usuário</InputLabel>
                                                <StyledInput
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    placeholder="Fale algo sobre o usuário"
                                                    value={desc}
                                                    onChange={(e) => setDesc(e.target.value)}
                                                    disabled={!passwordHash}
                                                />
                                            </InputGroup>

                                            <InputRow>
                                                <InputGroup half>
                                                    <InputLabel>Sexo e Gênero</InputLabel>
                                                    <SelectButton
                                                        hasValue={selectedValueSG}
                                                        onClick={() => setPickerVisible(true)}
                                                        disabled={!passwordHash}
                                                    >
                                                        <span>{selectedValueSG || "Selecione"}</span>
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ color: '#233DDF' }} />
                                                    </SelectButton>
                                                </InputGroup>

                                                <InputGroup half>
                                                    <InputLabel>Estado Civil</InputLabel>
                                                    <SelectButton
                                                        hasValue={estadoCivil}
                                                        onClick={() => setOpenEstadoCivil(true)}
                                                        disabled={!passwordHash}
                                                    >
                                                        <span>{estadoCivil || "Selecione"}</span>
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ color: '#233DDF' }} />
                                                    </SelectButton>
                                                </InputGroup>
                                            </InputRow>
                                        </FormCard>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <FormCard>
                                            <Box display="flex" alignItems="center" sx={{ width: '100%', position: 'relative' }} mb={4}>
                                                <Button
                                                    startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                                                    onClick={() => prev()}
                                                    sx={{ color: '#2148ff', position: 'absolute' }}
                                                >
                                                    Voltar
                                                </Button>
                                                {/* <Typography variant="h4" fontWeight="600" sx={{ margin: 'auto' }}>
                                                    Documentação
                                                </Typography> */}
                                            </Box>

                                            <InputRow>
                                                <InputGroup half>
                                                    <InputLabel>CPF do usuário</InputLabel>
                                                    <StyledInput
                                                        fullWidth
                                                        placeholder="Digite o CPF"
                                                        value={cpf}
                                                        onChange={handleInputChange}
                                                        disabled={!passwordHash}
                                                        inputProps={{ maxLength: 11 }}
                                                    />
                                                </InputGroup>

                                                <InputGroup half>
                                                    <InputLabel>País de origem</InputLabel>
                                                    <SelectButton
                                                        hasValue={nacionalidade}
                                                        onClick={() => setOpenNacionalidade(true)}
                                                        disabled={!passwordHash}
                                                    >
                                                        <span>{nacionalidade || "Selecione"}</span>
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ color: '#233DDF' }} />
                                                    </SelectButton>
                                                </InputGroup>
                                            </InputRow>

                                            <InputGroup>
                                                <InputLabel>Fotos do usuário</InputLabel>
                                                <input
                                                    type="file"
                                                    ref={inputRef}
                                                    multiple
                                                    onChange={handleImageChange}
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                />
                                                <SelectButton
                                                    onClick={selectImage}
                                                    disabled={!passwordHash}
                                                    style={{
                                                        borderStyle: 'dashed',
                                                        height: images.length ? 'auto' : '95px',
                                                        flexDirection: 'column',
                                                        gap: '8px'
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faImage} size="2x" style={{ color: '#2148ff' }} />
                                                    <span>{images.length ? `${images.length} foto(s) selecionada(s)` : 'Adicionar fotos'}</span>
                                                </SelectButton>

                                                {images.length > 0 && (
                                                    <ImagePreviewGrid>
                                                        {images.map((image, index) => (
                                                            <ImagePreview key={index} className="image-preview">
                                                                <img src={image} alt={`Preview ${index + 1}`} />
                                                                <div className="delete-button" onClick={() => handleImageDelete(index)}>
                                                                    <CloseIcon style={{ fontSize: 12 }} />
                                                                </div>
                                                            </ImagePreview>
                                                        ))}
                                                    </ImagePreviewGrid>
                                                )}
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLabel>Documentos (Opcional)</InputLabel>
                                                <input
                                                    type="file"
                                                    ref={inputFileRef}
                                                    onChange={handleFileChange}
                                                    style={{ display: 'none' }}
                                                    accept="application/image"
                                                />
                                                <SelectButton
                                                    onClick={handleClick}
                                                    disabled={!passwordHash}
                                                    style={{ borderStyle: 'dashed' }}
                                                >
                                                    <span style={{ color: pdf ? '#233DDF' : 'initial' }}>
                                                        {pdf ? 'Documento carregado' : 'Adicionar documentos'}
                                                    </span>
                                                    <FontAwesomeIcon icon={faFileAlt} style={{ color: '#233DDF' }} />
                                                </SelectButton>
                                            </InputGroup>

                                            <InputGroup>
                                                <InputLabel>Quem está cadastrando</InputLabel>
                                                <SelectButton
                                                    hasValue={cadas}
                                                    onClick={() => setOpenCadas(true)}
                                                    disabled={!passwordHash}
                                                >
                                                    <span>{cadas || "Selecione o parentesco"}</span>
                                                    <FontAwesomeIcon icon={faChevronDown} style={{ color: '#233DDF' }} />
                                                </SelectButton>
                                            </InputGroup>
                                        </FormCard>
                                    </SwiperSlide>
                                </Swiper>
                            </ScrollableContent>


                            <StickyFooter>
                                {statusSubscription !== "active" &&
                                    statusSubscription !== 'past_due' &&
                                    statusSubscription !== 'trialing' ? (
                                    <ActionButton
                                        variant="contained"
                                        onClick={() => navigate('/account/subscription')}
                                    >
                                        Assinar TwoAlert
                                    </ActionButton>
                                ) : (
                                    <>
                                        {slideValue === 0 ? (
                                            <ActionButton
                                                variant="contained"
                                                disabled={!passwordHash || isDisabled}
                                                onClick={onPress}
                                            >
                                                Próximo
                                            </ActionButton>
                                        ) : (
                                            <ActionButton
                                                variant="contained"
                                                disabled={!passwordHash || isDisabled2 || isSending}
                                                onClick={sendData}
                                            >
                                                Finalizar cadastro
                                            </ActionButton>
                                        )}
                                    </>
                                )}
                            </StickyFooter>
                        </>
                    ) : (
                        <ScrollableContent>
                            <FormCard>
                                <Box textAlign="center" py={8}>
                                    <Typography variant="h4" gutterBottom fontWeight="600">
                                        Limite de Cadastros Atingido
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        A fim de garantir uma gestão eficaz, cada conta tem um limite de cadastro de até 5 familiares.
                                    </Typography>
                                </Box>
                            </FormCard>
                        </ScrollableContent>
                    )}

                    <CustomModal
                        visible={pickerVisible}
                        setVisible={setPickerVisible}
                        options={options}
                        onSelectOption={setSelectedSG}
                        textTitleModal={'Sexo e Gênero'}
                    />
                    <CustomModal
                        visible={openEstadoCivil}
                        setVisible={setOpenEstadoCivil}
                        options={OptionsEstadoCivil}
                        onSelectOption={setEstadoCivil}
                        textTitleModal={'Estado Civil'}
                    />
                    <CustomModal
                        visible={openNacionalidade}
                        setVisible={setOpenNacionalidade}
                        options={countries}
                        onSelectOption={setNacionalidade}
                        textTitleModal={'País de origem'}
                        counties={true}
                    />
                    <CustomModal
                        visible={openCadas}
                        setVisible={setOpenCadas}
                        options={optionsParentesco}
                        onSelectOption={setCadas}
                        textTitleModal={'Quem está cadastrando'}
                    />


                    <Modal
                        open={openShowPDF}
                        onClose={() => setOpenShowPDF(false)}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 9999
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                bgcolor: '#000',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    p: 2
                                }}
                            >
                                <IconButton
                                    onClick={() => setOpenShowPDF(false)}
                                    sx={{
                                        bgcolor: 'rgba(255,255,255,0.2)',
                                        '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                                    }}
                                >
                                    <CloseIcon sx={{ color: '#FFF' }} />
                                </IconButton>
                                <IconButton
                                    onClick={deletePDF}
                                    sx={{
                                        bgcolor: 'rgba(255,255,255,0.2)',
                                        '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                                    }}
                                >
                                    <DeleteIcon sx={{ color: '#FFF' }} />
                                </IconButton>
                            </Box>

                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: 2
                                }}
                            >
                                <img
                                    src={pdf}
                                    alt=""
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '60vh',
                                        borderRadius: 12
                                    }}
                                />
                            </Box>

                            <Box sx={{ p: 2, textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    onClick={selectOneImage}
                                    sx={{
                                        bgcolor: '#FFF',
                                        color: '#000',
                                        borderRadius: 3,
                                        p: 2,
                                        width: '100%',
                                        '&:hover': {
                                            bgcolor: '#F2F2F7'
                                        }
                                    }}
                                >
                                    Escolher outro documento
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </MainContent>
            </AppContainer>
        </ThemeProvider>
    );
}