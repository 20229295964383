import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function UpdateAccount({ showUpdateModal, setShowUpdateModal }) {

    let navigate = useNavigate();

    function subscription() {
        setShowUpdateModal(false)
        navigate("/account/subscription")
    }

    return (
        <>
            {showUpdateModal && (
                <div className='allUpdateAccount'>
                    <div className='closeArea' onClick={() => setShowUpdateModal(false)}></div>
                    <div className="modal">
                        <div className='close'>
                            <FontAwesomeIcon icon={faX} onClick={() => setShowUpdateModal(false)} />
                        </div>
                        <form className="form" onSubmit={(event) => { event.preventDefault(); console.log('Formulário não será enviado');}}>
                            <label className="titleUpdate">TwoAlert <br /> Assinatura Plus</label>
                            <p className="description">Tenha mais segurança na internet ao conversar com um estranho e sinta-se à vontade ao compartilhar a sua localização em tempo real.</p>
                            <div className="tab-container">
                                <button className="tab tab--1">Mensal</button>
                                <button className="tab tab--2">Anual</button>
                                <div className="indicator"></div>
                            </div>

                            <div className="benefits">
                                <span>O que oferecemos:</span>
                                <ul>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" height="16" width="16">
                                            <rect fill="black" rx="8" height="16" width="16"></rect>
                                            <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" stroke="white" d="M5 8.5L7.5 10.5L11 6"></path>
                                        </svg>
                                        <span>Segurança virtual ao conhecer um estranho.</span>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" height="16" width="16">
                                            <rect fill="black" rx="8" height="16" width="16"></rect>
                                            <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" stroke="white" d="M5 8.5L7.5 10.5L11 6"></path>
                                        </svg>
                                        <span>Localização em tempo real. Compartilhe a sua localização com alguém da sua total confiança.</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="modal--footer">
                                <label className="price"><sup>R$</sup>9,99<sub>/mês</sub></label>
                                <button className="upgrade-btn" onClick={() => subscription()}>Atualizar assinatura</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}
