import React from 'react';

const PercentageCircle = ({ percentage, size = 100, isPercentage }) => {
    const radius = size / 2 - 10;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    const fontSize = size / 5;

    return (
        <svg height={size} width={size} viewBox={`0 0 ${size} ${size}`}>
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke="#E0E0E0"
                strokeWidth="10"
            />
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke={percentage >= 50 ? "#63ff63" : "#ff3d5a"}
                strokeWidth="10"
                strokeLinecap="round"
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset}
                transform={`rotate(-90 ${size / 2} ${size / 2})`}
            />
            <text
                x="50%"
                y="45%"
                textAnchor="middle"
                fill={percentage >= 50 ? "#189218" : "#ff3d5a"}
                fontSize={fontSize}
                dy=".3em"
                fontWeight="bold"
                dominantBaseline="middle"
                fontFamily= "SF-Pro-Display"
            >
                {isPercentage ? `${percentage}%` : `${percentage}`}
            </text>
        </svg>
    );
};

export default PercentageCircle;
