import React, { useState, useContext } from 'react'
import { auth } from '../../../../config/firebase';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { addNotification } from '../../../../components/notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../../../components/UserProvider';

export default function Password() {

    const [password, setpassword] = useState({ value: '', error: '' })
    const [newPassword, setNewPassword] = useState({ value: '', error: '' })

    const { user } = useContext(UserContext)


    const reauthenticate = (currentPassword) => {
        var user = auth.currentUser;
        var cred = EmailAuthProvider.credential(user.email, currentPassword);
        return reauthenticateWithCredential(user, cred);
    }

    const changePassword = (currentPassword, newPassword) => {

        if (!user.emailVerified) {
            addNotification("Por favor, verifique seu e-mail para ativar todas as funcionalidades do TwoAlert.", 'warning');
            return;
        }

        if (!currentPassword.value || !newPassword.value) {
            addNotification('Todos os campos devem estar preenchidos', 'error');
            return;
        }

        // if (newPassword.value !== retypePassword.value) {
        //     Alert.alert('Erro', 'As senhas não coincidem');
        //     return;
        // }

        reauthenticate(currentPassword.value).then(() => {
            var user = auth.currentUser;
            updatePassword(user, newPassword.value).then(() => {
                addNotification('Senha atualizada com sucesso', 'success');
            }).catch((error) => {
                if (error.code === 'auth/weak-password') {
                    addNotification('A senha é muito fraca. Por favor, escolha uma senha mais forte', 'error');
                } else if (error.code === 'auth/too-many-requests') {
                    addNotification('Muitas tentativas falhas de login. Tente novamente mais tarde', 'error');
                } else {
                    addNotification('Erro ao atualizar a senha: ' + error.message, 'error');
                }
            });            
        }).catch((error) => {
            if (error.code === 'auth/wrong-password') {
                addNotification('Senha atual incorreta', 'error');
            } else if (error.code === 'auth/too-many-requests') {
                addNotification('Muitas tentativas falhas de login. Tente novamente mais tarde', 'error');
            } else {
                // addNotification('Erro ao reautenticar: ' + error.message, 'error');
                addNotification('Desculpe! Algo deu errado.', 'error');
            }
        });
    }

    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)

    return (
        <div className='allProfile'>
            <div id="notifications"></div>
            <div className='contentInformation'>
                <h2>Alterar senha</h2>
                <span>Altere a sua senha do app TwoAlert</span>
            </div>
            <div className='contentAddInformation'>
                <div className='addInformation'>
                    <div className='contentInputs'>
                        <div className='contentInput'>
                            <input type={showPassword ? 'text' : 'password'} class="input" required value={password.value} onChange={(e) => setpassword({ ...password, value: e.target.value })} maxLength={10} minLength={6} />
                            <label class="label">Senha atual</label>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} className='iconEye' />
                        </div>
                        <div className='contentInput'>
                            <input type={showNewPassword ? 'text' : 'password'} class="input" required value={newPassword.value} onChange={(e) => setNewPassword({ ...newPassword, value: e.target.value })} maxLength={10} minLength={6} />
                            <label class="label">Nova senha</label>
                            <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} onClick={() => setShowNewPassword(!showNewPassword)} className='iconEye' />
                        </div>
                    </div>
                </div>
                <button id='sendInformations' style={{ marginTop: 0 }} onClick={() => changePassword(password, newPassword)}>Salvar informações</button>
            </div>
        </div>
    )
}