import { signOut } from "firebase/auth";
import { db } from "../../config/firebase";
import { auth } from "../../config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const getUid = () => {
    return auth.currentUser?.uid;
}

const logOut = async () => {
    await signOut(auth);
}

// Assuming setIsLoginComplete is passed as an argument to the function
export const SignOut = async (setIsLoginComplete, setLoadingLogOut) => {
    setIsLoginComplete(false);
    setLoadingLogOut(true)

    const uid = getUid();

    if (!uid) {
        console.error("Nenhum usuário está logado.");
        setIsLoginComplete(true);
        return;
    }

    const localUniqueID = localStorage.getItem('uniqueID');

    try {
        const userDoc = doc(db, "users", uid);
        const userDocSnapshot = await getDoc(userDoc);

        if (!userDocSnapshot.exists()) {
            setIsLoginComplete(true);
            logOut();
            return;
        }

        const userDocData = userDocSnapshot.data();

        // Verificar se o array sessionsDevice está vazio
        if (userDocData?.sessionsDevice && userDocData?.sessionsDevice?.length === 0) {
            setIsLoginComplete(true);
            logOut();
            return;
        }

        if (!userDocData?.sessionsDevice) {
            setIsLoginComplete(true);
            logOut();
            return;
        }

        // Em seguida, encontramos o índice do objeto da sessão que queremos remover
        const updatedSessions = userDocData.sessionsDevice.filter(session => session.uniqueID !== localUniqueID);

        await updateDoc(userDoc, {
            sessionsDevice: updatedSessions
        });

        setTimeout(() => {
            setLoadingLogOut(false)
        }, 1000)
        setTimeout(() => {
            setIsLoginComplete(true)
        }, 2000)

    } catch (e) {
        console.error("Error removing document: ", e);
        setIsLoginComplete(true);
    }
};

