// export function ageValidator(idade) {
//     if (!idade) return "O campo idade não pode estar vazio."
//     if (idade < 7) return "O usuário deve ter pelo ou menos 7 anos de idade"
//     return ''
// }

export function ageValidator(idade) {
    if (!idade) return "O campo idade não pode estar vazio.";

    // Verifica se o formato da data é válido (dd/mm/aaaa)
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(idade)) return "Formato de data inválido.";

    // Extrai dia, mês e ano da string de idade
    const [dia, mes, ano] = idade.split("/").map(Number);


    // Verifica se o ano é maior que o ano atual
    const anoAtual = new Date().getFullYear();
    if (ano > anoAtual) return "Ano de nascimento não pode ser maior que o ano atual.";

    // Verifica se a data é válida
    const dataNascimento = new Date(ano, mes - 1, dia);
    if (!(dataNascimento.getDate() === dia && dataNascimento.getMonth() === mes - 1 && dataNascimento.getFullYear() === ano)) {
        return "Data de nascimento inválida.";
    }

    // Calcula a idade
    let idadeAtual = anoAtual - ano;
    const m = new Date().getMonth() - mes;
    if (m < 0 || (m === 0 && new Date().getDate() < dia)) {
        idadeAtual--;
    }

    // Verifica se a idade é menor que 7 anos
    if (idadeAtual < 7) return "O usuário para ser cadastrado pelos responsáveis deve ter no mínimo 7 anos de idade.";

    return '';
}
