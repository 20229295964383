import './style.css'
import React, { useState, useEffect, useRef } from 'react'
import { auth } from '../../../../config/firebase';
import { db } from '../../../../config/firebase';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import UserAvatar from '../../../../components/userTwoAlert';
import { storage } from '../../../../config/firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { addNotification, removeLoadingNotification } from '../../../../components/notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTrash } from '@fortawesome/free-solid-svg-icons';
import { sendVerifyEmail } from '../../../../functions/sendVerifyEmail';
import { checkImage } from '../../../../components/checkImage';

export default function Profile({ setWidth }) {

    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });

        return unsubscribe;
    }, []);

    const [newName, setNewName] = useState('')
    const [newPhoto, setNewPhoto] = useState(null)

    useEffect(() => {
        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const unsubscribe = onSnapshot(userRef, (doc) => {
                const userData = doc.data();
                setNewPhoto(userData?.photo || '');
                setNewName(userData?.nome || '');
            });

            return unsubscribe; // Isso é para "limpar" a assinatura ao desmontar
        }
    }, [user]);

    const userName = user?.displayName;
    const email = user?.email;

    const displayName = newName ? newName : (userName ? userName : email?.split('@')[0]);

    const [nome, setNome] = useState({ value: '', error: '' })
    const [nome2, setNome2] = useState({ value: '', error: '' })
    const [photo, setPhoto] = useState('')

    const photoURL = photo ? photo : (newPhoto ? newPhoto : user?.photoURL);

    const fileInputRef = useRef(null);

    const changePhoto = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setPhoto(e.target.result);
            }
            reader.readAsDataURL(file);
        }
    };

    const uriToBlob = async (uri) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        return blob;
    }

    const uploadImage = async (uri, uid) => {

        const blob = await uriToBlob(uri);

        const storageRef = ref(storage, `userInfo/${uid}/userImage`);

        const uploadTask = uploadBytesResumable(storageRef, blob);

        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log('Upload is ' + progress + '% done');
                    setWidth(progress)
                },
                (error) => {
                    // console.error('Upload failed:', error);
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    // console.log('File available at', downloadURL);
                    resolve(downloadURL);
                    addNotification("Imagem atualizada com sucesso", "success");
                }
            );
        });
    };

    const saveAll = async () => {
        if (!user.emailVerified) {
            addNotification("Por favor, verifique seu e-mail para ativar todas as funcionalidades do TwoAlert.", 'warning');
            return;
        }

        if (photo) {
            addNotification("Analisando imagem...", "loading");

            try {
                const result = await checkImage(photo);
                if (!result.result) {
                    removeLoadingNotification();
                    addNotification("Imagem inapropriada. Por favor, escolha outra imagem.", "error");
                    return;
                }
                removeLoadingNotification();
            } catch (error) {
                console.error('Erro ao verificar a imagem:', error);
                removeLoadingNotification();
                let userMessage = "Erro ao verificar a imagem"; 
                if (error.message.includes("InvalidImageFormatException")) {
                    userMessage = "Formato de imagem inválido. Por favor, utilize formatos comuns como JPG, PNG ou GIF.";
                } else if (error.message.includes("Image too large")) { // Supondo que "Image too large" seja parte da mensagem de erro para imagens grandes
                    userMessage = "A imagem é muito grande. Por favor, tente uma imagem menor que 5MB.";
                }

                addNotification(userMessage, "error");
                return;
            }
        }

        const userDoc = doc(db, 'users', user.uid);

        const userData = {
            nome: `${nome.value} ${nome2.value}`.trim() || displayName,
        };

        if (photo) {
            try {
                const downloadURL = await uploadImage(photo, user.uid);
                userData.photo = downloadURL;
            } catch (error) {
                addNotification("Erro ao salvar informações", "error");
            }
        }

        await setDoc(userDoc, userData, { merge: true });
        addNotification("Informações salvas com sucesso", "success");

    };

    const removePhoto = async () => {

        try {
            // Iniciando o Firebase Storage
            const storage = getStorage();

            // Criando a referência para a imagem
            const imageRef = ref(storage, `userInfo/${user.uid}/userImage`);

            // Removendo do Firebase Storage
            await deleteObject(imageRef);

            // Atualizando o documento do usuário no Firestore para remover o URL da imagem
            const userDocRef = doc(db, 'users', user.uid);
            await setDoc(userDocRef, { photo: "" }, { merge: true });

            // Limpar o estado local
            setPhoto('');
            setNewPhoto(null);
            addNotification("Imagem removida com sucesso", "success");
        } catch (error) {
            // console.log("Erro ao remover a foto do usuário: ", error);
            addNotification("Erro ao remover imagem", "error");
        }
    };



    return (
        <div className='allProfile'>
            <div id="notifications"></div>
            <div className='contentInformation'>
                <h2>Informações do perfil</h2>
                <span>Adicione ou modifique suas informações</span>
            </div>
            <div className='contentAddInformation'>
                <div className='addInformation'>
                    <div className='contentInputs'>
                        <div className='contentInput'>
                            <input type="text" class="input" required value={nome.value} onChange={(e) => setNome({ ...nome, value: e.target.value })} maxLength={10} minLength={3} />
                            <label class="label">Primeiro Nome</label>
                        </div>
                        <div className='contentInput'>
                            <input type="text" class="input" required value={nome2.value} onChange={(e) => setNome2({ ...nome2, value: e.target.value })} maxLength={10} minLength={3} />
                            <label class="label">Último Nome</label>
                        </div>
                    </div>
                    <div className='contentInput'>
                        <input type="text" class="input" required value={email} />
                        <label class="label">E-mail</label>
                        {!user?.emailVerified && (
                            <button onClick={() => sendVerifyEmail()}>Verificar e-mail</button>
                        )}
                    </div>
                </div>
                <div className='contentAddInformationImage'>
                    <div className='containerProfile'>
                        <div className='contentProfile'>
                            <h3>Imagem de perfil</h3>
                            <span>Sua imagem poderá ser vista apenas pela pessoa com quem você compartilhou sua localização em tempo real.</span>
                        </div>
                        <div className='contentImafeProfile'>
                            {user && <UserAvatar userName={displayName} email={email} photoURL={photoURL} size={60} />}
                        </div>
                    </div>
                    <div className='containerButtonsInformation'>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={changePhoto}
                        />
                        <button onClick={() => fileInputRef.current.click()}><FontAwesomeIcon icon={faImage} /> Adicionar imagem</button>
                        {newPhoto && (
                            <button onClick={removePhoto}><FontAwesomeIcon icon={faTrash} /> Deletar</button>
                        )}
                    </div>
                </div>
                <button id='sendInformations' onClick={saveAll}>Salvar informações</button>
            </div>
        </div>
    )
}