// Dialogue.js
import React from 'react';
import './style.css';

export default function Dialogue({ title, message, buttons, onClose }) {

    const paragraphs = message.split('\n').filter(p => p);

    return (
        <div className='allVerification' onClick={onClose}>
            <div className='dialogueBox' onClick={e => e.stopPropagation()}>
                <div className='paddingDialogueBox'>
                    <h2>{title}</h2>
                </div>
                <div className="contentDialogueBox">
                    {paragraphs.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
                <div className='paddingDialogueBox'>
                    <div className='buttonDialogueBox'>
                        {buttons.map((button, index) => (
                            <button key={index} onClick={button.onClick}>{button.text}</button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
