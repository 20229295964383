import React, { useEffect, useState } from "react"
import './stlye.css'
import Menu from "../../components/menu"
import { doc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { getStorage, ref } from "firebase/storage";
import { deleteObject } from "firebase/storage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faImage, faUserCircle, faFolder, faX, faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ChangeData } from "../../componentsTwoAlert/changeData";
import { ChangeDataInfo } from "../../componentsTwoAlert/changeDataInfo";

import { useContext } from 'react';
import { UserContext } from '../../components/UserProvider';
import Verification from "../config/modal/verification";
import { VerificationContext } from '../../app/config/modal/verification/VerificationProvider';
import { useDialogue } from "../config/modal/dialogue/useDialogue";
import { addNotification, removeLoadingNotification } from "../../components/notification";
import LoadingComponent from "../../components/LoadingComponent";
import UpdateAccount from "../config/modal/updateAccount";
import { updateUser } from "../../analysis/update";
import fetchSecrets from "../../config/fetchSecrets";

export default function Users() {

    const [images, setImages] = useState([]);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [pdf, setPdf] = useState(null);
    const [pdfToDelete, setPdfToDelete] = useState(null);
    const [openShowPDF, setOpenShowPDF] = useState(false)

    const { openDialogue, closeDialogue } = useDialogue();

    const handleSaveImages = async () => {
        addNotification("Enviando atualizações...", "loading");

        // Estado original das imagens e do PDF para reversão se necessário
        const originalImages = [...images];
        const originalPdf = pdf;

        // Filtrar URLs que NÃO são do Firebase Storage para envio de novas imagens
        const validImages = images.filter(url => !url.includes('storage.googleapis.com'));

        // Filtrar URLs que SÃO do Firebase Storage para deletar
        const validImagesToDelete = imagesToDelete.filter(url => url.includes('storage.googleapis.com'));

        // Filtrar o PDF atual se não for do Firebase Storage (novo PDF a ser enviado)
        const validPdf = pdf && !pdf.includes('storage.googleapis.com') ? pdf : null;

        // Filtrar o PDF a ser deletado se for do Firebase Storage
        const validPdfToDelete = pdfToDelete && pdfToDelete.includes('storage.googleapis.com') ? pdfToDelete : null;

        // Verificar se há alterações válidas para enviar
        if (validImages.length === 0 && validImagesToDelete.length === 0 && !validPdf && !validPdfToDelete) {
            addNotification("Nenhuma alteração detectada para enviar.", 'error');
            removeLoadingNotification();
            return;
        }

        const secrets = await fetchSecrets();
        const SITE_reCAPTCHA = secrets.SITE_reCAPTCHA;

        window.grecaptcha.ready(async () => {
            const token = await window.grecaptcha.execute(`${SITE_reCAPTCHA}`, { action: 'submit' });

            if (token) {
                // Envio dos dados atualizados, imagens e PDF
                try {
                    const message = await updateUser(
                        registerId,
                        user.uid,
                        null,
                        token,
                        validImages,
                        validImagesToDelete,
                        validPdf,
                        validPdfToDelete,
                    );
                    addNotification(message, 'success');
                    removeLoadingNotification();
                    setImagesToDelete([])
                    setPdfToDelete(null)
                } catch (error) {

                    // Reverter as mudanças nos estados se o envio falhar
                    // Evitar duplicação usando um novo conjunto para rastrear as imagens
                    const newImageSet = new Set([...originalImages]);

                    // Restaurar imagens deletadas se forem do Firebase Storage
                    validImagesToDelete.forEach(image => {
                        if (!newImageSet.has(image)) {
                            newImageSet.add(image);
                        }
                    });

                    // Remover qualquer nova imagem do Firebase que possa ter sido adicionada, se necessário
                    const finalImages = Array.from(newImageSet).filter(image => !validImages.includes(image));

                    // Atualizar o estado das imagens com a lista final
                    setImages(finalImages);

                    // Gerenciar o estado do PDF similarmente, se necessário
                    if (validPdf && originalPdf.includes('storage.googleapis.com')) {
                        setPdf(originalPdf);
                    }

                    setImagesToDelete([])

                    openDialogue("Erro ao Atualizar", error.message, [
                        {
                            text: "Ok",
                            onClick: () => {
                                closeDialogue();
                            }
                        },
                    ]);

                    // addNotification(error.message, 'error');
                    removeLoadingNotification();
                }
            }
        })
    };




























    const [blockPrint, setBlockPrint] = useState(false)
    window.addEventListener('keydown', function (e) {
        if (e.key === 'PrintScreen' || e.keyCode === 44 || e.keyCode === 16) {
            e.preventDefault();
            setBlockPrint(true)
        }
    });

    const { user, users, passwordHash, statusSubscription } = useContext(UserContext)
    const { initiateVerification } = useContext(VerificationContext);

    const [registerId, setRegisterId] = useState('')

    const [openAlter, setOpenAlter] = useState(false)

    const [showChangeData, setShowChangeData] = useState(0)
    const [showWitchChangeData, setShowWitchChangeData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)

    const onButtonPress = async (userId, code) => {
        if (statusSubscription !== "active" && statusSubscription !== "past_due") {
            addNotification("Uma assinatura ativa é necessária para prosseguir com esta operação.", "warning");
            setShowUpdateModal(true)
        }

        if (!passwordHash) {
            addNotification('É preciso configurar o PIN para realizar ações no TwoAlert Web', 'warning');
            return;
        }

        if (code === 'Gerar código') {
            onGenerateCode(userId);
        } else {
            try {
                await navigator.clipboard.writeText(code);
                addNotification('Código copiado para a área de transferência!', 'info');
            } catch (err) {
                console.error('Falha ao copiar o código:', err);
                addNotification('Erro ao copiar o código.', 'info');
            }
        }
    };

    const generateCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 8; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const onGenerateCode = async (userId) => {
        try {
            await initiateVerification();

            const code = generateCode();
            const userRef = doc(db, 'dataUsersRegister', userId);
            await updateDoc(userRef, { code: code });
        } catch {
            addNotification('Desculpe, não foi possivel gerar o código, tente novamente mais tarde.', 'error');
        }
    };

    const onPressedAlter = async (registrationId) => {
        if (statusSubscription !== "active" && statusSubscription !== "past_due") {
            addNotification("Uma assinatura ativa é necessária para prosseguir com esta operação.", "warning");
        }

        if (!passwordHash) {
            addNotification('É preciso configurar o PIN para realizar ações no TwoAlert Web', 'warning');
            return;
        }

        try {
            await initiateVerification();

            const userExists = await checkUserExists(registrationId);
            if (userExists) {
                setRegisterId(registrationId)
                setOpenAlter(true)
            }
            else {
                addNotification('Usuário não encontrado!', 'error');
                return;
            }
        } catch {
            // A verificação falhou ou foi cancelada
        }
    };

    const MenuOptionsComponent = ({ registrationId, blockUser }) => {
        const EditIcon = () => (
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md"><path fillRule="evenodd" clipRule="evenodd" d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z" fill="currentColor"></path></svg>
        );

        const DeleteIcon = () => (
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md"><path fillRule="evenodd" clipRule="evenodd" d="M10.5555 4C10.099 4 9.70052 4.30906 9.58693 4.75114L9.29382 5.8919H14.715L14.4219 4.75114C14.3083 4.30906 13.9098 4 13.4533 4H10.5555ZM16.7799 5.8919L16.3589 4.25342C16.0182 2.92719 14.8226 2 13.4533 2H10.5555C9.18616 2 7.99062 2.92719 7.64985 4.25342L7.22886 5.8919H4C3.44772 5.8919 3 6.33961 3 6.8919C3 7.44418 3.44772 7.8919 4 7.8919H4.10069L5.31544 19.3172C5.47763 20.8427 6.76455 22 8.29863 22H15.7014C17.2354 22 18.5224 20.8427 18.6846 19.3172L19.8993 7.8919H20C20.5523 7.8919 21 7.44418 21 6.8919C21 6.33961 20.5523 5.8919 20 5.8919H16.7799ZM17.888 7.8919H6.11196L7.30423 19.1057C7.3583 19.6142 7.78727 20 8.29863 20H15.7014C16.2127 20 16.6417 19.6142 16.6958 19.1057L17.888 7.8919ZM10 10C10.5523 10 11 10.4477 11 11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16V11C13 10.4477 13.4477 10 14 10Z" fill="currentColor"></path></svg>
        );

        const handleEdit = (e) => {
            if (blockUser || statusSubscription !== "active" && statusSubscription !== "past_due") {
                e.preventDefault(); // Previne a ação padrão
                e.stopPropagation(); // Impede que o evento se propague mais
                return; // Impede que a ação de edição seja executada
            }
            onPressedAlter(registrationId);
        };

        const handleDelete = (e) => {
            e.stopPropagation();
            onPressedDelete(registrationId);
        };

        const menuOptions = [
            {
                label: 'Editar',
                onSelect: handleEdit,
                Icon: EditIcon,
                color: '#1D1D1F',
                disabled: blockUser || statusSubscription !== "active" && statusSubscription !== "past_due"
            },
            {
                label: 'Deletar',
                onSelect: handleDelete,
                Icon: DeleteIcon,
                color: '#FF4D4D',
                disabled: false // Mantém habilitado
            },
        ];

        return menuOptions.map((option, index) => (
            <li
                key={index}
                onClick={option.onSelect}
                style={{
                    color: option.color,
                    opacity: option.disabled ? 0.3 : 1, // Altera a opacidade se desabilitado
                    pointerEvents: option.disabled ? 'none' : 'auto' // Desabilita eventos de clique se necessário
                }}
            >
                <option.Icon /> {option.label}
            </li>
        ));
    };

    const [menuStates, setMenuStates] = useState({});

    const [openMenuId, setOpenMenuId] = useState(null);

    const toggleMenu = (userId) => {
        setOpenMenuId(prevOpenMenuId => prevOpenMenuId === userId ? null : userId);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openMenuId) {
                const menuElement = document.getElementById(`menu-${openMenuId}`);
                if (menuElement && !menuElement.contains(event.target)) {
                    setOpenMenuId(null);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openMenuId]);

    const UserMenu = ({ user, onButtonPress, onPressedAlter, onPressedDelete }) => {

        return (
            <div key={user.id} className="cardTop">
                <div className="cardUser">
                    <div className="viewPhotoUser" style={{ width: 100, height: 100, borderRadius: 8, backgroundColor: '#F2F2F2' }}>
                        <img draggable={false} src={user.images[0]} style={{ width: 100, height: 100, borderRadius: 8, backgroundColor: '#F2F2F2', objectFit: 'cover' }} />
                    </div>
                    <div className="containerUsers">
                        <div className="flexRow">
                            <h3>{user.nome}</h3>
                            <span>{tempoAtras(user.creationDate)}</span>
                        </div>
                        <p>{user.descricao}</p>
                        <div className="flexRow">
                            <button onClick={() => onButtonPress(user.id, user.code)} disabled={user?.blockUser}>{statusSubscription !== "active" && statusSubscription !== 'past_due' ? 'Assinar TwoAlert' : user.code}</button>
                            <div className="containerMenu" id={`menu-${user.id}`}>
                                <FontAwesomeIcon icon={faEllipsisVertical} color="#999999" onClick={() => toggleMenu(user.id)} />
                                <div className={openMenuId === user.id ? "menuOptions active" : "menuOptions"}>
                                    <ul>
                                        <MenuOptionsComponent
                                            registrationId={user.id}
                                            blockUser={user?.blockUser}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    function tempoAtras(timestamp) {
        const agora = new Date();
        const passado = timestamp.toDate(); // Converter o objeto Timestamp para um objeto Date
        const diffEmSegundos = Math.floor((agora - passado) / 1000);

        let tempoAtras;

        if (diffEmSegundos < 60) {
            tempoAtras = diffEmSegundos === 1 ? `há ${diffEmSegundos} seg` : `há ${diffEmSegundos} segs`;
        } else if (diffEmSegundos < 3600) {
            const minutos = Math.floor(diffEmSegundos / 60);
            tempoAtras = minutos === 1 ? `há ${minutos} min` : `há ${minutos} mins`;
        } else if (diffEmSegundos < 86400) {
            const horas = Math.floor(diffEmSegundos / 3600);
            tempoAtras = horas === 1 ? `há ${horas} hr` : `há ${horas} hrs`;
        } else if (diffEmSegundos < 604800) {
            const dias = Math.floor(diffEmSegundos / 86400);
            tempoAtras = dias === 1 ? `há ${dias} dia` : `há ${dias} dias`;
        } else if (diffEmSegundos < 2592000) {
            const semanas = Math.floor(diffEmSegundos / 604800);
            tempoAtras = semanas === 1 ? `há ${semanas} sem` : `há ${semanas} sems`;
        } else if (diffEmSegundos < 31536000) {
            const meses = Math.floor(diffEmSegundos / 2592000);
            tempoAtras = meses === 1 ? `há ${meses} mes` : `há ${meses} meses`;
        } else {
            const anos = Math.floor(diffEmSegundos / 31536000);
            tempoAtras = anos === 1 ? `há ${anos} ano` : `há ${anos} anos`;
        }

        return tempoAtras;
    }

    const checkUserExists = async (registrationId) => {
        try {
            const docRef = doc(db, 'dataUsersRegister', registrationId);
            const docSnapshot = await getDoc(docRef);
            return docSnapshot.exists();
        } catch (error) {
            return false;
        }
    };


    const deleteUserData = async (registrationId) => {
        try {
            const userDocRef = doc(db, "dataUsersRegister", registrationId);

            const userDocSnapshot = await getDoc(userDocRef);
            if (!userDocSnapshot.exists()) {
                return false;
            }

            const userData = userDocSnapshot.data();
            const imageUrls = userData.images || [];
            const pdfUrl = userData.pdf;

            for (const url of imageUrls) {
                const fileRef = ref(getStorage(), url);
                await deleteObject(fileRef);
            }

            if (pdfUrl) {
                const pdfRef = ref(getStorage(), pdfUrl);
                await deleteObject(pdfRef);
            }

            await deleteDoc(userDocRef);
            return true;
        } catch (error) {
            console.error('Erro ao deletar dados do usuário:', error);
            return false;
        }
    };

    const onPressedDelete = async (users) => {
        if (!passwordHash) {
            addNotification('É preciso configurar o PIN para realizar ações no TwoAlert Web', 'warning');
            return;
        }

        try {
            await initiateVerification();

            openDialogue("Confirmação!", "Você realmente deseja excluir este usuário?", [
                {
                    text: "Não",
                    onClick: () => {
                        closeDialogue();
                    }
                },
                {
                    text: "Sim",
                    onClick: () => handleUserDeletion(users)
                },
            ]);
        } catch {
            // A verificação falhou ou foi cancelada
        }
    };

    const handleUserDeletion = async (users) => {
        closeDialogue();
        addNotification("Excluindo usuário...", "loading");
        const userExists = await checkUserExists(users);
        if (!userExists) {
            addNotification('Usuário não encontrado!', 'error');
            removeLoadingNotification();
            closeDialogue();
            return;
        }
        const userDeleted = await deleteUserData(users);
        if (userDeleted) {
            addNotification('Usuário excluido com sucesso', 'success');
            removeLoadingNotification();
        } else {
            addNotification('Falha ao excluir usuário', 'error');
            removeLoadingNotification();
        }
    };

    const [showInformation, setShowInformation] = useState(true)
    const [viewLimitExceededCount, setViewLimitExceededCount] = useState(0)

    function countBlockedUsers(users) {
        return users.reduce((count, user) => count + (user.blockUser === true ? 1 : 0), 0);
    }

    useEffect(() => {
        setViewLimitExceededCount(countBlockedUsers(users))
    }, [users])

    return (
        <div className='allHome'>

            <Verification />

            <UpdateAccount showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} />

            {blockPrint && (
                <div className="blockPrint">
                    <h1>PrintScreen não permitido!</h1>
                    <button onClick={() => setBlockPrint(false)}>Fechar</button>
                </div>
            )}

            <div id="notifications"></div>

            <Menu />
            <div className='home'>
                {users.length >= 1
                    ?
                    <>
                        <div className={openAlter ? 'flexUsersUser active' : 'flexUsersUser'}>

                            {showInformation && viewLimitExceededCount > 0 && (
                                <div className="bannerInformations">
                                    <div className="containerBannerInformarion">
                                        <div className="iconBannerInformations">
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </div>
                                        <div className="contentBannerInformations">
                                            <span>
                                                {viewLimitExceededCount > 1 ?
                                                    `Identificamos que ${viewLimitExceededCount} dos seus usuários excederam o limite de 20 visualizações. Para garantir a integridade e a conformidade dos dados, recomendamos a exclusão e o re-cadastro desses usuários. Essa medida ajuda a manter a clareza e a precisão das informações dentro do sistema.` :
                                                    'Parece que um de seus usuários já atingiu o limite de 20 visualizações. Convém que você o exclua e o recadastre novamente para manter a clareza e conformidade nos dados.'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <button onClick={() => setShowInformation(false)}>Descartar</button>
                                </div>
                            )}

                            <div className={"containerUsersUserData"}>
                                {!loading ? (
                                    <>
                                        {users.map(user => (
                                            <UserMenu
                                                key={user.id}
                                                user={user}
                                                onButtonPress={onButtonPress}
                                                onPressedAlter={onPressedAlter}
                                                onPressedDelete={onPressedDelete}
                                                setIsMenuOpen={() => toggleMenu(user.id)}
                                                isMenuOpen={menuStates[user.id] || false}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <div className="loadingComponent">
                                        <LoadingComponent />
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className={openAlter ? 'containerInfoData active' : 'containerInfoData'}>
                            {openAlter && (
                                <div className="allContentInformationData">
                                    {showChangeData === 0
                                        ?
                                        <div className="choseInfomationData">
                                            <div className="containerInfomationData" onClick={() => { setShowChangeData(1) }}>
                                                <FontAwesomeIcon icon={faImage} />
                                                <span>Alterar imagens</span>
                                            </div>
                                            <div className="containerInfomationData" onClick={() => { setShowChangeData(2); setShowWitchChangeData(1) }}>
                                                <FontAwesomeIcon icon={faUserCircle} />
                                                <span>Nome, idade ou biografia</span>
                                            </div>
                                            <div className="containerInfomationData" onClick={() => { setShowChangeData(2); setShowWitchChangeData(2) }}>
                                                <FontAwesomeIcon icon={faFolder} />
                                                <span>Est. Civil, Nacionalidade, Sexo e Gênero</span>
                                            </div>
                                            <div className="closeInformations" onClick={() => setOpenAlter(false)}>
                                                <FontAwesomeIcon icon={faX} />
                                            </div>
                                        </div>
                                        :
                                        <div className="containerOptionsAlert">
                                            <div className="containerOptionsHeader">
                                                <div className="buttonBack" onClick={() => { setShowChangeData(0) }}>
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                    <span>Voltar</span>
                                                </div>
                                                <p>Alterar dados</p>
                                                {showChangeData === 1 && (
                                                    <button type="button" className="send" onClick={handleSaveImages}>Salvar</button>
                                                )}
                                            </div>
                                            <div className="containerOptionsContent">
                                                {
                                                    showChangeData === 1
                                                        ? <ChangeData data={{ uid: user?.uid, registerId: registerId }} close={() => setShowChangeData(0)} openShowPDF={openShowPDF} setOpenShowPDF={setOpenShowPDF} images={images} setImages={setImages} imagesToDelete={imagesToDelete} setImagesToDelete={setImagesToDelete} pdf={pdf} setPdf={setPdf} pdfToDelete={pdfToDelete} setPdfToDelete={setPdfToDelete} />
                                                        : showChangeData === 2
                                                            ? <ChangeDataInfo data={{ registerId: registerId }} ChangeData={showWitchChangeData} />
                                                            : null
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </>
                    :
                    <div className="usersRegistered">
                        <h2>Ainda não há usuários cadastrados</h2>
                        <p>Descubra os benefícios de cadastrar usuários e como isso pode otimizar suas atividades. <a href='https://support.twoalert.com.br/user-management/register-users' target="_blank" rel="noopener">Saiba mais</a> sobre o cadastro e gestão de usuários.</p>
                    </div>
                }
            </div>
        </div >
    )
}