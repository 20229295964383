import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImageAccordion from './ImageAccordion'
import './style.css'
import { faX } from '@fortawesome/free-solid-svg-icons'

export default function DataImage({ selectedImage, setSelectedImage, handlePressWebDataInfo }) {
    return (
        <div className='containerImageData'>
            <div className='informationDataImage'>
                <div className='closeButton'>
                    <div className='closeInformationsImage' onClick={() => setSelectedImage(null)}>
                        <FontAwesomeIcon icon={faX} />
                    </div>
                </div>
                <div className='containerImage'>
                    <img src={selectedImage?.imageUrl} draggable={false} alt='user image' />
                </div>

                <div className='analytcs' style={{ padding: 0, marginTop: 15 }}>

                    <section style={{ padding: 0 }}>
                        <div className='headerSection'>
                            <h3>Dados encontrados na web</h3>
                            <div className='buttonAboutSection' onClick={handlePressWebDataInfo}>
                                <span>?</span>
                            </div>
                        </div>

                        <div style={{ gap: 10 }}>
                            <ImageAccordion images={selectedImage?.webDetectionResults?.fullMatchingImages.map(image => image.url)} title={'Imagens correspondentes'} />

                            <ImageAccordion images={selectedImage?.webDetectionResults?.partialMatchingImages.map(image => image.url)} title={'Imagens correspondentes parciais'} />

                            <ImageAccordion images={selectedImage?.webDetectionResults?.visuallySimilarImages.map(image => image.url)} title={'Imagens visualmente semelhantes'} />
                        </div>
                    </section>

                    <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column', gap: 8 }}>
                        {selectedImage?.webDetectionResults?.fullMatchingInSocialMedia && (
                            <div className='contentAboutUser'>
                                <span>Uma imagem correspondente a esta foi encontrada nas redes sociais</span>
                            </div>
                        )}

                        {selectedImage?.webDetectionResults?.partialMatchingInSocialMedia && (
                            <div className='contentAboutUser'>
                                <span>Uma imagem parcialmente igual a esta foi encontrada nas redes sociais</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
