import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyA1WEhhql_t2QbI0Jt3cXw7c6XTsc8t_ls",
    authDomain: "twoalert-68c80.firebaseapp.com",
    databaseURL: "https://twoalert-68c80-default-rtdb.firebaseio.com",
    projectId: "twoalert-68c80",
    storageBucket: "twoalert-68c80.appspot.com",
    messagingSenderId: "577881324479",
    appId: "1:577881324479:web:c301ad3d0efd554e5a3f0a"
};

initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage();
export const database = getDatabase();