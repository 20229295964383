import React, { useState, useEffect, useContext, useCallback } from 'react';
import { 
    Box, 
    Typography,
    Button,
    CircularProgress,
    styled,
    Paper,
    Modal,
    IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Menu from "../../components/menu";
import InfoCode from "../../components/infoCode";
import { addNotification, removeLoadingNotification } from '../../components/notification';
import { UserContext } from '../../components/UserProvider';
import { DataAnalysed } from '../../componentsTwoAlert/DataAnalysed';
import fetchSecrets from '../../config/fetchSecrets';
import axios from 'axios';

const RootContainer = styled('div')({
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#F2F2F2'
});

const ContentContainer = styled('div')(({ show }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    position: 'relative',
    transition: 'padding 0.3s ease',
    ...(show && {
        paddingRight: '360px'
    })
}));

const SearchContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    maxWidth: '440px',
    width: '100%'
}));

const SearchButton = styled(Button)({
    height: '56px',
    minWidth: '160px',
    backgroundColor: '#233DFF',
    borderRadius: '12px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#1a2ebf'
    },
    '&.Mui-disabled': {
        backgroundColor: '#E5E5E5',
        color: '#999999'
    }
});

const InfoCodeContainer = styled(Box)(({ show }) => ({
    position: 'fixed',
    right: show ? 0 : '-420px',
    top: 0,
    width: '420px',
    height: '100vh',
    backgroundColor: '#FFFFFF',
    boxShadow: '-4px 0 16px rgba(0, 0, 0, 0.08)',
    transition: 'right 0.3s ease-in-out',
    overflowY: 'auto'
}));

const CodeInputContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    justifyContent: 'center',
});

const SingleInput = styled('input')({
    width: '35px',
    height: '42px',
    border: '2px solid #D1D5DB',
    borderRadius: '10px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    color: '#111827',
    backgroundColor: 'white',
    '&:focus': {
        outline: 'none',
        borderColor: '#233DFF',
        boxShadow: '0 0 0 2px rgba(35, 61, 255, 0.1)'
    }
});

const VerifyButton = styled(Button)({
    backgroundColor: '#233DFF',
    borderRadius: '12px',
    padding: '14px',
    width: '80%',
    color: 'white',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'none',
    boxShadow: '0 2px 4px rgba(35, 61, 255, 0.25)',
    '&:hover': {
        backgroundColor: '#1a2ebf'
    },
    '&.Mui-disabled': {
        backgroundColor: '#E5E5E5',
        color: '#999999'
    }
});

const PrintModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(242, 242, 242, 0.8)',
        backdropFilter: 'blur(8px)'
    }
});

export default function ViewInfoCode() {
    const [blockPrint, setBlockPrint] = useState(false);
    const { user } = useContext(UserContext);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [codigo, setCodigo] = useState({ value: '', error: '' });
    const [veracityPercentage, setVeracityPercentage] = useState(0);
    const [animateBall, setAnimateBall] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'PrintScreen' || e.keyCode === 44 || e.keyCode === 16) {
                e.preventDefault();
                setBlockPrint(true);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    const fetchUserData = useCallback(async () => {
        if (codigo.value.trim() === '') {
            addNotification('É preciso que o código tenha 8 caracteres', 'warning');
            return;
        }

        setLoading(true);
        addNotification("Procurando dados...", "loading");

        try {
            const secrets = await fetchSecrets();
            const response = await axios.post(
                `${secrets.SERVER}/fetch_user_data`,
                {
                    code: codigo.value.trim(),
                    userUid: user.uid
                },
                {
                    headers: {
                        'Authorization': `Bearer ${secrets.AUTH_TOKEN}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success) {
                const { userData, veracityPercentage, message } = response.data;
                setShow(true);
                setUserData(userData);
                setVeracityPercentage(veracityPercentage);
                removeLoadingNotification();
                if (message) addNotification(message, 'info');
            } else {
                addNotification(response.data.message, 'error');
            }
        } catch (error) {
            addNotification("Ocorreu um erro ao buscar os dados. Por favor, tente novamente.", 'error');
        } finally {
            setLoading(false);
            removeLoadingNotification();
        }
    }, [codigo, user.uid]);

    useEffect(() => {
        if (userData) {
            const totalDuration = 20000;
            const intervalDuration = 200;
            const increment = 100 / (totalDuration / intervalDuration);

            const interval = setInterval(() => {
                setProgress((oldProgress) => {
                    const newProgress = oldProgress + increment;
                    if (newProgress < 100) {
                        return newProgress;
                    } else {
                        setShow(false);
                        setAnimateBall(true);
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, intervalDuration);

            return () => clearInterval(interval);
        }
    }, [userData]);

    const close = () => {
        setAnimateBall(false);
        setUserData(null);
        setProgress(0);
        setCodigo({ value: '', error: '' });
    };

    return (
        <RootContainer>
            <Menu />
            
            <ContentContainer show={show}>
                <PrintModal
                    open={blockPrint}
                    onClose={() => setBlockPrint(false)}
                >
                    <Paper
                        sx={{
                            p: 3,
                            borderRadius: 2,
                            maxWidth: 360,
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            backdropFilter: 'blur(8px)'
                        }}
                    >
                        <IconButton
                            onClick={() => setBlockPrint(false)}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" textAlign="center" gutterBottom>
                            PrintScreen não permitido!
                        </Typography>
                        <SearchButton
                            fullWidth
                            onClick={() => setBlockPrint(false)}
                            sx={{ mt: 2 }}
                        >
                            Fechar
                        </SearchButton>
                    </Paper>
                </PrintModal>

                {animateBall && (
                    <DataAnalysed
                        animateBall={animateBall}
                        data={userData}
                        veracityPercentage={veracityPercentage}
                        close={close}
                    />
                )}

                <SearchContainer>
                    <Typography
                        variant="h4"
                        textAlign="center"
                        sx={{ 
                            fontSize: 28,
                            fontWeight: 600,
                            color: '#111827',
                        }}
                    >
                        Verificação
                    </Typography>

                    <CodeInputContainer>
                        {Array(8).fill(0).map((_, index) => (
                            <SingleInput
                                key={index}
                                value={codigo.value[index] || ''}
                                maxLength={index === 0 ? 8 : 1}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    if (index === 0 && text.length > 1) {
                                        // Para colar texto
                                        const newValue = text.slice(0, 8);
                                        setCodigo({ value: newValue, error: '' });
                                    } else {
                                        const newValue = codigo.value.split('');
                                        newValue[index] = text;
                                        setCodigo({ value: newValue.join(''), error: '' });
                                        
                                        // Auto-focus próximo input
                                        if (text && index < 7) {
                                            const nextInput = document.querySelector(`input[data-index="${index + 1}"]`);
                                            if (nextInput) nextInput.focus();
                                        }
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace' && !codigo.value[index] && index > 0) {
                                        const prevInput = document.querySelector(`input[data-index="${index - 1}"]`);
                                        if (prevInput) prevInput.focus();
                                    }
                                }}
                                data-index={index}
                            />
                        ))}
                    </CodeInputContainer>

                    <VerifyButton
                        // disabled={codigo.value.length !== 8 || loading}
                        onClick={fetchUserData}
                        fullWidth
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
                            <Typography component="span">
                                Verificar
                            </Typography>
                            {loading && <CircularProgress size={20} sx={{ color: 'inherit' }} />}
                        </Box>
                    </VerifyButton>
                </SearchContainer>

                <InfoCodeContainer show={show}>
                    <InfoCode
                        data={{
                            images: userData?.images,
                            nome: userData?.nome,
                            desc: userData?.descricao,
                            idade: userData?.idade,
                            nacionalidade: userData?.nacionalidade,
                            estadoCivil: userData?.estadoCivil,
                            selectedValueSG: userData?.genero,
                            pdf: userData?.pdf,
                            cadas: userData?.cadastrante,
                            progress: progress
                        }}
                    />
                </InfoCodeContainer>
            </ContentContainer>
        </RootContainer>
    );
}