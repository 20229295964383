import React, { useState, useEffect } from 'react'
import Menu from "../../components/menu"
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { addNotification } from '../../components/notification';
import UserAvatar from '../../components/userTwoAlert';

import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Marker as LeafletMarker } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';

import { useContext } from 'react';
import { UserContext } from '../../components/UserProvider';

import Loading from '../../assets/mn224E_W9XQ.gif';

function SetViewOnClick({ animateRef, location }) {
    const map = useMap();

    React.useEffect(() => {
        if (animateRef.current && location && typeof location.lat === 'number' && typeof location.lng === 'number') {
            map.flyTo([location.lat, location.lng], map.getZoom(), {
                animate: true
            });
        }
    }, [map, location, animateRef]);

    return null;
}

const wsURL = 'wss://server-location-dot-twoalert-68c80.uc.r.appspot.com/';

export default function Location() {

    const [loadingState, setLoadingState] = useState(false)

    const animateRef = React.useRef(true);
    
    const center = { lat: -5.0937344, lng: -42.8234802 }

    const { user, displayName, nameExist, photoURL } = useContext(UserContext);

    const [code, setCode] = useState({ value: '', error: '' });
    const [location, setLocation] = useState(null);
    const [ws, setWs] = useState(null);
    const [alarmActivated, setAlarmActivated] = useState(false);


    const [adminData, setAdminData] = useState(null)

    const createAvatarIcon = () => {

        // Renderizar o UserAvatar para uma string HTML
        const avatarHtmlString = ReactDOMServer.renderToString(
            <div className="pulse">
                <UserAvatar
                    userName={adminData?.adminName || 'Usuário'}
                    photoURL={adminData?.adminPhoto || ''}
                    size={30}
                />
            </div>
        );

        // Criar um divIcon com o HTML do UserAvatar
        return L.divIcon({
            html: avatarHtmlString,
            className: '', // Use uma classe vazia para evitar estilos padrão do Leaflet
            iconSize: [30, 30],
        });
    };

    const avatarIcon = createAvatarIcon();

    useEffect(() => {
        const newWs = new WebSocket(wsURL);

        newWs.onopen = () => {
            console.log('Conectado ao servidor');
        };

        newWs.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'locationUpdate') {
                setLocation({
                    lat: data.location.latitude,
                    lng: data.location.longitude,
                    timestamp: data.timestamp
                });
                setAlarmActivated(data.alarmStatus);
            } else if (data.type === 'viewerJoined') {
                setLocation(data.location);
                setAdminData({ adminPhoto: data.adminPhoto, adminName: data.adminName })
                setAlarmActivated(data.location.alarmStatus);
                setLocation({
                    lat: data.location.latitude,
                    lng: data.location.longitude,
                });
            } else if (data.type === 'alarmUpdate') {
                setAlarmActivated(data.alarmStatus);

            } else if (data.type === 'disconnected') {
                addNotification(data.message, 'error');
                setAdminData(null)
                setLocation(null)
                setAlarmActivated(null)
                ws.close();
            } else if (data.type === 'error') {
                addNotification(data.message, 'error');
                setAdminData(null)
                setLocation(null)
                setAlarmActivated(null)
            }
        };

        newWs.onerror = (error) => {
            console.error('WebSocket error:', error);
            setAdminData(null)
            setLocation(null)
            setAlarmActivated(null)
        };

        newWs.onclose = (e) => {
            console.error('WebSocket closed. Reconnecting...', e.reason);
            setAdminData(null)
            setLocation(null)
            setAlarmActivated(null)
            setTimeout(() => {
                const reconnectWs = new WebSocket(wsURL);
                setWs(reconnectWs);
            }, 5000); // Tentar reconectar após 5 segundos
        };

        setWs(newWs);

        return () => {
            newWs.close();
        };
    }, []);

    const checkLocation = async () => {
        const deviceId = localStorage.getItem('uniqueID');

        if (!nameExist || !photoURL) {
            addNotification('Antes de compartilhar sua localização em tempo real, é necessário definir um nome e adicionar uma foto de perfil.', 'error');
            return;
        }

        if (code.value.trim() === '') {
            addNotification('Código não pode estar vazio', 'error');
            return;
        }

        const payload = {
            type: 'viewerJoin',
            trackerId: code.value.trim(),
            uid: user.uid,
            deviceId: deviceId
        };

        ws.send(JSON.stringify(payload));
    };

    const disconnectFromServer = async () => {
        const deviceId = localStorage.getItem('uniqueID');
        if (ws && ws.readyState === WebSocket.OPEN) {
            const payload = {
                type: 'viewerLeave',
                uid: user.uid,
                trackerId: code.value.trim(), // Supondo que o código está armazenado em `code.value`
                deviceId: deviceId
            };

            ws.send(JSON.stringify(payload));
            ws.close();
        }
    };

    return (
        <div className='allHome'>

            {/* <svg width="16" height="12" viewBox="0 0 16 12" fill="none" class="device-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5001 6.5V1.5H12.5001V6.5H3.5001ZM2.0001 1C2.0001 0.44772 2.44782 0 3.0001 0H13.0001C13.5524 0 14.0001 0.44772 14.0001 1V7C14.0001 7.55228 13.5524 8 13.0001 8H3.0001C2.44782 8 2.0001 7.55228 2.0001 7V1ZM0.75606 11.8398C3.06492 11.564 5.49346 11.4167 8.00043 11.4167C10.5074 11.4167 12.9359 11.564 15.2448 11.8398L15.4227 10.3504C13.0537 10.0674 10.5657 9.9167 8.00043 9.9167C5.43511 9.9167 2.94713 10.0674 0.578125 10.3504L0.75606 11.8398Z" fill="black"></path></svg> */}

            {/* <svg width="12" height="16" viewBox="0 0 12 16" fill="none" class="device-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 1.5H10.5V14.5H1.5V1.5ZM0 1.5C0 0.671573 0.67157 0 1.5 0H10.5C11.3284 0 12 0.671573 12 1.5V14.5C12 15.3284 11.3284 16 10.5 16H1.5C0.67157 16 0 15.3284 0 14.5V1.5ZM7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12Z" fill="black"></path></svg> */}

            <div id="notifications"></div>
            <Menu />
            <div className='home'>
                <MapContainer center={center} zoom={10} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; OpenStreetMap contributors"
                    />

                    <div className={alarmActivated ? 'containerCodeAlart active' : 'containerCodeAlart'}>
                        <span>Usuário ativou um alerta!</span>
                    </div>

                    {location && (
                        <>
                            <SetViewOnClick animateRef={animateRef} location={location} />
                            <LeafletMarker position={location} icon={avatarIcon} />
                        </>
                    )}

                    {!adminData && (
                        <div className='containerInputCode'>
                            <h2>Acompanhe alguém em tempo real</h2>
                            <div className='inputCode'>
                                <input placeholder='Código de Localização' value={code.value} onChange={(e) => setCode(prevCode => ({ value: e.target.value, error: '' }))} />
                                <button onClick={checkLocation}>
                                    {loadingState
                                        ?
                                        <img src={Loading} alt='loading animation' />
                                        :
                                        <FontAwesomeIcon icon={faSearch} />
                                    }
                                </button>
                            </div>
                        </div>
                    )}
                </MapContainer>

            </div>
        </div>

    )
}