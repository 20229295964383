import React, { createContext, useState } from 'react';
import Dialogue from '..';

export const DialogueContext = createContext();

export const DialogueProvider = ({ children }) => {

    const [dialogueProps, setDialogueProps] = useState({ isOpen: false });

    const showDialogue = (props) => {
        setDialogueProps({ ...props, isOpen: true });
    };

    const closeDialogue = () => {
        setDialogueProps({ isOpen: false });
    };

    return (
        <DialogueContext.Provider value={{ showDialogue, closeDialogue }}>
            {children}
            {dialogueProps.isOpen && <Dialogue {...dialogueProps} onClose={closeDialogue} />}
        </DialogueContext.Provider>
    );
};
