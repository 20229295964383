import React, { useEffect, useState } from "react";
import UserAvatar from "../../../../components/userTwoAlert"
import './style.css'
import { UserContext } from '../../../../components/UserProvider';
import { useContext } from 'react';
import { db } from '../../../../config/firebase';

import { doc, collection, onSnapshot, updateDoc, getDoc, deleteField } from 'firebase/firestore';
import { addNotification } from '../../../../components/notification'
import { LoginContext } from '../../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons';

import edgeLogo from '../../../../assets/connectSession/Microsoft_Edge_logo.png'
import chromeLogo from '../../../../assets/connectSession/chrome.png'
import safariLogo from '../../../../assets/connectSession/safari.png'
import firefoxLogo from '../../../../assets/connectSession/social.png'
import operaLogo from '../../../../assets/connectSession/opera.png'
import androidLogo from '../../../../assets/connectSession/android.png'
import appleLogo from '../../../../assets/connectSession/Apple_logo_black.svg.webp'

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useDialogue } from "../../modal/dialogue/useDialogue";
import Verification from "../../modal/verification";
import { VerificationContext } from "../../modal/verification/VerificationProvider";
import { Link } from "react-router-dom";

export default function Account() {

    const { user, photoURL, displayName, email, passwordHash } = useContext(UserContext)

    const { setIsLoginComplete } = useContext(LoginContext);

    const { initiateVerification, setShowVerification } = useContext(VerificationContext);

    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        if (!user.uid) return;

        const userRef = doc(collection(db, 'users'), user.uid);

        const unsubscribe = onSnapshot(userRef, (docSnap) => {
            if (docSnap.exists()) {
                const userData = docSnap.data();
                const userSessions = userData.sessionsDevice || [];
                setSessions(userSessions);
            }
        });

        // Limpar a inscrição ao desmontar o componente
        return unsubscribe;
    }, [user.uid]);

    const browserLogos = {
        "Mozilla Firefox": firefoxLogo,
        "Opera": operaLogo,
        "Microsoft Edge": edgeLogo,
        "Google Chrome": chromeLogo,
        "Apple Safari": safariLogo,
        "Internet Explorer": '', // Adicione um logo para o Internet Explorer
        "Unknown": '', // Adicione um logo padrão para quando o navegador é desconhecido
    };

    const osLogos = {
        "macOS": appleLogo,
        "iOS": appleLogo,
        "iPadOS": appleLogo,
        "Windows": '', // Adicione um logo para Windows
        "Linux": '', // Adicione um logo para Linux
        "Android": androidLogo,
        "Unknown": '', // Adicione um logo padrão para quando o sistema operacional é desconhecido
    };

    function humanizeTimeDifference(firebaseTimestamp) {
        // Converte o Timestamp do Firebase para um objeto Date
        const sessionDate = new Date(firebaseTimestamp.seconds * 1000 + firebaseTimestamp.nanoseconds / 1e6);
        const now = new Date();
        const diffInSeconds = Math.floor((now - sessionDate) / 1000);

        if (diffInSeconds < 60) {
            return diffInSeconds === 1 ? '1 segundo' : `${diffInSeconds} segundos`;
        }
        if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return minutes === 1 ? '1 minuto' : `${minutes} minutos`;
        }
        if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return hours === 1 ? '1 hora' : `${hours} horas`;
        }
        if (diffInSeconds < 604800) {
            const days = Math.floor(diffInSeconds / 86400);
            return days === 1 ? '1 dia' : `${days} dias`;
        }
        if (diffInSeconds < 2419200) {
            const weeks = Math.floor(diffInSeconds / 604800);
            return weeks === 1 ? '1 semana' : `${weeks} semanas`;
        }

        const months = Math.floor(diffInSeconds / 2419200);
        return months === 1 ? '1 mês' : `${months} meses`;
    }

    const [selectedSession, setSelectedSession] = useState(null);

    function formatSessionDate(date) {
        // Converte o formato {seconds, nanoseconds} para um objeto Date
        const sessionDate = new Date(date.seconds * 1000 + date.nanoseconds / 1e6);
        const now = new Date();

        const diffInDays = Math.floor((now - sessionDate) / (1000 * 60 * 60 * 24));

        let formattedDate;
        if (diffInDays === 0) {
            formattedDate = 'hoje';
        } else if (diffInDays === 1) {
            formattedDate = 'ontem';
        } else {
            formattedDate = sessionDate.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            });
        }

        const sessionTime = sessionDate.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return `${formattedDate} às ${sessionTime}`;
    }

    function formatLocation(session) {
        const parts = [session.city, session.region, session.country].filter(Boolean);

        if (parts.length > 0) {
            return parts.join(', ');
        } else {
            return 'Location not available';
        }
    }

    const handleDisconnect = async (uniqueID) => {

        setIsLoginComplete(false)

        try {
            // Primeiro, recuperamos o documento atual
            const userDoc = doc(db, "users", user.uid);
            const userDocData = (await getDoc(userDoc)).data();

            // Em seguida, encontramos o índice do objeto da sessão que queremos remover
            const updatedSessions = userDocData.sessionsDevice.filter(session => session.uniqueID !== uniqueID);

            // Finalmente, atualizamos o documento com o novo array de sessões
            await updateDoc(userDoc, {
                sessionsDevice: updatedSessions
            });

            // Após deletar a sessão, resetamos o selectedSession para null
            addNotification("Sessão desconectada com sucesso", 'success');
            setSelectedSession(null);

            setTimeout(() => {
                setIsLoginComplete(true)
            }, 2000)
        } catch (e) {
            // console.error("Error removing document: ", e);
            addNotification("Desculpe! Não foi possível desconectar desta sessão", 'error');
            setIsLoginComplete(true)
        }
    };

    const deletePasswordHash = async () => {
        const userDocRef = doc(db, "users", user.uid); // Acessa o documento do usuário

        try {
            await updateDoc(userDocRef, {
                passwordHash: deleteField() // Remove completamente o campo passwordHash
            });
            addNotification("PIN removido com sucesso.", 'success');
        } catch (error) {
            console.error("Erro ao remover o PIN:", error);
            addNotification("Erro ao remover o PIN.", 'error');
        }
    };

    const { openDialogue, closeDialogue } = useDialogue();

    const handleClick = () => {
        const hasPasswordHash = passwordHash; // Supondo que 'passwordHash' seja uma variável/estado que você já tenha

        const dialogueTitle = hasPasswordHash ? 'Configurar bloqueio' : 'Ativar bloqueio de ações?';
        const dialogueMessage = "O bloqueio de ações é um PIN de 6 digitos que você poderá criar para realizar ações como cadastro, editar usuário, deletar usuário ou gerar um código twoalert.";

        const dialogueOptions = hasPasswordHash ? [
            {
                text: "Excluir",
                onClick: () => {
                    closeDialogue();
                    deletePasswordHash();
                }
            },
            {
                text: "Alterar",
                onClick: () => {
                    closeDialogue();
                    initiateVerification();
                    // Adicione lógica adicional, se necessário, para a alteração do PIN
                }
            }
        ] : [
            {
                text: "Não ativar",
                onClick: () => {
                    closeDialogue();
                }
            },
            {
                text: "Ativar",
                onClick: () => {
                    closeDialogue();
                    initiateVerification();
                }
            }
        ];

        openDialogue(dialogueTitle, dialogueMessage, dialogueOptions);
    };

    return (
        <div className="allAccount">

            <Verification type={passwordHash ? 'update' : 'configure'} />

            <div id="notifications"></div>

            {selectedSession && (
                <aside>
                    <div className='infoDeviceLoged'>
                        <div className='closeInfoDeviceLoged'>
                            <FontAwesomeIcon icon={faX} onClick={() => setSelectedSession(null)} />
                        </div>
                        <div className='headerinfoDeviceLoged'>
                            <img src={browserLogos[selectedSession.browserName] || osLogos[selectedSession.operatingSystem] || edgeLogo} alt={selectedSession.browserName || selectedSession.operatingSystem} />
                            <h3>{selectedSession.browserName}</h3>
                        </div>
                        <div className='infoDeviceLogedContent'>
                            <div className='flexInfoDeviceLoged'>
                                <h3>Atividade</h3>
                                <span>Último login: {formatSessionDate(selectedSession.createdAt)}</span>
                            </div>
                            <div className='flexInfoDeviceLoged'>
                                <h3>Localização</h3>
                                <span>{formatLocation(selectedSession)}</span>
                            </div>
                        </div>
                        <button onClick={() => handleDisconnect(selectedSession.uniqueID)}>Desconectar</button>
                    </div>
                </aside>
            )}


            <div className="accountInformation">
                <UserAvatar photoURL={photoURL} email={email} userName={displayName} size={100} />
                <div className="contentInfoUserSettings">
                    <h3>{displayName}</h3>
                    <span>{email}</span>
                    <div className="confingPlusAccount">
                        <button onClick={handleClick}>{passwordHash ? 'Configurar bloqueio' : 'Bloqueio de ações'}</button>
                    </div>
                </div>

                <Link to="/account/deleteAccount" className="deleteAccount">Excluir sua conta</Link>
            </div>
            <div className="loginState">
                <h2>Onde você fez login?</h2>
                <span>Analise a atividade de login desta conta e desconecte-se das sessões que você não reconhece.</span>
                <div className="containerLoginState">
                    <div className="contentLoginState">
                        {sessions.map((session, index) => {
                            const browserLogo = browserLogos[session.browserName] || '';
                            const osLogo = osLogos[session.operatingSystem] || '';

                            return (
                                <div
                                    className='conectedDevices'
                                    key={index}
                                    onClick={() => setSelectedSession(session)}
                                >
                                    <div className='logDevice'>
                                        <img src={browserLogo || osLogo || edgeLogo} alt={session.browserName || session.operatingSystem} />
                                        <div className='infoDevice'>
                                            <h3>{session.browserName} ({session.operatingSystem})</h3>
                                            <span>Último login há: {humanizeTimeDifference(session.createdAt)}</span>
                                        </div>
                                    </div>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}