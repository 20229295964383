import React, { useState } from 'react';
import './styles.css';

export default function CustomModal ({ visible, setVisible, options, onSelectOption, textTitleModal, counties = false }) {
    return (
        <>
            {visible && (
                <aside className='asidePopupCustomModal'>
                    <div className='blurClose' onClick={() => setVisible(false)}></div>
                    <div className='containerInfoPopup'>
                        <div className='modalOptions'>
                            <span className='textTitleModal'>{textTitleModal}</span>
                            <div className='justifyContentOptions'>
                                {options.map((option, index) => (
                                    <div
                                        key={index}
                                        className='buttonOption'
                                        onClick={() => {
                                            onSelectOption(counties ? option.value : option);
                                            setVisible(false);
                                        }}
                                    >
                                        <span>{counties ? option.name : option}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </aside>
            )}
        </>
    );
};