import { auth } from '../../config/firebase'; // Supondo que você tenha um arquivo de configuração do Firebase
import { sendEmailVerification } from 'firebase/auth';
import { addNotification } from "../../components/notification";

export const sendVerifyEmail = async () => {
    const user = auth.currentUser;

    if (user) {
        try {
            await sendEmailVerification(user);
            addNotification("E-mail de verificação enviado.", 'success');
        } catch (error) {
            addNotification("Erro ao enviar email de verificação.", 'error');
            // throw new Error("Erro ao enviar email de verificação.");
        }
    } else {
        throw new Error("Usuário não está logado.");
    }
};
