import axios from 'axios';
import fetchSecrets from '../../config/fetchSecrets';

const sendAlertBotRequest = async (messages) => {

    const secrets = await fetchSecrets();
    const SERVER_URL = secrets.SERVER_URL;
    const AUTH_TOKEN = secrets.AUTH_TOKEN;

    try {
        const response = await axios.post(`${SERVER_URL}/send_alertbot_request`, {
            messages: messages,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`,
            }
        });

        return response.data.response;
    } catch (error) {
        console.error("Error calling the API:", error);
        return "Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.";
    }
};

export default sendAlertBotRequest;
