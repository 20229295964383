import React, { useState, useEffect, useContext } from 'react';
import { Card, Typography, Box, Button, CircularProgress, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    Warning as WarningIcon,
    CreditCard as CreditCardIcon,
    CalendarToday as CalendarTodayIcon,
    Info as InfoIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { UserContext } from '../../../../components/UserProvider';
import { useNavigate } from 'react-router-dom';
import { fetchSubscriptions } from '../../../../components/subscriptionService';
import axios from 'axios';
import { useDialogue } from '../../modal/dialogue/useDialogue';
import Logo from '../../../../assets/Logo-TwoAlert.jpeg'
import { handleCancelSubscription, handleManageSubscription } from './handleSubscribe';
import fetchSecrets from '../../../../config/fetchSecrets';

const commonFeatures = [
    { icon: '🔒', text: 'Cadastro seguro por terceiros' },
    { icon: '✓', text: 'Verificação precisa de identidade' },
    { icon: '📍', text: 'Localização em tempo real' },
    { icon: '👁️', text: 'Veja e bloqueie quem acessa sua localização, garantindo privacidade total' },
];

const StyledCard = styled(Card)(({ theme, recommended, selected }) => ({
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: selected ? '#F0F4FF' : '#FFFFFF',
    border: selected ? `2px solid #233DFF` : recommended ? `2px solid #233DFF` : '1px solid #E5E5E5',
}));

const TrialBadge = styled(Box)({
    backgroundColor: '#ECF5FF',
    padding: '6px 12px',
    borderRadius: '8px',
    alignSelf: 'flex-start',
    marginBottom: '10px',
    border: '1px solid #233DFF'
});

export default function Subscription() {
    const navigate = useNavigate();
    const { openDialogue, closeDialogue } = useDialogue();
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [activeSubscription, setActiveSubscription] = useState(null);
    const [plans, setPlans] = useState([]);
    const { user, email, hasUsedTrial, setStatusSubscription } = useContext(UserContext);

    useEffect(() => {
        const initialize = async () => {
            setLoading(true);
            try {
                const activeSubscription = await fetchSubscriptions(user.uid);
                if (activeSubscription?.isActive) {
                    setActiveSubscription(activeSubscription);
                    setStatusSubscription('active');
                } else {
                    setStatusSubscription(null);
                }
            } catch (err) {
                console.error('Erro na inicialização:', err);
                openDialogue('Erro', 'Não foi possível carregar as assinaturas disponíveis.', [
                    { text: 'OK', onClick: () => closeDialogue() }
                ]);
            } finally {
                setLoading(false);
            }
        };

        initialize();
    }, []);

    useEffect(() => {
        const fetchPlans = async () => {

            const secrets = await fetchSecrets();
            const SERVER = secrets.SERVER;
            const AUTH_TOKEN = secrets.AUTH_TOKEN;

            try {
                const response = await axios.get(`${SERVER}/list-plans`, {
                    headers: {
                        'Authorization': `Bearer ${AUTH_TOKEN}`,
                    }
                });

                let filteredPrices = response.data.prices.filter(price =>
                    response.data.products.some(p => p.id === price.product)
                );

                let fetchedPlans = filteredPrices.map(price => {
                    let productForPrice = response.data.products.find(p => p.id === price.product);

                    const formattedAmount = new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }).format(price.unit_amount / 100);

                    return {
                        id: price.id,
                        title: productForPrice?.name || '',
                        price: formattedAmount,
                        interval: price.recurring?.interval,
                        features: commonFeatures,
                        recommended: price.recurring?.interval === 'month'
                    };
                });

                setPlans(sortPlans(fetchedPlans));
            } catch (error) {
                console.error("Erro ao recuperar planos:", error);
            }
        };

        fetchPlans();
    }, []);

    const handleStartPayment = async () => {
        if (!selectedPlan) return;

        setButtonLoading(true)

        const secrets = await fetchSecrets();
            const SERVER = secrets.SERVER;
            const AUTH_TOKEN = secrets.AUTH_TOKEN;

        try {
            const success_url = window.location.href;
            const cancel_url = window.location.href;

            const response = await axios.post(
                `${SERVER}/api/create-checkout-session`,
                {
                    planId: selectedPlan,
                    userUid: user?.uid,
                    email: email,
                    success_url,
                    cancel_url
                },
                {
                    headers: {
                        'Authorization': `Bearer ${AUTH_TOKEN}`,
                    }
                }
            );

            const checkoutUrl = response.data.url;

            if (checkoutUrl) {
                setButtonLoading(false)
                navigate(-1);
                window.location.href = checkoutUrl;
            } else {
                openDialogue("Erro", "Não foi possível iniciar o pagamento. Tente novamente.", [
                    { text: "OK", onClick: () => closeDialogue() }
                ]);
                setButtonLoading(false)
            }
        } catch (error) {
            console.error("Erro ao iniciar o pagamento:", error);
            openDialogue("Erro", "Houve um problema ao processar o pagamento.", [
                { text: "OK", onClick: () => closeDialogue() }
            ]);
            setButtonLoading(false)
        }

    };

    const sortPlans = (plans) => {
        const order = { 'week': 1, 'month': 2, 'year': 3 };
        return plans.sort((a, b) => order[a.interval] - order[b.interval]);
    };

    function formatSubscriptionId(subscriptionId) {
        const planType = subscriptionId.split('.').pop(); // Pega a última parte da string
        return planType.charAt(0).toUpperCase() + planType.slice(1); // Formata a primeira letra
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1D1D1F' }}>Carregando...</Typography>
            </Box>
        );
    }

    if (!activeSubscription) {
        return (
            <div className='allProfile'>
                <div className='contentInformation'>
                    <h2>Gerencie a sua assinatura</h2>
                    <span>Gerencie e personalize a sua assinatura TwoAlert facilmente.</span>
                </div>
                <div className='contentAddInformation'>
                    <Box sx={{ maxWidth: 800, margin: '0 auto' }}>
                        <Box sx={{ textAlign: 'center', mb: 4 }}>
                            <img
                                src={Logo}
                                alt="TwoAlert Logo"
                                style={{ width: 100, height: 100, borderRadius: 10, marginBottom: 15 }}
                            />
                            <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 3 }}>
                                Escolha entre nossos planos semanal, mensal ou anual e desfrute de uma experiência de segurança personalizada.
                                A segurança ao seu alcance, no seu tempo.
                            </Typography>
                        </Box>

                        {plans.map((plan) => (
                            <StyledCard
                                key={plan.id}
                                recommended={plan.interval === 'month'}
                                selected={selectedPlan === plan.id}
                                onClick={() => setSelectedPlan(plan.id)}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                    <Typography variant="h6" component="h2">{plan.title}</Typography>
                                    {plan.interval === 'month' && (
                                        <Typography variant="subtitle2" color="primary">RECOMENDADO</Typography>
                                    )}
                                    {selectedPlan === plan.id && (
                                        <Checkbox checked color="primary" />
                                    )}
                                </Box>

                                {!hasUsedTrial && (
                                    <TrialBadge>
                                        <Typography variant="subtitle2" color="primary">Teste grátis</Typography>
                                    </TrialBadge>
                                )}

                                <Typography variant="h5" component="p" sx={{ mb: 2 }}>
                                    {plan.price}
                                    <Typography component="span" variant="body2">
                                        /{plan.interval === 'year' ? 'ano' : plan.interval === 'month' ? 'mês' : 'semana'}
                                    </Typography>
                                </Typography>

                                <Box sx={{ mt: 2 }}>
                                    {plan.features.map((feature, index) => (
                                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                                            <Typography sx={{ mr: 1.5, width: 24 }}>{feature.icon}</Typography>
                                            <Typography variant="body2">{feature.text}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </StyledCard>
                        ))}

                        <Box sx={{ mt: 4, px: 2 }}>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                                • A assinatura será <Box component="span" sx={{ fontWeight: 'bold' }}>renovada automaticamente</Box> pelo mesmo período,
                                a menos que seja cancelada com pelo menos 24 horas de antecedência do fim do período atual
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                                • O cancelamento pode ser feito a qualquer momento através das configurações do App, sem custos adicionais
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                                • Ao cancelar, você continuará tendo acesso aos recursos até o final do período atual
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                                • Se houver período de teste gratuito, a parte não utilizada será perdida ao adquirir a assinatura
                            </Typography>

                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, gap: 1 }}>
                                <Button
                                    variant="text"
                                    component="a"
                                    href="https://twoalert.com.br/legal/privacy"
                                    target="_blank"
                                    sx={{ color: '#233DFF', textDecoration: 'underline', fontSize: '0.75rem' }}
                                >
                                    Política de Privacidade
                                </Button>
                                <Typography variant="body2" color="text.secondary">•</Typography>
                                <Button
                                    variant="text"
                                    component="a"
                                    href="https://twoalert.com.br/legal/terms"
                                    target="_blank"
                                    sx={{ color: '#233DFF', textDecoration: 'underline', fontSize: '0.75rem' }}
                                >
                                    Termos de Uso
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{ position: 'sticky', bottom: 0, left: 0, right: 0, p: 2, bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider' }}>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={!selectedPlan || buttonLoading}
                                onClick={handleStartPayment}
                                sx={{
                                    bgcolor: '#233DFF',
                                    borderRadius: '25px',
                                    py: 1.5,
                                    '&:hover': {
                                        bgcolor: '#1a2ebf'
                                    }
                                }}
                            >
                                {buttonLoading ? <CircularProgress size={24} color="inherit" /> : 'Começar agora'}
                            </Button>
                        </Box>

                    </Box>
                </div>
            </div>
        );
    }

    return (
        <div className='allProfile'>
            <div className='contentInformation'>
                <h2>Gerencie a sua assinatura</h2>
                <span>Gerencie e personalize a sua assinatura TwoAlert facilmente.</span>
            </div>
            <div className='contentAddInformation'>
                <Box sx={{ maxWidth: 800, margin: '0 auto' }}>
                    {activeSubscription?.subscriptionDetails?.environment === 'Sandbox' && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: '#FEF3C7',
                            p: 1.25,
                            borderRadius: 1.25,
                            mb: 1.25,
                            gap: 1
                        }}>
                            <WarningIcon sx={{ color: '#B45309' }} />
                            <Typography sx={{ color: '#B45309', fontWeight: 500 }}>
                                Ambiente de testes
                            </Typography>
                        </Box>
                    )}

                    <StyledCard>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ p: 2 }}>
                                <img
                                    src={Logo}
                                    alt="TwoAlert Logo"
                                    style={{ width: 60, height: 60, borderRadius: 10 }}
                                />
                            </Box>

                            <Box sx={{ flex: 1 }}>
                                <Box sx={{ p: 2, borderBottom: 1, borderColor: '#E5E5E5' }}>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1D1D1F' }}>
                                        TwoAlert
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="subtitle1" sx={{ color: '#1D1D1F' }}>
                                            {(() => {
                                                const status = activeSubscription.subscriptionDetails.status;
                                                const statusText = {
                                                    'ACTIVE': '(Ativo)',
                                                    'CANCELED': '(Cancelado)',
                                                    'EXPIRED': '(Expirado)',
                                                    'GRACE_PERIOD': '(Período de carência)',
                                                    'TRIAL': '(Período de teste)',
                                                    'PAUSED': '(Pausado)',
                                                    'PENDING': '(Pendente)'
                                                }[status] || '';
                                                return `${formatSubscriptionId(activeSubscription.subscriptionDetails.subscriptionId)} ${statusText}`;
                                            })()}
                                        </Typography>
                                        <Button
                                            onClick={() => handleManageSubscription(activeSubscription.subscriberOS, user?.uid, openDialogue, closeDialogue)}
                                            endIcon={<ChevronRightIcon />}
                                            sx={{ color: '#233DFF' }}
                                        >
                                            Gerenciar
                                        </Button>
                                    </Box>
                                </Box>

                                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1.25 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <CreditCardIcon sx={{ color: '#1D1D1F' }} />
                                        <Typography sx={{ color: '#1D1D1F' }}>
                                            {(() => {
                                                const { willRenew, isTrialPeriod, currentPeriodEnd, status } = activeSubscription.subscriptionDetails;
                                                const isSandbox = activeSubscription.subscriptionDetails.environment === 'Sandbox';
                                                const formattedDate = new Date(currentPeriodEnd).toLocaleDateString();

                                                if (isTrialPeriod) return `Período de teste até ${formattedDate}`;
                                                if (status === 'CANCELED') return `Acesso disponível até ${formattedDate}`;
                                                if (status === 'EXPIRED') return 'Assinatura expirada';
                                                if (status === 'GRACE_PERIOD') return `Período de carência até ${formattedDate}`;
                                                if (willRenew) return `Próxima cobrança em ${formattedDate}`;
                                                return `Expira em ${formattedDate}`;
                                            })()}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <CalendarTodayIcon sx={{ color: '#1D1D1F' }} />
                                        <Typography sx={{ color: '#1D1D1F' }}>
                                            Assinatura iniciada em {new Date(activeSubscription.subscriptionDetails.currentPeriodStart).toLocaleDateString()}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <InfoIcon sx={{ color: '#1D1D1F' }} />
                                        <Typography sx={{ color: '#1D1D1F' }}>
                                            {activeSubscription.subscriptionDetails.managedBy}
                                        </Typography>
                                    </Box>

                                    {activeSubscription.subscriptionDetails.isTrialPeriod && (
                                        <Box sx={{
                                            bgcolor: '#ECFDF5',
                                            p: 1,
                                            borderRadius: 1,
                                            mt: 0.625
                                        }}>
                                            <Typography sx={{ color: '#047857', textAlign: 'center', fontWeight: 500 }}>
                                                Oferta especial ativa
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </StyledCard>

                    {'web' !== activeSubscription.subscriberOS.toLowerCase() && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: '#F5F7FF',
                            p: 1.5,
                            borderRadius: 1,
                            mt: 1.25,
                            gap: 1
                        }}>
                            <InfoIcon sx={{ color: '#233DFF' }} />
                            <Typography sx={{ color: '#233DFF', flex: 1 }}>
                                {activeSubscription.subscriptionDetails.differentDeviceMessage}
                            </Typography>
                        </Box>
                    )}

                    {activeSubscription.subscriptionDetails.status !== "CANCELED" &&
                        activeSubscription.subscriptionDetails.status !== "EXPIRED" ? (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleCancelSubscription(
                                activeSubscription.subscriberOS,
                                activeSubscription.subscriptionDetails.stripeSubscriptionId,
                                setLoading,
                                user,
                                setActiveSubscription,
                                openDialogue,
                                closeDialogue
                            )}
                            sx={{
                                mt: 1.25,
                                p: 2,
                                borderRadius: 2,
                                bgcolor: '#FFFFFF',
                                color: '#FF3B30',
                                '&:hover': {
                                    bgcolor: '#FFFFFF',
                                }
                            }}
                        >
                            Cancelar assinatura
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleManageSubscription(activeSubscription.subscriberOS, user?.uid, openDialogue, closeDialogue)}
                            sx={{
                                mt: 1.25,
                                p: 2,
                                borderRadius: 2,
                                bgcolor: '#233DFF',
                                color: '#FFFFFF',
                                '&:hover': {
                                    bgcolor: '#1a2ebf',
                                }
                            }}
                        >
                            {`Renovar: R$ ${(activeSubscription.subscriptionDetails.priceAmount / 100).toFixed(2).replace('.', ',')}/${activeSubscription.subscriptionDetails.subscriptionId.includes('anual')
                                ? 'ano'
                                : activeSubscription.subscriptionDetails.subscriptionId.includes('mensal')
                                    ? 'mês'
                                    : 'semana'
                                }`}
                        </Button>
                    )}
                </Box>
            </div>
        </div>
    );
}