import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { useContext } from 'react';
import { UserContext } from '../../../../components/UserProvider';
import { sendUserReport } from '../../../../functions/reportUser';
import { addNotification } from '../../../../components/notification';


export default function ReportProblem({ showReportModal, setShowReportModal }) {

    const { user, email } = useContext(UserContext)

    const [problem, setProblem] = useState('')
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
    };

    const sendReport = async () => {

        if (!user.emailVerified) {
            addNotification("Por favor, verifique seu e-mail para ativar todas as funcionalidades do TwoAlert.", 'warning');
            return;
        }

        if (!title.trim() || title.length < 5 || title.length > 50) {
            addNotification('O título deve conter entre 5 e 50 caracteres.', 'error');
            return; // Interrompe a execução da função
        }
        if (!description.trim() || description.length < 10 || description.length > 500) {
            addNotification('A descrição deve conter entre 10 e 500 caracteres.', 'error');
            return; // Interrompe a execução da função
        }

        try {
            await sendUserReport(title, description, "reportWebPlatform", problem);
            setTitle('')
            setDescription('')
            setShowReportModal(false)
            addNotification('Relatório enviado com sucesso. Responderemos o mais breve possível. Obrigado pelo seu feedback!', 'success');
        } catch (error) {
            addNotification('Ocorreu um erro ao enviar seu relatório. Por favor, tente novamente mais tarde ou entre em contato com o suporte se o problema persistir.', 'error');
        }
    }


    return (
        <>
            {showReportModal && (
                <div className="allReportProblem">
                    <div className='closeAreaReport' onClick={() => setShowReportModal(false)}></div>
                    <div className='reportType'>
                        {problem !== ''
                            ?
                            <>
                                <div className='titleSelectProblem'>
                                    <FontAwesomeIcon icon={faChevronLeft} onClick={() => setProblem('')} />
                                    <h3>Relate-nos a sua dúvida!</h3>
                                </div>
                                <form className='formProblem' onSubmit={handleFormSubmit}>
                                    <div className='inputProblemArea'>
                                        <label>Título da sua dúvida</label>
                                        <input type='text' maxLength={50} value={title} onChange={(e) => setTitle(e.target.value)} placeholder={`${problem}`} required />
                                    </div>
                                    <div className='inputProblemArea'>
                                        <label>E-mail da conta</label>
                                        <input type='email' placeholder='E-mail da conta' value={email} required />
                                    </div>
                                    <div className='inputProblemArea'>
                                        <label>Descrição da dúvida</label>
                                        <textarea rows={1} value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Descrição da dúvida' required maxLength={500} />
                                    </div>
                                    <div className='reportProblemButton'>
                                        <button onClick={() => sendReport()}>Enviar</button>
                                    </div>
                                </form>
                            </>
                            :
                            <>
                                <div className='titleSelectProblem'>
                                    <h3>Selecione a sua dúvida!</h3>
                                </div>
                                <ul>
                                    <li onClick={() => setProblem('Geolocalização')}>
                                        <span>Geolocalização</span>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </li>
                                    <li onClick={() => setProblem('Cadastro')}>
                                        <span>Cadastro</span>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </li>
                                    <li onClick={() => setProblem('Consulta')}>
                                        <span>Consulta</span>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </li>
                                    <li onClick={() => setProblem('Usuários')}>
                                        <span>Usuários</span>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </li>
                                    <li onClick={() => setProblem('Acessos')}>
                                        <span>Acessos</span>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </li>
                                    <li onClick={() => setProblem('Conta')}>
                                        <span>Conta</span>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </li>
                                    <li onClick={() => setProblem('Outros')}>
                                        <span>Outros</span>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </li>
                                </ul>
                            </>
                        }
                    </div>
                </div>
            )}
        </>
    )
}