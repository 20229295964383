import React, { useState, forwardRef, useImperativeHandlef } from 'react';
import { SHA256 } from "crypto-js";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from '../../../../config/firebase'; // Ajuste o caminho conforme necessário
import './style.css';
import { useContext } from 'react';
import { UserContext } from '../../../../components/UserProvider';
import { addNotification } from '../../../../components/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { VerificationContext } from '../../modal/verification/VerificationProvider';
import { auth } from '../../../../config/firebase';

const createPasswordHash = (password) => {
    return SHA256(password).toString();
};

// export default function Verification({ showVerification, setShowVerification, type }) {
export default function Verification({ type }) {

    const { showVerification, setShowVerification, promiseResolver } = useContext(VerificationContext);

    const [inputs, setInputs] = useState(Array(6).fill(""));

    const MAX_ATTEMPTS = 5;
    const [attemptCount, setAttemptCount] = useState(0);

    const [password, setpassword] = useState({ value: '', error: '' })
    const [showPassword, setShowPassword] = useState(false)
    const [showTypePassword, setShowTypePassword] = useState(false)

    const { user, email } = useContext(UserContext)

    const handleBackspace = (index, event) => {
        if (event.key === "Backspace" && index > 0 && inputs[index] === "") {
            document.getElementById(`input${index}`).focus();
        }
    };

    const handleInputChange = (index, value) => {
        if (!/^\d$/.test(value) && value !== "") return; // Permitir apenas números e permitir apagar

        const newInputs = [...inputs];
        newInputs[index] = value;
        setInputs(newInputs);

        // Foco automático no próximo input ou foco no anterior se for Backspace
        if (value && index < 5) {
            document.getElementById(`input${index + 2}`).focus();
        }
    };

    const reauthenticate = async (currentPassword) => {
        const cred = EmailAuthProvider.credential(email, currentPassword);
        try {
            var user = auth.currentUser;
            await reauthenticateWithCredential(user, cred);
            return true;
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                addNotification('Senha atual incorreta', 'error');
            } else if (error.code === 'auth/too-many-requests') {
                addNotification('Muitas tentativas falharam. Tente novamente mais tarde.', 'error');
            } else {
                // addNotification('Erro ao reautenticar: ' + error.message, 'error');
                addNotification('Algo deu errado!: ', 'error');
            }
            return false;
        }
    };

    const saveOrUpdatePasswordHash = async (passwordHash, currentPassword) => {
        const userDocRef = doc(db, "users", user.uid);

        const isAuthenticated = await reauthenticate(currentPassword);
        if (!isAuthenticated) return;

        try {
            await setDoc(userDocRef, { passwordHash }, { merge: true });
            addNotification("Código configurado com sucesso.", 'success');
            setShowTypePassword(false);
            setShowVerification(false);
        } catch (error) {
            console.error("Erro ao salvar o código:", error);
            addNotification("Erro ao salvar o código.", 'error');
        }
    };

    const verifyPasswordHash = async (passwordHash) => {
        const userDocRef = doc(db, "users", user.uid);

        try {
            const docSnap = await getDoc(userDocRef);

            // Verifica se o documento existe e se o campo passwordHash está presente
            if (docSnap.exists() && docSnap.data().passwordHash) {
                if (docSnap.data().passwordHash !== passwordHash) {
                    addNotification("Código incorreto.", 'error'); // Código existe, mas está incorreto
                    return false;
                }
                return true; // Código correto
            } else {
                addNotification("Parece que você ainda não configurou o código de acesso.", 'error');
                return false; // Código não configurado
            }
        } catch (error) {
            console.error("Erro ao verificar o código:", error);
            addNotification("Erro ao verificar o código.", 'error');
            return false;
        }
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        if (attemptCount >= MAX_ATTEMPTS) {
            addNotification("Número máximo de tentativas atingidas. Tente novamente mais tarde.", 'error');
            setShowVerification(false);
            return;
        }

        const code = inputs.join("");
        if (code.length !== 6) {
            addNotification("Por favor! Insira um código de 6 dígitos.", 'warning');
            return;
        }

        const passwordHash = createPasswordHash(code);

        if (type === 'configure' || type === 'update') {
            await saveOrUpdatePasswordHash(passwordHash, password.value);
        } else {
            const isValid = await verifyPasswordHash(passwordHash);
            if (isValid) {
                if (promiseResolver && typeof promiseResolver.resolve === 'function') {
                    promiseResolver.resolve();
                    setShowVerification(false);
                }
            } else {
                if (promiseResolver && typeof promiseResolver.reject === 'function') {
                    promiseResolver.reject();
                    setAttemptCount(prev => prev + 1);
                }
            }
        }

        setInputs(Array(6).fill(""))
    };

    const handleVerification = (event) => {
        if (event) event.preventDefault();
        setShowTypePassword(true);
    }

    const createVerificationCode = (event) => {
        event.preventDefault(); // Isso deve estar aqui para sempre prevenir a atualização da página.
    
        if (user.emailVerified) {
            !type ? handleSubmit(event) : handleVerification(event); // Ajuste conforme necessário
        } else {
            addNotification("Por favor, verifique seu e-mail para ativar todas as funcionalidades do TwoAlert.", 'warning');
        }
    };
    


    return (
        <>
            {showVerification && (
                <div className='allVerification'>
                    <div className='closeArea' onClick={() => setShowVerification(false)}></div>
                    <form className="formVerification" onSubmit={createVerificationCode}>
                        <div className="titleVerification">Verificação</div>
                        <div className="titleVerification">Código de verificação</div>
                        <p className="message">{type === 'configure' ? 'Crie um código de 6 dígitos para realizar ações no TwoAlert' : type === 'update' ? 'Altere seu código de 6 dígitos pra realizar ações no TwoAlert' : 'Para prosseguir, insira o código que você configurou.'}</p>
                        <div className="inputs">
                            {inputs.map((input, index) => (
                                <input
                                    key={index}
                                    id={`input${index + 1}`}
                                    type="text"
                                    maxLength="1"
                                    required
                                    value={input}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    onKeyDown={(e) => handleBackspace(index, e)}
                                />
                            ))}
                        </div>
                        <button className="action">Prosseguir</button>
                    </form>
                </div>
            )}

            {showTypePassword && (
                <div className='allVerification'>
                    <div className='closeArea' onClick={() => setShowTypePassword(false)}></div>
                    <form className="formVerification" onSubmit={handleSubmit}>
                        <div className="titleVerification">Insira a sua senha para criar ou editar o código</div>
                        <div className='contentInputs'>
                            <div className='contentInput'>
                                <input type={showPassword ? 'text' : 'password'} class="input" required value={password.value} onChange={(e) => setpassword({ ...password, value: e.target.value })} maxLength={10} minLength={6} />
                                <label class="label">Senha atual</label>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} className='iconEye' />
                            </div>
                        </div>
                        <button className="action" style={{ marginTop: 0 }}>Prosseguir</button>
                    </form>
                </div>
            )}
        </>
    );
}