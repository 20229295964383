import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css'; // Atualize o caminho conforme necessário

const RestrictedAccessScreen = () => {
    const navigate = useNavigate();

    return (
        <div className="restrictedAccessContainer">
            <h2 className="restrictedAccessTitle">Acesso Restrito</h2>
            <p className="restrictedAccessMessage">Você precisa estar logado para acessar esta página.</p>
            <button
                onClick={() => navigate('/')}
                className="backToHomeButton"
            >
                Voltar ao Início
            </button>
        </div>
    );
};

export default RestrictedAccessScreen;
