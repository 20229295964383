import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';
import './style.css';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/firebase';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEnvelope, faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';
import { addNotification } from '../components/notification';
import { useContext } from 'react';
import { LoginContext } from '../index';

import logo from '../assets/Logo-TwoAlert.jpeg';

import Loading from '../assets/mn224E_W9XQ.gif'

import image01 from '../assets/imagem_01.jpg';
import image02 from '../assets/imagem_02.jpg';
import image03 from '../assets/imagem_03.jpg';
import image04 from '../assets/imagem_04.jpg';
import image05 from '../assets/imagem_05.jpg';
import image06 from '../assets/imagem_06.jpg';
import image07 from '../assets/imagem_07.jpg';

export default function Login() {

    const images = [
        image01, image02, image03, image04, image05, image06, image07
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3500); // Ajustando o intervalo
        return () => clearInterval(timer);
    }, []);

    const [isActive, setIsActive] = useState('login');

    const { setIsLoginComplete } = useContext(LoginContext);
    const [loading, setLoading] = useState(false)

    const getBrowserName = () => {
        const userAgent = navigator.userAgent;
        let browserName = "Unknown";

        if (userAgent.includes("Firefox")) {
            browserName = "Mozilla Firefox";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            browserName = "Opera";
        } else if (userAgent.includes("Edg")) {
            browserName = "Microsoft Edge";
        } else if (userAgent.includes("Chrome")) {
            browserName = "Google Chrome";
        } else if (userAgent.includes("Safari")) {
            browserName = "Apple Safari";
        } else if (userAgent.includes("MSIE") || !!document.documentMode === true) {
            browserName = "Internet Explorer";
        }

        return browserName;
    };

    const getOperatingSystem = () => {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        let os = "Unknown";

        if (platform.indexOf("Mac") !== -1) {
            os = "macOS";
            if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
                os = "iOS";
            }
            if (userAgent.match(/Macintosh/i) && userAgent.match(/AppleWebKit/i) && !userAgent.match(/Safari/i)) {
                os = "iPadOS";
            }
        } else if (platform.indexOf("Win") !== -1) {
            os = "Windows";
        } else if (platform.indexOf("Linux") !== -1) {
            os = "Linux";
            if (userAgent.indexOf("Android") !== -1) {
                os = "Android";
            }
        } else if (platform.indexOf("iPhone") !== -1) {
            os = "iOS";
        }

        return os;
    };

    const generateUniqueID = () => {
        const navigatorInfo = window.navigator;
        const screenInfo = window.screen;

        const uidParts = [
            navigatorInfo.userAgent,
            navigatorInfo.language,
            screenInfo.height.toString(),
            screenInfo.width.toString(),
            screenInfo.colorDepth.toString(),
            new Date().getTimezoneOffset().toString()
        ];

        const uid = uidParts.join('-');

        // Criando um hash SHA-256 do UID
        const hash = CryptoJS.SHA256(uid);

        // Convertendo o hash em uma string hexadecimal
        const hashString = hash.toString(CryptoJS.enc.Hex);

        // Embaralhando a string hash
        const shuffledHash = hashString.split('').sort(() => Math.random() - 0.5).join('');

        return shuffledHash;
    };

    const run = async (uid) => {
        setIsLoginComplete(false)
        const browserName = getBrowserName();
        const operatingSystem = getOperatingSystem();
        const deviceName = getBrowserName();

        let uniqueID = localStorage.getItem('uniqueID');

        // Se não existir, gere um novo e salve no AsyncStorage
        if (!uniqueID) {
            uniqueID = generateUniqueID();
            localStorage.setItem('uniqueID', uniqueID);
        }

        const publicIpResponse = await axios.get('https://httpbin.org/ip');
        const userIP = publicIpResponse.data.origin;

        try {

            const response = await axios.post(`https://server-secrets-key-dot-twoalert-68c80.uc.r.appspot.com/sessionInfo`, {
                operatingSystem,
                browserName,
                deviceName,
                userIP,
                uniqueID,
                uid
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const result = response.data;
            if (result.success == true) {
                setTimeout(() => {
                    setIsLoginComplete(true)
                }, 1000)
            }
        } catch (error) {
            setIsLoginComplete(true)
            addNotification('Desculpe, mas não conseguimos realizar seu login no momento. Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato com nosso suporte.', 'error');
        }
    }



    const registerWithEmail = async (email, password, passwordConfirm) => {
        setLoading(true)
        if (password.length < 6 || passwordConfirm.length < 6) {
            addNotification('A senha deve ter no mínimo 6 caracteres', 'warning');
            setLoading(false)
            return;
        }

        if (password !== passwordConfirm) {
            addNotification('As senhas não coincidem', 'warning');
            setLoading(false)
            return;
        }
        if (!terms) {
            addNotification('É preciso que você esteja de acordo com a nossa Política de Privacidade e Termos de Uso.', 'warning');
            setLoading(false)
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            setLoading(false)
            run(user.uid)
            await sendEmailVerification(user);
            addNotification('Usuário cadastrado com sucesso!', 'success');
        } catch (error) {
            setLoading(false)
            const errorMessageMap = {
                'Firebase: Error (auth/email-already-in-use).': "Este e-mail já está sendo utilizado por outro usuário!",
                'Firebase: Error (auth/user-not-found).': "Usuário não encontrado, verifique se o e-mail foi inserido corretamente!",
                'Firebase: Error (auth/wrong-password).': "Senha incorreta!",
                'Firebase: Error (auth/network-request-failed).': "Verifique sua conexão com a internet e tente novamente!",
                'Firebase: Error (auth/too-many-requests).': "O acesso a esta conta foi temporariamente desativada devido a muitas tentativas falhas de login. Você pode restaurá-la, imediatamente, redefinindo a sua senha, ou pode tentar novamente mais tarde.",
                'Firebase: Error (auth/invalid-email).': "Endereço de e-mail inválido",
                'Firebase: Error (auth/weak-password).': 'A senha é muito fraca.',
                'Firebase: Error (auth/too-many-requests).': "O acesso a esta conta foi temporariamente desativada devido a muitas tentativas de falhas de login. Você pode restaurá-la, imediatamente, redefinindo a sua senha, ou pode tentar novamente mais tarde.",
                'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).': "O acesso a esta conta foi temporariamente desativada devido a muitas tentativas de falhas de login. Você pode restaurá-la, imediatamente, redefinindo sua senha, ou pode tentar novamente mais tarde.",
            };

            const message = errorMessageMap[error.message];
            addNotification(message, 'error');
        }
    };

    const loginWithEmail = async (email, password) => {
        setLoading(true)
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            setLoading(false)
            run(user.uid)
            addNotification('Logado com sucesso!', 'success');
        } catch (error) {
            setLoading(false)
            const errorMessageMap = {
                'Firebase: Error (auth/missing-password).': "Por favor, insira a sua senha!",
                'Firebase: Error (auth/user-not-found).': "Usuário não encontrado, verifique se o e-mail foi inserido corretamente!",
                'Firebase: Error (auth/wrong-password).': "Senha incorreta!",
                'Firebase: Error (auth/network-request-failed).': "Verifique a sua conexão com a internet e tente novamente!",
                'Firebase: Error (auth/too-many-requests).': "O acesso a esta conta foi temporariamente desativada devido a muitas tentativas de falhas de login. Você pode restaurá-la, imediatamente, redefinindo a sua senha ou pode tentar novamente mais tarde.",
                'Firebase: Error (auth/invalid-email).': "Endereço de e-mail inválido",
                'Firebase: Error (auth/weak-password).': 'A senha é muito fraca.',
                'Firebase: Error (auth/too-many-requests).': "O acesso a esta conta foi temporariamente desativada devido a muitas tentativas de falhas de login. Você pode restaurá-la imediatamente redefinindo sua senha ou pode tentar novamente mais tarde.",
                'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).': "O acesso a esta conta foi temporariamente desativada devido a muitas tentativas de falhas de login. Você pode restaurá-la, imediatamente, redefinindo a sua senha ou pode tentar novamente mais tarde.",
            };

            const message = errorMessageMap[error.message];
            addNotification(message, 'error');
        }
    };

    const sendResetPasswordEmail = async (email) => {
        setLoading(true)
        sendPasswordResetEmail(auth, email)
            .then(() => {
                addNotification('Enviamos instruções para o email ' + email + ' para a redefinição da senha.', 'success')
            })
            .catch((err) => {
                setLoading(false)
                if (err.message == 'Firebase: Error (auth/user-not-found).') {
                    addNotification('Usuário não encontrado verifique se o email foi digitado corretamente!', 'error')
                }
                if (err.message == 'Firebase: Error (auth/too-many-requests).') {
                    addNotification('Você já solicitou muitos envios de e-mails, aguarde um pouco e solicite novamente.', 'error')
                    return;
                }
                if (err.message == 'Firebase: Error (auth/network-request-failed).') {
                    addNotification('Verifique a sua conexão com a internet e tente novamente!', 'error')
                }

            });
        setLoading(false)
    }

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword02, setShowPassword02] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const [terms, setTerms] = useState(false);

    return (
        <div className="login">
            <div id="notifications"></div>
            <div className="imagesLogin">
                <div className='logoIcon'>
                    <img src={logo} alt="Imagem" />
                    <span>TwoAlert</span>
                </div>
                <AnimatePresence>
                    <motion.img
                        key={index}
                        src={images[index]}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 1 } }} // Aumentando o delay
                        exit={{ opacity: 0 }}
                        transition={{ duration: 1 }} // Ajustando a duração da animação
                        className='slide'
                    />
                </AnimatePresence>
            </div>
            <div className="loginForm">
                <div className={isActive === 'login' ? 'containerLoginForm active' : 'containerLoginForm'}>
                    <h2>TwoAlert</h2>
                    <span>A segurança virtual necessária!</span>
                    <form className='containerForm' onSubmit={handleSubmit}>
                        <div className='inputForm'>
                            <label>E-mail</label>
                            <div className='inputArea'>
                                <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                <input type="email" placeholder='Digite o seu e-mail' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='inputForm'>
                            <label>Senha</label>
                            <div className='inputArea'>
                                <FontAwesomeIcon icon={faLock} className='icon' />
                                <input type={showPassword ? 'text' : 'password'} placeholder='Digite a sua senha' value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='false' />
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} className='iconEye' />
                            </div>
                        </div>
                        <span id='forgot' onClick={() => setIsActive('forgotPassword')}>Esqueceu a sua senha?</span>
                        <button onClick={() => loginWithEmail(email, password)}>{loading && (<img src={Loading} alt='loading animation' />)} Entrar</button>
                    </form>
                    <a href="#" onClick={() => setIsActive('createAccount')}>Cadastre-se agora!</a>
                </div>

                <div className={isActive === 'createAccount' ? 'containerLoginForm active' : 'containerLoginForm'}>
                    <h2>TwoAlert</h2>
                    <span>A segurança virtual necessária!</span>
                    <form className='containerForm' onSubmit={handleSubmit}>
                        <div className='inputForm'>
                            <label>E-mail</label>
                            <div className='inputArea'>
                                <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                <input type="email" placeholder='Digite o seu e-mail' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='inputForm'>
                            <label>Senha</label>
                            <div className='inputArea'>
                                <FontAwesomeIcon icon={faLock} className='icon' />
                                <input type={showPassword ? 'text' : 'password'} placeholder='Digite a sua senha' value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='false' />
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} className='iconEye' />
                            </div>
                        </div>
                        <div className='inputForm'>
                            <label>Confirmar senha</label>
                            <div className='inputArea'>
                                <FontAwesomeIcon icon={faLock} className='icon' />
                                <input type={showPassword02 ? 'text' : 'password'} placeholder='Confirme a sua senha' value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} autoComplete='false' />
                                <FontAwesomeIcon icon={showPassword02 ? faEyeSlash : faEye} onClick={() => setShowPassword02(!showPassword02)} className='iconEye' />
                            </div>
                        </div>
                        <div className='termsAndContidions'>
                            <input type="checkbox" checked={terms} onClick={() => setTerms(!terms)} onChange={() => setTerms(!terms)} id="terms" />
                            <label htmlFor="terms"></label>
                            <span>Ao prosseguir, você concorda com os nossos <a href='https://twoalert.com.br/legal/terms' target="_blank" rel="noopener">Termos de Uso</a> e a nossa <a href='https://twoalert.com.br/legal/privacy' target="_blank" rel="noopener">Política de Privacidade</a></span>
                        </div>
                        <button onClick={() => registerWithEmail(email, password, passwordConfirm)}>{loading && (<img src={Loading} alt='loading animation' />)} Cadastrar</button>
                    </form>
                    <a href="#" onClick={() => setIsActive('login')}>Entrar agora</a>
                </div>


                <div className={isActive === 'forgotPassword' ? 'containerLoginForm active' : 'containerLoginForm'}>
                    <div className='backIcon' onClick={() => setIsActive('login')}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <h2>TwoAlert</h2>
                    <span>A segurança virtual necessária!</span>
                    <form className='containerForm' onSubmit={handleSubmit}>
                        <div className='inputForm'>
                            <label>E-mail</label>
                            <div className='inputArea'>
                                <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                <input type="email" placeholder='Digite o seu e-mail' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='termsAndContidions'>
                            <span>Você receberá um e-mail com o link de redefinição da senha.</span>
                        </div>
                        <button onClick={() => sendResetPasswordEmail(email)}>{loading && (<img src={Loading} alt='loading animation' />)} Enviar instruções</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
