import axios from 'axios';
import fetchSecrets from '../../config/fetchSecrets';

const blobFromUrl = async (url) => {
    const response = await fetch(url);
    return await response.blob();
};

export async function updateUser(document_id, uid, payload = {}, token, images = [], delete_image_urls = [], pdf = null, delete_pdf_urls = null) {


    const secrets = await fetchSecrets();
    const SERVER_URL = secrets.SERVER_URL;
    const AUTH_TOKEN = secrets.AUTH_TOKEN;

    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("document_id", document_id);

    // Inclui o payload no FormData se houver conteúdo
    if (payload) {
        Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value);
        });
    }

    // Anexar imagens para upload
    if (images.length > 0) {
        for (const uri of images) {
            const blob = await blobFromUrl(uri);
            const file = new File([blob], `image_${images.indexOf(uri) + 1}.jpeg`, { type: "image/jpeg" });
            formData.append("images", file);
        }
    }

    if (delete_image_urls) {
        // URLs de imagens para deletar
        delete_image_urls.forEach(url => {
            formData.append("delete_image_urls[]", url);
        });
    }


    // Anexar PDF para upload
    if (pdf) {
        const pdfBlob = await blobFromUrl(pdf);
        const pdfFile = new File([pdfBlob], "documento.pdf", { type: "image/jpeg" });
        formData.append("pdf", pdfFile);
    }

    if (delete_pdf_urls) {
        // URLs de PDFs para deletar
        formData.append("delete_pdf_urls[]", delete_pdf_urls);
    }

    try {
        const response = await axios.post(
            `${SERVER_URL}/update-user`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${AUTH_TOKEN}`,
                    'X-User-ID': uid,
                    'X-Recaptcha-Token': token
                }
            }
        );

        return response.data.message;
    } catch (error) {
        console.error(error);
        const errorMessage = error.code === 'ECONNABORTED'
            ? "Tempo de espera excedido. Tente novamente mais tarde."
            : error.response?.data?.error || "Erro ao atualizar usuário";

        throw new Error(errorMessage);
    }
}
