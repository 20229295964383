import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const VeracityPercentageGraph = ({ viewsDetails }) => {

    const formatDateLabel = (timestamp) => {
        if (timestamp) {
            const date = new Date(timestamp);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            return `${day}/${month}`;
        } else {
            return '';
        }
    };

    // Supondo que viewsDetails já está no formato correto e pronto para ser utilizado
    const chartData = viewsDetails.slice(-6).map(item => ({
        value: item.veracityPercentage,
        date: formatDateLabel(item.viewDate)
    }));

    const data = {
        labels: chartData.map(item => item.date),
        datasets: [{
            data: chartData.map(item => item.value),
            borderColor: '#1D1D1F',
            backgroundColor: 'rgba(211, 211, 211, 0.5)',
            borderWidth: 2,
            pointBackgroundColor: '#1D1D1F',
            pointBorderColor: '#1D1D1F',
            pointBorderWidth: 1,
            pointRadius: 3
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        elements: {
            line: {
                tension: 0.4
            }
        }
    };

    return (
        <div className='containerGraph'>
            <Line data={data} options={options} />
        </div>
    );
};

export default VeracityPercentageGraph;
