import React, { useState, createContext, useContext, useEffect } from 'react';

export const DeleteAccountContext = createContext();

export const DeleteAccountProvider = ({ children }) => {
  const [deletedAccount, setDeletedAccount] = useState(false);

  return (
    <DeleteAccountContext.Provider value={{ deletedAccount, setDeletedAccount }}>
      {children}
    </DeleteAccountContext.Provider>
  );
};


export const IsDeletingContext = createContext();

export const IsDeletingProvider = ({ children }) => {
  const [IsDeleting, setIsDeletingAccount] = useState(false);

  return (
    <IsDeletingContext.Provider value={{ IsDeleting, setIsDeletingAccount }}>
      {children}
    </IsDeletingContext.Provider>
  );
};