import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const EditedDataGraph = ({ editedData }) => {
    const formatDateLabel = (date) => {
        const parts = date.split("/");
        return `${parts[0]}/${parts[1]}`; // Retorna apenas o dia e o mês
    };

    const lastEightEdits = editedData.slice(-6);

    const data = {
        labels: lastEightEdits.map(item => formatDateLabel(item.data)), // Formata as datas
        datasets: [{
            data: lastEightEdits.map(item => parseFloat(item.porcentagem)), // As porcentagens
            borderColor: 'rgba(0, 0, 0, 1)', // Linha do gráfico em preto
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Preenchimento sob a linha (se necessário)
            borderWidth: 2,
            pointBorderColor: 'rgba(0, 0, 0, 1)',
            pointBackgroundColor: 'rgba(0, 0, 0, 1)',
            pointRadius: 6,
            pointBorderWidth: 2,
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        elements: {
            line: {
                tension: 0.4
            }
        },
    };

    return (
        <div className='containerGraph'>
            <Line data={data} options={options} />
        </div>
    );
};

export default EditedDataGraph;
