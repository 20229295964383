import { useContext } from 'react';
import { DialogueContext } from '../DialogueContext';

// useDialogue.js
export const useDialogue = () => {
    const { showDialogue, closeDialogue } = useContext(DialogueContext);

    const openDialogue = (title, message, buttons) => {
        showDialogue({ title, message, buttons });
    };

    return { openDialogue, closeDialogue };
};
