import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import sendAlertBotRequest from "../alertBot";
import {
    Box,    
    Avatar,
    Typography,
    TextField,
    IconButton,
    styled,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import AlertBot from '../../assets/AlertBot.png';

const ChatContainer = styled(motion.div)({
    width: '350px',
    height: '500px',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
    zIndex: 100,
    transition: 'all 0.3s ease-in-out'
});

const ChatContent = styled(motion.div)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: 'opacity 0.2s ease-in-out',
});

const Header = styled(Box)({
    padding: '12px 16px',
    borderBottom: '1px solid #C1C1C1',
    color: '#1D1D1F',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    height: '56px',
});

const StyledAvatar = styled(Avatar)({
    width: 28,
    height: 28,
    borderRadius: 0,
    objectFit: 'contain',
    '& img': {
        objectFit: 'contain'
    }
});

const ChatBubble = styled(motion.div)(({ isUser }) => ({
    padding: '8px 12px',
    maxWidth: '85%',
    borderRadius: '14px',
    backgroundColor: isUser ? '#233DDF' : '#f1f1f1',
    color: isUser ? '#fff' : '#000',
    fontFamily: 'SF-Pro-Text',
    fontSize: '15px',
    lineHeight: '1.4',
    '& ul, & ol': {
        paddingLeft: '20px',
        margin: '8px 0',
        listStylePosition: 'outside'
    },
    '& li': {
        marginBottom: '4px'
    }
}));

const MinimizedChat = styled(motion.div)({
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    padding: '8px 16px',
    width: '215px',
    height: '37px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
    zIndex: 100,
    transition: '0.3s',
});

const StyledInput = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        height: '40px',
        borderRadius: '20px',
        border: '1px solid #C1C1C1',
        fontFamily: 'SF-Pro-Text',
        fontSize: '15px',
        '& fieldset': {
            border: 'none',
        },
        '&:hover fieldset': {
            border: 'none',
        },
        '&.Mui-focused fieldset': {
            border: 'none',
        },
    },
    '& .MuiInputBase-input': {
        padding: '8px 16px',
    },
});

const SendButton = styled(IconButton)({
    width: '36px',
    height: '36px',
    color: '#233DDF',
    '&:hover': {
        backgroundColor: 'rgba(35, 61, 223, 0.1)',
    },
});

export default function AppleStyleChat() {
    const [chatTwoAlert, setChatTwoAlert] = useState(false);
    const [messages, setMessages] = useState([{
        content: 'Olá! Como posso ajudar você hoje?',
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        role: 'assistant'
    }]);
    const [inputValue, setInputValue] = useState('');
    const [dataAtual] = useState(new Date().toLocaleDateString('pt-BR', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    }));
    const chatRef = useRef();

    const containerVariants = {
        hidden: { 
            height: '37px',
            width: '215px',
            opacity: 1,
        },
        visible: { 
            height: '500px',
            width: '350px',
            opacity: 1,
            transition: { duration: 0.3 }
        },
        exit: { 
            height: '37px',
            width: '215px',
            opacity: 1,
            transition: { duration: 0.3 }
        }
    };

    const contentVariants = {
        hidden: { 
            opacity: 0,
            transition: { duration: 0.2 }
        },
        visible: { 
            opacity: 1,
            transition: { delay: 0.2, duration: 0.2 }
        }
    };

    const sendMessage = async () => {
        if (inputValue.trim() === '') return;

        const newMessage = {
            content: inputValue,
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            role: 'user'
        };

        setMessages(prev => [...prev, newMessage]);
        setInputValue('');

        try {
            const messagesForModel = [...messages, newMessage].map(msg => ({
                role: msg.role === 'user' ? 'user' : 'assistant',
                content: msg.content,
            }));

            const response = await sendAlertBotRequest(messagesForModel);
            const responseMessage = {
                content: response,
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                role: 'assistant',
            };

            setMessages(prev => [...prev, responseMessage]);
        } catch (error) {
            console.error("Error sending message to GPT:", error);
        }
    };

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <AnimatePresence mode="wait">
            {chatTwoAlert ? (
                <ChatContainer
                    key="chat"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <Header>
                        <IconButton 
                            size="small" 
                            onClick={() => setChatTwoAlert(false)}
                            sx={{ 
                                color: '#1D1D1F',
                                padding: '4px',
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </IconButton>
                        <StyledAvatar src={AlertBot} />
                        <Box>
                            <Typography 
                                sx={{ 
                                    fontSize: '15px',
                                    fontFamily: 'SF-Pro-Display-Bold',
                                    fontWeight: 600,
                                }}
                            >
                                AlertBot
                            </Typography>
                            <Typography 
                                sx={{ 
                                    fontSize: '13px',
                                    opacity: 0.9,
                                    fontFamily: 'SF-Pro-Text-Regular',
                                }}
                            >
                                A sua ajuda virtual com o TwoAlert!
                            </Typography>
                        </Box>
                    </Header>

                    <motion.div
                        variants={contentVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                    >
                        <Box
                            ref={chatRef}
                            sx={{
                                flex: 1,
                                overflowY: 'auto',
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,
                                height: 'calc(100% - 80px)', // Ajuste para considerar o header e o input
                                maxHeight: '372px', // 500px (altura total) - 56px (header) - 60px (input)
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#E1E1E1',
                                    borderRadius: '2px',
                                },
                            }}
                        >
                            <Box sx={{ textAlign: 'center', mb: 1 }}>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: '#8E8E93',
                                        fontFamily: 'SF-Pro-Text-Regular',
                                    }}
                                >
                                    {dataAtual}
                                </Typography>
                            </Box>

                            {messages.map((message, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
                                        gap: 1,
                                    }}
                                >
                                    {message.role === 'assistant' && (
                                        <StyledAvatar
                                            src={AlertBot}
                                            sx={{ width: 24, height: 24 }}
                                        />
                                    )}
                                    <ChatBubble
                                        isUser={message.role === 'user'}
                                    >
                                        <ReactMarkdown>{message.content}</ReactMarkdown>
                                        <Typography
                                            sx={{
                                                fontSize: '10px',
                                                mt: 0.5,
                                                opacity: 0.7,
                                                fontFamily: 'SF-Pro-Text-Regular',
                                            }}
                                        >
                                            {message.time}
                                        </Typography>
                                    </ChatBubble>
                                </Box>
                            ))}
                        </Box>

                        <Box sx={{ 
                            p: 2, 
                            borderTop: '1px solid #E5E5EA',
                        }}>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <StyledInput
                                    fullWidth
                                    size="small"
                                    placeholder="Pergunte algo..."
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                                />
                                <SendButton onClick={sendMessage}>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </SendButton>
                            </Box>
                        </Box>
                    </motion.div>
                </ChatContainer>
            ) : (
                <MinimizedChat
                    key="minimized"
                    onClick={() => setChatTwoAlert(true)}
                >
                    <StyledAvatar src={AlertBot} />
                    <Typography 
                        sx={{ 
                            fontSize: '15px',
                            fontFamily: 'SF-Pro-Text-Regular',
                            fontWeight: 500,
                            color: '#1D1D1F',
                        }}
                    >
                        Central de ajuda
                    </Typography>
                    <FontAwesomeIcon 
                        icon={faChevronUp}
                        style={{ color: '#8E8E93' }} 
                    />
                </MinimizedChat>
            )}
        </AnimatePresence>
    );
}