import React from 'react'
import './style.css'

import brasilFlag from '../../../../assets/flags/brasil_flag.png'
import euaFlag from '../../../.././assets/flags/united_state_flag.png'
import franchFlag from '../../../../assets/flags/franch_flag.png'
import spanFlag from '../../../../assets/flags/span_flag.png'
import germanFlag from '../../../../assets/flags/german_flag.png'
import italyFlag from '../../../../assets/flags/italy_flag.png'

export default function Idiom({ setWidth }) {


    return (
        <div className='allProfile'>
            <div id="notifications"></div>
            <div className='contentInformation'>
                <h2>Alterar idioma</h2>
                <span>Altere o idioma do app TwoAlert</span>
            </div>
            <div className='contentAddInformation'>
                <div className='addInformation'>
                    <div className='containerIdioms'>
                        <div className='idiomOption'>
                            <li>Português - Brasil</li>
                            <img src={brasilFlag} />
                        </div>
                        <div className='idiomOption disable'>
                            <li>Inglês - (English)</li>
                            <img src={euaFlag} />
                        </div>
                        <div className='idiomOption disable'>
                            <li>Francês - (Français)</li>
                            <img src={franchFlag} />
                        </div>
                        <div className='idiomOption disable'>
                            <li>Espanhol - (Español)</li>
                            <img src={spanFlag} />
                        </div>
                        <div className='idiomOption disable'>
                            <li>Alemão - (Deutsch)</li>
                            <img src={germanFlag} />
                        </div>
                        <div className='idiomOption disable'>
                            <li>Italiano - (Italiano)</li>
                            <img src={italyFlag} />
                        </div>
                    </div>
                </div>
                <button id='sendInformations'>Alterar idioma</button>
            </div>
        </div>
    )
}