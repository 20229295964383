import React, { createContext, useState, useCallback } from 'react';

export const VerificationContext = createContext();

export const VerificationProvider = ({ children }) => {
    const [showVerification, setShowVerification] = useState(false);
    const [promiseResolver, setPromiseResolver] = useState(null);

    const initiateVerification = useCallback(() => {
        setShowVerification(true);
        return new Promise((resolve, reject) => {
            setPromiseResolver({ resolve, reject });
        });
    }, []);

    return (
        <VerificationContext.Provider value={{ showVerification, setShowVerification, initiateVerification, promiseResolver }}>
            {children}
        </VerificationContext.Provider>
    );
};
